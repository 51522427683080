/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Calendar Styles
\*------------------------------------*/
ul.stec-top-menu-filter-dropdown,
ul.stec-top-dropmenu-layouts {
    margin: 0;
}

.stec-layout-agenda-events-all-control,
p.stec-layout-day-noevents {
    margin-bottom: 3em !important;
}

.stec-top-menu-filter-by-calendar,
.stec-top-menu-filter-by-category,
.stec-top-menu-filter-by-organizer,
.stec-top-menu-filter-by-location,
.stec-top-menu-filter-by-featured {
    &:hover {
        background-color: $ca-dk-grey;
    }
}

body .stec-layout-month-daylabel p {
    color: $white !important;
}

.stec {
    font-family: $main-font;
    font-weight: $normal;

    ul {
        li {
            &:before {
                content: '' !important;
            }
        }
    }
}

.stec-layout-month-daylabel {
    p {
        font-family: $main-font;
        font-weight: $normal;
    }
}

.stec-layout-month-daycell {
    .stec-layout-month-daycell-wrap {
        .stec-layout-month-daycell-num {
            font-family: $main-font;
            font-weight: $normal;
        }
    }
}

.responsive-calendar {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
}

.responsive-calendar iframe {
    border: 0;
    width: 100%;
    min-height: 50vh; /* Adjust the value based on your needs */
    @include md {
        min-height: 80vh; /* Adjust the value based on your needs */
    }
}

@media screen and (max-width: 768px) {
    .responsive-calendar iframe {
        width: 150%; /* Adjust as necessary for your content */
    }
}
