@use 'sass:color';
@use 'sass:math';

/*------------------------------------*\
#Base Styles
\*------------------------------------*/
@use 'base/reset' as *;
@use 'base/typography' as *;
@use 'base/grid' as *;
@use 'base/spacing' as *;
/*------------------------------------*\
#Components
\*------------------------------------*/
@use 'components/alerts' as *;
@use 'components/buttons' as *;
@use 'components/calendar' as *;
@use 'components/forms' as *;
@use 'components/graphics' as *;
@use 'components/modals' as *;
@use 'components/nav' as *;

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;


@media print {
  /*------------------------------------*\
#Global
\*------------------------------------*/

  body {
    background: $white;
    color: $black;
    margin: 0;
    font-size: 14pt;
    font-family: $main-font;
    font-weight: $normal;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  /*------------------------------------*\
#Navigation
\*------------------------------------*/

  .mini-nav,
  header,
  footer {
    display: none !important;
  }

  /*------------------------------------*\
#Content
\*------------------------------------*/

  #content {
    width: 100%;
    margin: 0;
    float: none;
    padding: 0;
  }

  /*------------------------------------*\
#Page Layout
\*------------------------------------*/
  .container {
    width: 100% !important;
    &.container__header {
      padding: 0 !important;
      min-height: 150px !important;
      border-bottom: 2px solid $black;
      margin-bottom: 25px;
      &.p-4-all {
        padding: 0 !important;
      }
    }
  }

  .p-4-all {
    padding: 0 !important;
  }

  .mb-2 {
    margin: 0 !important;
  }

  .section {
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  /*------------------------------------*\
#Typography
\*------------------------------------*/

  p {
    line-height: 1.5em;
  }

  .text-white {
    color: $black;
  }

  .container__header h1 {
    font-size: 3rem;
  }

  /*------------------------------------*\
#Header Tags
\*------------------------------------*/

  $step-size-heading: 0.35;

  $step-down: 0.6;

  $step-up: 0.95;

  // Mixins - Header
  @mixin heading-size($size) {
    font-size: $size * $step-size-heading + rem;
    margin-bottom: 0.2em;
    line-height: math.div($size, 4) + em;
  }

  @mixin heading-step-down($size) {
    font-size: $size * $step-down + rem;
  }

  $context-font: (
    section: 1,
    /* 100% */ article: 0.8,
    /* 80%  */ footer: 0.275,
    /* 67%  */ aside: 0.33 /* 33% */,
  );

  h1 {
    @include heading-size(6);
    font-weight: 400;

    &.small-heading {
      @include heading-step-down(6);
    }
  }

  h2 {
    @include heading-size(5);
    font-weight: 500;

    &.small-heading {
      @include heading-step-down(4);
    }
  }

  h3 {
    @include heading-size(4);

    &.small-heading {
      @include heading-step-down(4);
    }
  }

  h4 {
    @include heading-size(3);
    line-height: 1.2em;

    &.small-heading {
      @include heading-step-down(2.5);
      line-height: 1.25em;
    }
  }

  h5 {
    @include heading-size(2);
  }

  h6 {
    @include heading-size(1);
  }

  @each $element, $value in $context-font {
    #{$element} {
      h1 {
        @include heading-size(6 * $value);
      }

      h2 {
        @include heading-size(5 * $value);
      }

      h3 {
        @include heading-size(4 * $value);
      }

      h4 {
        @include heading-size(3 * $value);
      }

      h5 {
        @include heading-size(2 * $value);
      }

      h6 {
        @include heading-size(1 * $value);
      }
    }
  }

  /*------------------------------------*\
#Career and Fees
\*------------------------------------*/

  .container-application-item {
    text-align: left;
  }

  .application-items-list {
    margin: 1em 0 2em;
    .app-item {
      margin-top: 1em;
      height: 100%;
    }
  }
}
