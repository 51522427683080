/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Modals
\*------------------------------------*/

.modal-box {
    display: none;
    z-index: 1000;
    background: white;
    border-bottom: 1px solid #aaa;
    border-radius: 4px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    position: fixed;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 100%;
    min-height: 70vh;
    @include md {
        margin-top: 0em;
        height: auto;
        width: 600px;
        height: auto;
        min-height: 600px;
    }
    @include lg {
        width: 1200px;
    }
}

.modal-body {
    height: 100%;
    p {
        .btn {
            line-height: 1em;
        }
    }
    ol {
        margin: 0;
    }
    .screenshot {
        width: 100%;
        @include md {
            width: 60%;
        }
    }
    iframe {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    &.grid {
        grid-template-areas:
            'modal-thumb'
            'modal-text';
        grid-template-columns: 1fr;
        grid-template-rows: auto 3fr;
        @include md {
            grid-template-areas: 'modal-thumb modal-text';
            grid-template-columns: 1fr 3fr;
            grid-template-rows: auto;
        }
    }
    .content-section {
        height: 100%;
    }
}

.modal-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-thumbnail {
    grid-area: modal-thumb;
    margin-bottom: 1em;
    img {
        width: 100%;
        height: auto;
    }
    @include md {
        margin-bottom: unset;
    }
}

.modal-text {
    grid-area: modal-text;
}

.modal-box header h3,
.modal-box header h4,
.modal-box .modal-header h3,
.modal-box .modal-header h4 {
    margin: 0;
}

.modal-box .modal-body {
    padding: 2em;
}

.modal-box footer,
.modal-box .modal-footer {
    padding: 1em;
    border-top: 1px solid #ddd;
    background: rgba(0, 0, 0, 0.02);
    text-align: right;
}

.modal-overlay {
    opacity: 0;
    filter: alpha(opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3) !important;
}

a.close {
    line-height: 1;
    font-size: 1.5em;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    text-decoration: none;
    color: #bbb;
    background-color: $lt-grey;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    @include md {
        top: 1em;
        right: 1em;
    }
}

a.close:hover {
    color: #222;
    -webkit-transition: color 1s ease;
    -moz-transition: color 1s ease;
    transition: color 1s ease;
}
