/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Reset
\*------------------------------------*/
*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*------------------------------------*\
#Global
\*------------------------------------*/

.site {
    &.customize-support {
        #main-menu {
            top: 46px;

            @include md {
                top: 0;
            }
        }

        .main-nav {
            transition: all 0.2s ease;

            &.scrolled {
                margin-top: 46px;
            }

            @include logged-in-desktop {
                &.scrolled {
                    margin-top: 32px;
                }
            }
        }
    }
}

header {
    position: relative;
    display: block;
}

.container,
.container-fluid,
.container-grid {
    margin-right: auto;
    margin-left: auto;

    @include md {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container {
    margin: 0 auto;
    transition: all 0.3s ease;
    max-width: $cont-md;

    @include xs {
        max-width: $cont-xs;
    }

    @include sm {
        max-width: $cont-sm;
    }

    @include md {
        max-width: $cont-md;
    }

    @include lg {
        max-width: $cont-lg;

        &.container-narrow {
            @include container-narrow($cont-lg);
        }
    }

    @include xxl {
        max-width: $cont-xl;
    }

    &.container-cta {
        max-width: 100%;
        width: 100%;
    }
}

.container-fullpage {
    width: 100vw;
    height: 80vh;
}

.container-grid {
    transition: all 0.3s ease;

    @include md {
        max-width: 1170px;
    }

    @include lg {
        max-width: 1600px;
    }
}

.display-block {
    display: block;
}

.fill-height {
    height: 100%;
}

footer {
    .container {
        ul {
            margin: 0;
            padding: 0;
        }
    }
}

.footer__main {
    .container {
        padding: 3em 15px;

        @include lg {
            padding: 2em;
        }
    }
}

.footer__secondary {
    .container {
        padding: 3vw 15px;

        @include md {
            padding: 1vw 15px;
        }

        @include lg {
            padding: 1vw 30px;
        }
    }
}

.is-flex {
    display: flex;

    &.flex-center-both {
        align-items: center;
        justify-content: center;
    }

    &.flex-column {
        flex-direction: column;

        &.centered {
            justify-content: center;
        }
    }

    &.flex-row {
        flex-direction: row;

        &.centered {
            align-items: center;
        }
    }

    &.justify-content-start {
        justify-content: flex-start;
    }

    &.justify-content-end {
        justify-content: flex-end;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.justify-content-between {
        justify-content: space-between;
    }

    &.align-items-start {
        align-items: flex-start;
    }

    &.align-items-end {
        align-items: flex-end;
    }

    &.align-items-center {
        align-items: center;
    }

    &.align-content-start {
        align-content: flex-start;
    }

    &.align-content-end {
        align-content: flex-end;
    }

    &.align-content-center {
        align-content: center;
    }
    &.flex-wrap {
        flex-wrap: wrap;
    }
}

.overlap-left {
    @include lg {
        transform: translateX(-8em);
    }
}

.overlap-right {
    @include lg {
        transform: translateX(-6em);
    }
}

section {
    padding: 4em 15px;
    @include md {
        padding-left: 0;
        padding-right: 0;
    }

    &.full-section {
        padding: 0;
    }
}

.wrapper {
    width: 100%;
    margin: 0 auto 3em;
}

.wrapper-padded {
    padding: 0 15px;
}

/*------------------------------------*\
#Structure
\*------------------------------------*/
a.container__link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.blog-post {
    .container__single-post-image {
        width: 100%;
        position: relative;
        height: 260px;
        overflow: hidden;
        @include md {
            width: calc(50% - 2em);
            margin: 0 2em 0 0;
            height: 480px;
        }
        @include grid {
            width: 100%;
        }
    }
    .container__single-post-title-text {
        width: 100%;
        justify-content: center;
        @include md {
            width: 50%;
            padding: 0 10% 2em 0;
            justify-content: flex-end;
            height: 100%;
        }
        @include grid {
            width: 100%;
        }
    }
    .section__post-title-block {
        margin: 0 2em 2em;
        column-gap: 2em;

        @include md {
            margin: 0 0 2em;
            background-color: $ca-lt-grey-light-5;
        }
    
        @include grid {
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr; // Stacks by default
    
            @include md {
                margin-top: 0;
                grid-template-columns: 1fr 1fr; // Ensures balanced layout
                align-items: center; // Aligns content vertically
            }
        }
    }
    .single-post {
        width: 100%;
        @include md {
            width: calc(70% - 2em);
            margin-right: 2em;
        }
        @include grid {
            width: 100%;
        }
    }
    .single-post__sidebar {
        width: 100%;
        @include md {
            width: 30%;
        }
        a {
            color: $ca-dark-grey-dark-5;
            &:hover {
                color: $ca-dark-grey-dark-5;
            }
        }
        .post-sidebar__item {
            background-color: $ca-lt-grey-light-5;
            padding: 1em;
            &:hover {
                background-color: $ca-md-grey;
            }
        }
        @include grid {
            width: 100%;
        }
    }
}

.post-item-card {
    background-color: $white;
    grid-template-rows: min-content 1fr;
    position: relative;
}

.post-item__content {
    width: 100%;
    padding: 2em;
}

.post-item__excerpt {
    margin-bottom: 2em;
}

.post-item__image {
    overflow: hidden;
    height: 260px;
    position: relative;

    img {
        object-fit: contain;
        top: 50%;
        width: 100%;
        position: absolute;
        transform: translateY(-50%);
        @include md {
            height: 100%;
            width: unset;
        }
    }
    &.video {
        height: 213px;
    }
}

.ca-event-item {
    background-color: $purple;
    height: 100%;

    .ca-event-date {
        padding: 1.5em;
        background-color: $purple-dark-10;
        height: 100%;
    }

    .ca-event-details {
        padding: 1em;
        width: 100%;

        .event-location {
            padding: 0.5em 1em 0.5em 0;
        }
    }
    .event-info {
        i {
            margin-right: 0.2em;
        }
    }
}

.cafe-hours.mobile {
    display: block;
    @include lg {
        display: none;
    }
}

.career-post-content {
    div {
        margin-bottom: 2em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.career-posting-list__details {
    margin-bottom: 2em;
}

.career-posting-meta {
    margin-bottom: 1em;
    border-bottom: 1px dotted $lt-grey;
    padding-bottom: 1em;
}

.container__header {
    min-height: 20em;
    margin-top: 100px;
    @include grid {
        margin-top: unset;
    }

    @include lg {
        min-height: 25em;
    }
}

.container__home-page-features {
    max-width: 1600px;
}

.container__post {
    margin-bottom: 2em;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.container__program-post-item {
    padding: 2em 1em;

    @include md {
        padding: 2em;
    }
    @include lg {
        padding: 3em;
    }
}

.container__sport-single {
    padding: 2em;
    border: 2px solid $white;
}

.contract-options-item {
    padding: 2em;
    background-color: rgba($purple, 0.3);
}

.display_archive {
    max-width: 100%;
    margin: 0 auto;

    @include md {
        max-width: 800px;
    }

    @include lg {
        max-width: 960px;
    }
}

.download-item {
    align-items: flex-start;
    flex-direction: column;
    @include md {
        align-items: center;
        flex-direction: row;
    }
    .download-item-meta {
        margin-top: 0.5em;
        @include md {
            margin-top: unset;
        }
    }
}

.grid-card {
    padding: 2em;
    background-color: $white;
    &.lt-grey {
        background-color: $off-white;
    }
}

.ltad-item-card {
    padding: 1.5em;
}

.main-content {
    padding-top: 100px;
    width: 100vw;
}

.margin-0 {
    margin: 0 !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.padding {
    padding: 0;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.payment-methods {
    padding-top: 2em;
}

.section__block-beliefs {
    padding: 4em 0;
}

.section__block-ca-way {
    padding: 4em 0;
    .container {
        padding: 3em 15px;
        max-width: 320px;
        @include md {
            max-width: 800px;
            padding: 3em;
        }
    }
}

.section__block-cta {
    min-height: 15em;
    padding: 3em 15px;
    @include md {
        padding: 4em;
        min-height: 20em;
    }

    @include xl {
        min-height: 25em;
    }
}

.section__block-careers {
    .grid__3col {
        grid-template-columns: 1fr;
        grid-row-gap: 1.2em;

        @include lg {
            grid-template-columns: repeat(3, 1fr);
            grid-row-gap: 0;
        }
    }
}

.section__fullpage {
    height: 70vh;
}

.section__home-intro {
    padding: 2em 0;
    margin-top: 100px;

    @include grid {
        margin-top: unset;
    }

    @include sm {
        padding: 4em;
    }

    @include md {
        padding: 4em 0 8em;
    }

    @include lg {
        padding: 6em 0 12em;
    }
}

.section__block-contact {
    .section__text + .section__text {
        margin-top: 0;
    }

    .section__block-content-fee {
        padding: 3em;

        @include lg {
            padding: 2em 4em;
        }
    }
}

.section__block-home-page-features {
    @include desktop-nav {
        margin-top: -10em;
    }

    .home-page-feature {
        flex-direction: column;
        padding: 2em 3em;
        grid-gap: 1.5em;
        grid-template-rows: auto 3fr;

        @include grid {
            flex-direction: unset;
        }

        .container__home-page-feature-content {
            p {
                margin-bottom: 1.2em;
            }
        }
    }
}

.section__no-posts {
    padding: 3em;
    margin-top: 1.5em;

    &.border-top {
        border-top: 1px solid;
    }
}

.section__text {
    width: 100%;
    & + .section__no-posts {
        padding: 0;
    }
}

.section__block-splash {
    min-height: auto;

    .container-fluid {
        flex-direction: column;
        margin: 0 auto;
        max-width: 1600px;

        @include md {
            flex-direction: row;
        }

        .section__text {
            text-align: center;

            @include md {
                text-align: left;
            }
        }
    }

    @include md {
        min-height: 30em;
    }
}

.single-post__column {
    max-width: 300px;
    margin: 0 auto;

    @include md {
        max-width: 365px;
    }
}

.single-post-content {
    max-width: 960px;
    margin: 0 auto;
}

.ss-item-card {
    background-color: $white;
}

.fee-item-fee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    border-top: 1px dotted $dk-grey;
    &:last-child {
        border-bottom: 1px dotted $dk-grey;
    }
    @include md {
        padding: 0;
        justify-content: center;
        border: none;
        &:last-child {
            border: none;
        }
    }
}

.fee-item {
    padding: 1.2em 1em 1em;
    &:nth-child(odd) {
        background-color: rgba($purple, 0.1);
    }
    &:nth-child(even) {
        background-color: rgba($purple, 0.25);
    }
}

.fee-section {
    border-bottom: 4px solid $dk-grey;
    &:last-child {
        border: none;
    }
}

.fee-table-header {
    padding: 1em;
}

/*------------------------------------*\
#Content Blocks
\*------------------------------------*/
.application-items {
    .icon-block-item {
        flex-direction: column;
        margin-bottom: 1em;
        padding: 2em;
        .icon_block__content {
            text-align: center;
            @include md {
                text-align: left;
            }
        }
        .icon_block__icon {
            height: 60px;
        }
        @include md {
            flex-direction: unset;
            padding: 1em 2em;
            .icon-block__icon {
                width: 10%;
                margin: 0 0 1em 0;
            }
            .icon-block__meta {
                width: 90%;
                align-self: center;
            }
        }
    }
}
.application-item {
    padding: 20px;
    border: 1px solid $ca-lt-grey;
    border-radius: 10px;
}

.ca-event-block {
    &.grid__1col {
        max-width: 576px;
    }
}

.content-section {
    & ~ .content-section {
        margin-top: 1em;
    }

    .content-section-thumb {
        height: 300px;
        overflow: hidden;
        position: relative;
        img {
            object-fit: contain;
            top: 50%;
            width: 100%;
            position: absolute;
            transform: translateY(-50%);
        }
    }

    &.paint-splash {
        flex-direction: column;
        @include md {
            flex-direction: row;
        }
    }
}

.icon-block__meta,
.pages-content__meta {
    height: 100%;
}

.icon-block-item,
.pages-content-item {
    padding: 2em 15px;
    @include md {
        padding: 2em 0;
    }
}

.icon-block-item {
    @include md {
        padding: 2em;
    }
}

.pages-content-item {
    &.image-thumb {
        display: flex;
        align-items: center;
        padding: 0 0 2em;
        flex-direction: column;
     
        @include md {
            flex-direction: row;
            //padding: 0 0 6em;
            &.push-right {
                flex-direction: row-reverse;
            }
        }
        &.grid {
            flex-direction: column;
            display: grid;
        }
    }
    .pages-content__meta {
        padding: 2em;
        width: 100%;
        &.grid {
            padding: 0;
        }
    }
}

.section__content-block {
    & > div {
        margin-bottom: 1em;
    }
}

.text-large {
    width: 100%;
}
/*------------------------------------*\
#Grids
\*------------------------------------*/

$grid_columns: (
    50%: '2',
    33.33%: '3',
    25%: '4',
    20%: '5',
    16.66%: '6',
);

$grid_gap: 1em;

$content-text-image-gap: 2em;

.grid {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: start;
    grid-gap: 1em;

    @include grid {
        display: grid;
        display: -ms-grid;
        min-height: 0;
        min-width: 0;
        transition: all 0.2s ease;
        margin: 0 auto;

        @include md {
            grid-row-gap: 2em;
        }

        @include lg {
            grid-template-columns: auto;
          
        }

        &.grid-full {
            max-width: 100%;
        }

        &.grid_no-gap {
            grid-gap: 0;
        }
    }

    &.grid-row {
        grid-auto-rows: 1fr;
    }

    .grid-item {
        &.pages-content-item {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start; // Ensures alignment for content below


            &.default {
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
            }

            &.padded-box {
                padding: 1.5em;
                @include md{
                    padding: 2em;
                }
            }
    
            &.image-card,
            &.outline {
                padding: 0;
                flex-direction: column;
            }
    
            &.column-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                width: 100%;
                grid-row-gap: 1em;
                
                .pages-content__item-card {
                    flex-grow: 1; // Ensures image takes available height
                    &.img-round {
                        flex-grow: unset;
                    }
                }
                &.image-card {
                    grid-row-gap: 0;
                }
             
            }
    
            &.row-item {
                display: grid;
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                text-align: center;
                justify-items: center;
                padding: 0;

                &.padded-box {
                    padding: 2em;
                }
        
                @include md {
                    grid-template-columns: 1fr 3fr; // Image (1fr), Text (3fr)
                    grid-auto-flow: column;
                    grid-column-gap: 2em;
                    text-align: left;
                    justify-items: start;
                    align-items: stretch;
                }
        
                > * {
                    width: 100%;
                    min-height: 100%;
                }
        
                .pages-content__item-card {
                    flex-grow: 1;
                    height: auto;
                    max-height: 180px;
                }
        
                // Ensures images inside row-item remain a perfect circle
                .pages-content__item-card.img-round {
                    width: 100%;
                    max-width: 180px;
                    height: auto;
                    aspect-ratio: 1 / 1;
                    flex-shrink: 0;
                }
        
                .pages-content__meta {
                    flex-grow: 1;
                    min-width: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
    
    // Ensure all `.grid-column` layouts have equal item heights
    .grid.grid-column,
    .grid.grid-boxed-column {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-items: stretch; // Forces equal height
    }

    &.blog-post-content {
        @include grid {
            grid-template-areas:
                'single-post-content'
                'single-post-sidebar';
            grid-gap: 2em;
            grid-template-columns: 1fr;
            @include md {
                grid-template-areas: 'single-post-content single-post-sidebar';
                grid-template-columns: minmax(600px, 5fr) minmax(300px, 2fr);
            }
        }
    }

    &.content-text-image {
        flex-direction: column-reverse;
        @include md {
            flex-direction: row;
        }
        @include grid {
            grid-template-areas:
                'content-image'
                'content-text';
            grid-template-columns: 1fr;
            grid-column-gap: 2em;
            &.center {
                grid-template-areas:
                    'content-text'
                    'content-image';
            }
        }
        .content-section-text {
            width: 100%;
            @include md {
                width: 60%;
            }
            @include grid {
                width: auto;
            }
        }
        .content-section-thumb {
            width: 100%;
            margin-bottom: 1em;
            @include md {
                width: calc((40%) - #{$content-text-image-gap});
                margin-bottom: 0;
            }
            @include grid {
                width: auto;
            }
        }
        &.right {
            .content-section-thumb {
                @include md {
                    margin-left: #{$content-text-image-gap};
                    @include grid {
                        margin-left: 0;
                    }
                }
            }
            @include grid {
                @include md {
                    grid-template-areas: 'content-text content-image';
                    grid-template-columns: 3fr 460px;
                }
            }
        }
        &.left {
            flex-direction: column-reverse;
            @include md {
                flex-direction: row-reverse;
            }
            .content-section-thumb {
                @include md {
                    margin-right: #{$content-text-image-gap};
                    @include grid {
                        margin-right: 0;
                    }
                }
            }
            @include grid {
                @include md {
                    grid-template-areas: 'content-image content-text';
                    grid-template-columns: 460px 3fr;
                }
            }
        }
        &.paint-splash {
            img {
                width: 60%;
                @include grid {
                    width: unset;
                }
            }
            .content-section-text {
                width: 40%;
                @include grid {
                    width: unset;
                }
            }
        }
    }

    @each $width, $column in $grid_columns {
        &.grid__#{$column}col {
            .grid-item {
                width: 100%;
                flex: 1 1 372px;
                margin-bottom: #{$grid_gap};

                @include md {
                    max-width: calc(#{$width} - #{$grid_gap});
                    width: calc(#{$width} - #{$grid_gap});
                    margin-right: #{$grid_gap};
                    @include grid {
                        max-width: unset;
                        width: unset;
                        margin-right: unset;
                        row-gap: 1em;
                    }
                    &:nth-of-type(#{$column}n) {
                        margin-right: 0;
                    }
                }
                &.mb-0 {
                    margin-bottom: 0;
                }
                @include grid {
                    margin-bottom: 0;
                }
            }
        }
    }

    $columns_count: 2, 3, 4, 5, 6;

    @each $column_count in $columns_count {
        &.grid__#{$column_count}col {
            justify-content: space-between;
        }
    }

    &.grid-full {
        width: 100%;
        @each $width, $column in $grid_columns {
            &.grid__#{$column}col {
                .grid-item {
                    width: 100%;
                    margin-bottom: 0;
                    @include md {
                        width: #{$width};
                        margin-right: 0;
                        @include grid {
                            width: unset;
                        }
                    }
                }
            }
        }
    }

    &.rows {
        flex-direction: column;
        @include grid {
            flex-direction: unset;
        }
    }

    &.grid-icon-text-column {
        grid-gap: 1.5em;
        /* grid-template-rows: minmax(80px, 120px) auto; */
        grid-template-rows: minmax(120px, 220px) auto;
    }

    &.post-item-card {
        flex-direction: column;
        @include grid {
            grid-template-areas:
                'blog-image'
                'blog-content';
            grid-template-columns: 1fr;
            margin: 0;
        }
    }

    &.post-item__content {
        grid-template-areas:
            'blog-category'
            'blog-title'
            'blog-date'
            'blog-excerpt'
            'read-more';
    }

    &.cafe-hours {
        display: none;

        @include lg {
            display: grid;
            grid-template-columns: 1fr repeat(4, 2fr);
            grid-template-rows: repeat(3, 1fr);
            grid-template-areas:
                ' . all-grades high-school junior-high elementary'
                'mon-thurs mon-thurs-all-grades mon-thurs-high-school mon-thurs-junior-high mon-thurs-elementary'
                'friday friday-all-grades friday-high-school friday-junior-high friday-elementary';
        }
    }

    &.campaign {
        grid-template-columns: 1fr;
        text-align: center;
        padding: 1em;
        grid-gap: 0.5em;

        @include md {
            grid-template-columns: 2fr 7fr;
            text-align: left;
            grid-gap: 1em;
        }

        @include lg{
            grid-template-columns: 1fr 7fr;
        }

        a {
            font-weight: 600;

            &:hover {
                color: $dk-purple;
            }
        }
        &:nth-child(odd) {
            background-color: $lt-purple-light-50;
        }
        &:nth-child(even) {
            background-color: $lt-purple-light-40;
        }
    }

    &.learning-model-item {
        grid-template-columns: 1fr;
        grid-template-areas:
            'lm_graphic'
            'lm_text';
        padding: 2em 0;

        @include md {
            grid-template-columns: 3fr 5fr;
            grid-template-areas: 'lm_graphic lm_text';
        }
        &.pull-right {
            float: none;
            @include lg {
                grid-template-columns: 5fr 3fr;
                grid-template-areas: 'lm_text lm_graphic';
            }
        }
    }

    &.resource-item {
        grid-template-rows: 100px auto;
    }

    &.tuition-fee-table-header {
        display: none;
        @include md {
            display: grid;
            grid-template-columns: 4fr 1fr 1fr 1fr;
            grid-template-areas: 'fee-year academy-header collegiate-header kindergarten-header';
        }
    }

    &.container__application-steps {
        grid-template-columns: auto;
        text-align: center;
        margin-bottom: 4em;

        &:last-of-type {
            margin: 0;
        }

        @include md {
            grid-template-columns: auto 1fr;
            text-align: left;
        }
    }

    &.container__fee-posts {
        grid-gap: 1em;
        grid-template-areas:
            'application_fee'
            'initiation_fee'
            'academy_tuition'
            'collegiate_tuition'
            'other_fees'
            'supplies_fee'
            'bussing_1'
            'bussing_2';
        max-width: 1600px;

        @include sm {
            grid-template-areas:
                'application_fee application_fee'
                'initiation_fee initiation_fee'
                'academy_tuition collegiate_tuition'
                'other_fees supplies_fee'
                'bussing_1 bussing_2';
        }

        @include lg {
            margin: 2em auto;
            grid-template-areas:
                'application_fee application_fee application_fee initiation_fee initiation_fee initiation_fee'
                'academy_tuition academy_tuition collegiate_tuition collegiate_tuition other_fees other_fees'
                'supplies_fee supplies_fee bussing_1 bussing_1 bussing_2 bussing_2';
        }
    }

    &.contract-options {
        grid-template-areas:
            'option-1'
            'option-2'
            'option-3';
        @include md {
            grid-template-areas: 'option-1 option-2 option-3';
        }
    }

    &.grid__gap-narrow {
        grid-gap: 1.5em;
    }

    &.integrated-studies-post-item {
        grid-template-areas: 'is-icon is-title';
        grid-template-columns: minmax(80px, 120px) minmax(100px, 250px);
        grid-gap: 0.5em;

        @include md {
            grid-gap: 1em;
        }
    }

    &.pillar_item {
        .pillar_item-icon {
            width: 20%;
            margin-right: 5%;
            @include grid {
                width: 100%;
            }
        }
        .pillar_item-text {
            width: 75%;
            @include grid {
                width: 100%;
            }
        }
        @include grid {
            grid-template-areas: 'pillar-icon' 'pillar-text';
            grid-template-rows: auto 5fr;

            @include md {
                grid-template-areas: 'pillar-icon pillar-text';
                grid-template-columns: 2fr 10fr;
                grid-template-rows: 1fr;
            }
        }
    }

    &.reach_item {
        grid-template-areas: 'reach-image reach-text';
        grid-template-columns: 2fr 5fr;
        grid-template-rows: auto;

        @include reach-break {
            grid-template-areas:
                'reach-image'
                'reach-text';
            grid-template-rows: max-content auto 1fr;
            grid-template-columns: 1fr;
        }
    }

    &.team-members {
        grid-template-columns: 1fr;
        justify-content: center;
        @include md {
            grid-template-columns: repeat(2, 1fr);
        }
        @include team-break {
            grid-template-columns: repeat(3, 1fr);
            &.grid__2col {
                grid-template-columns: repeat(2, 372px);
            }
        }
    }

    &.team-post-item__team-member {
        @include grid {
            grid-template-areas:
                'team-thumb'
                'team-content';
            grid-template-rows: max-content 2fr;
        }
    }

    &.testimonial-post-quote {
        @include grid {
            grid-template-areas:
                'quote'
                'testimonial-meta';
        }

        .testimonial-post-meta {
            display: flex;
            flex-direction: column;
            @include grid {
                display: grid;
                flex-direction: unset;
                grid-template-areas:
                    'author'
                    'author-info';
            }
        }
    }
    .fee-column {
        width: 100%;
        flex-direction: column;
        @include md {
            flex-direction: row;
        }
        .fee-cell {
            flex: 1 1 0px;
        }
    }

    &.fee-item {
        grid-template-columns: 1fr;
        @include md {
            grid-template-columns: 4fr 5fr;
            min-height: 6em;
            align-items: center;
        }
    }
    &.fee-table-header {
        display: none;
        @include md {
            display: grid;
            grid-template-columns: 4fr 5fr;
        }
    }
    &.video-block {
        @include grid {
            grid-template-areas: 'video' 'video-text';

            @include md {
                grid-template-areas: 'video video-text';
                grid-template-rows: 1fr;
            }
        }
        &.align-top {
            align-items: start;
        };
        &.align-center {
            align-items: center;
        };
        &.align-bottom {
            align-items: end;
        };
        .grid-item {
            min-height: 360px;

            &.video-block-video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;

                .mejs-controls {
                    display: none !important;
                    visibility: hidden !important;
                }
                .mejs-overlay-play {
                    background-color: rgba(0, 0, 0, 0.35);
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
            &.video-block-text {
                justify-content: center;
                align-items: center;
                padding: 2em;
                @include md {
                    align-items: unset;
                }
                .sub-title,
                .video-block-description {
                    width: 100%;
                }
                &.text-block {
                    padding: 2em;
                }
                &.full-block {
                    padding: 1em;
                    @include md {
                        padding: 2em;
                    }
                }
            }
        }
    }
}

.grid__auto-col {
    grid-template-columns: 1fr;
    grid-gap: 1em;
    grid-auto-rows: 1fr;

    @include md {
        grid-template-columns: repeat(2, 1fr);
    }

    @include lg {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    &.container__ca-event-list {
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(100px, 320px));
    }
}

.grid__2col,
.grid__3col,
.grid__4col,
.grid__5col,
.grid__6col {
    grid-template-columns: minmax(280px, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 1em;

    @include md{
        grid-gap: 2em;
    }
}

.grid__2col {
    @include md {
        grid-template-columns: repeat(2, 1fr);
    }

    &.card-list {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 3fr;

        @include md {
            grid-template-columns: 1fr 4fr;
            grid-template-rows: auto;
        }
    }
}

.grid__3col {
    &.container__posts {
        grid-template-columns: 1fr;
        width: 100%;

        @include md {
            grid-template-columns: repeat(2, 1fr);
        }
        @include lg {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include md {
        &.container__team-posts {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 5em;
            grid-auto-rows: auto;
        }
    }

    @include md {
        grid-template-columns: repeat(3, 1fr);

        &.container__testimonial-posts {
            grid-template-columns: 1fr;
        }
    }

    @include lg {
        &.container__testimonial-posts {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include team-break {
        &.container__team-posts {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 8em;
        }
    }
}

.grid__4col {
    @include md {
        grid-row-gap: 3em;
        grid-template-columns: repeat(4, 1fr);
    }

    &.container__strat-goals {
        grid-row-gap: 8em;

        @include md {
            grid-template-columns: repeat(2, 1fr);
        }

        @include lg {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.container__menu-a-la-carte {
        grid-row-gap: 4em;
        grid-template-rows: auto;

        @include md {
            grid-column-gap: 4em;
            grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}

.grid__5col {
    @include md {
        grid-template-columns: repeat(5, 1fr);
    }

    &.container__reach {
        @include grid {
            grid-template-areas:
                'respect'
                'enthusiasm'
                'altruism'
                'commitment'
                'honesty';
            grid-template-columns: 1fr;

            @include reach-break {
                grid-template-areas: 'respect enthusiasm altruism commitment honesty';
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }
}

.grid__6col {
    grid-template-rows: auto;

    @include sm {
        grid-template-columns: repeat(2, 1fr);
    }

    @include lg {
        grid-template-columns: repeat(6, 1fr);
    }
}

.grid__footer {
    grid-template-areas:
        'info'
        'footer-submenu';
    grid-template-columns: 1fr;
    grid-row-gap: 3em;
    width: 100%;

    @include md {
        grid-template-areas: 'info footer-submenu';
        grid-template-columns: 3fr auto;
        grid-column-gap: 4em;
    }

    .footer-info {
        width: 100%;
        @include md {
            width: 40%;
        }
        @include grid {
            width: unset;
        }
    }

    .grid__footer-subgrid {
        justify-content: flex-start;
        .grid-item {
            width: 100%;
            margin-bottom: 2em;
        }
        @include md {
            width: 60%;
            justify-content: flex-end;
            .grid-item {
                width: auto;
                margin-bottom: unset;
                margin-right: 2em;
            }
        }
        @include grid {
            grid-gap: 0;
            grid-auto-flow: row;
            width: unset;
            grid-gap: 2em;

            @include md {
                grid-template-columns: 2fr 2fr;
            }
            @include lg {
                grid-auto-flow: column;
            }
        }
    }
}

.site-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        'mini-nav'
        'main-nav'
        'content'
        'footer';
    width: 100vw;
    max-width: 100%;
}

.container-pagination {
    width: 300px;
    margin: 0 auto;
    text-align: center;
    & > ul {
        padding-left: 0 !important;
        margin: 2em 1em 0em !important;
    }
    @include md {
        width: 700px;
    }
    @include lg {
        width: 900px;
    }
}

/*------------------------------------*\
#Grid Areas
\*------------------------------------*/

.post-item__category {
    grid-area: blog-category;
}

.post-item__content {
    grid-area: blog-content;
}

.post-item__excerpt {
    grid-area: blog-excerpt;
}

.post-item__image {
    grid-area: blog-image;
}

.blog-read-more {
    grid-area: read-more;
}

.post-item__title {
    grid-area: blog-title;
}

.post-item__publish-date {
    grid-area: blog-date;
}

$grade-list: all-grades elementary junior-high high-school;
$day-list: mon-thurs friday;

.cafe-days {
    @each $day in $day-list {
        &.#{$day} {
            grid-area: #{$day};
            display: block;
        }
    }
}

.cafe-grade {
    @each $grade in $grade-list {
        &.#{$grade} {
            grid-area: #{$grade};
        }
    }
}

.cafe-grade-mobile {
    grid-area: cafe-grade-mobile;
}

.cafe-times {
    @each $day in $day-list {
        &.#{$day} {
            @each $grade in $grade-list {
                &.#{$grade} {
                    grid-area: #{$day}-#{$grade};
                }
            }
        }
    }
}

.mon-thurs__wrapper {
    grid-area: mon-thurs-wrapper;
}

.friday__wrapper {
    grid-area: friday-wrapper;
}

.content-section-image {
    grid-area: content-image;
}

.content-section-text {
    grid-area: content-text;
}

.contract-option-1 {
    grid-area: option-1;
}

.contract-option-2 {
    grid-area: option-2;
}

.contract-option-3 {
    grid-area: option-3;
}

#content {
    grid-area: content;
}

footer {
    grid-area: footer;
}

.main-nav {
    grid-area: main-nav;
}

.fee-application-fee {
    grid-area: application_fee;
}

.fee-initiation-fee {
    grid-area: initiation_fee;
}

.fee-academy-tuition {
    grid-area: academy_tuition;
}

.fee-collegiate-tuition {
    grid-area: collegiate_tuition;
}

.fee-other-fees {
    grid-area: other_fees;
}

.fee-grades-1-12-fee {
    grid-area: supplies_fee;
}

.fee-bussing-optional {
    grid-area: bussing_1;
}

.fee-bussing-optional-2 {
    grid-area: bussing_2;
}

.footer-info {
    grid-area: info;
}

.grid__foter-subgrid {
    grid-area: footer-submenu;
}

.integrated-studies-item-icon {
    grid-area: is-icon;
}

.integrated-studies-item-title {
    grid-area: is-title;
}

.mini-nav {
    grid-area: mini-nav;
}

.learning-model-graphic {
    grid-area: lm_graphic;
}

.learning-model-text {
    grid-area: lm_text;
}

.pillar_item-icon {
    grid-area: pillar-icon;
}

.pillar_item-text {
    grid-area: pillar-text;
}

.reach-image {
    grid-area: reach-image;
}

.reach_item {
    &.respect {
        grid-area: respect;
    }
    &.enthusiasm {
        grid-area: enthusiasm;
    }
    &.altruism {
        grid-area: altruism;
    }
    &.commitment {
        grid-area: commitment;
    }
    &.honesty {
        grid-area: honesty;
    }
}

.reach-text {
    grid-area: reach-text;
}

.reach-title {
    grid-area: reach-title;
}

.reach-description {
    grid-area: reach-description;
}

.single-post__content {
    grid-area: single-post-content;
}

.single-post__sidebar {
    grid-area: single-post-sidebar;
}

.team-content {
    grid-area: team-content;
}

.team-post-item__thumbnail {
    grid-area: team-thumb;
}

.testimonial-post-author {
    grid-area: author;
}

.testimonial-post-author-info {
    grid-area: author-info;
}

.testimonial-post-meta {
    grid-area: testimonial-meta;
}

.testimonial-post-quote__text {
    grid-area: quote;
}

.tuition-fee {
    &.academy-header {
        grid-area: academy-header;
    }
    &.fee-year {
        grid-area: fee-year;
    }
    &.collegiate-header {
        grid-area: collegiate-header;
    }
    .kindergarten-header {
        grid-area: kindergarten-header;
    }
}

.tuition-fee-item-fee {
    &.academy {
        grid-area: academy-fee;
    }
    &.collegiate {
        grid-area: collegiate-fee;
    }
    &.kindergarten {
        grid-area: kindergarten-fee;
    }
}

/*------------------------------------*\
#Grid Cell
\*------------------------------------*/

.grid-cell {
    padding: 1em 0.65em;
}

/*------------------------------------*\
#Bus Route Table
\*------------------------------------*/
.bus-route-wrapper {
    display: flex;
    flex-direction: column;
    @include md {
        flex-direction: row;
    }

    tr.column-header {
        background-color: $purple;
        color: white;
    }
}

.bus-route-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
}

table th,
table td {
    padding: 0.625em;
    text-align: center;
}

table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
    }

    table td::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }
}

/* general styling */
body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.25;
}
