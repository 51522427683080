@use 'sass:color';

/*------------------------------------*\
#Colour Functions
\*------------------------------------*/
//Use example
//color: adjust-lightness($dk-grey, 30%); // Lightens $dk-grey by 30%
//color: adjust-lightness($lt-purple, -20%); // Darkens $lt-purple by 20%

@function adjust-lightness($color, $amount) {
  @return color.adjust($color, $lightness: $amount);
}

/*------------------------------------*\
#Colour Variables
\*------------------------------------*/
$orange: #f36c23;
$purple: #664a75;
$yellow: #eeb22a;
$dk-purple: #5e2154;
$extra-dk-purple: #42153b;
$lt-purple: #7f6789;

$ca-dk-grey: #7a6e66;
$ca-md-grey: #aca198;
$ca-lt-grey: #d6d1ca;

$dk-grey: #2d2d2d;
$md-grey: #8e8e8e;
$lt-grey: #eaeaea;

$white: #fff;
$black: #000;
$off-white: #f9f9f9;

$green: #87ce00;
$red: #ad031d;
$navy: #00226e;

$scoca-lt-purple: #c6bbd0;
$scoca-dk-purple: #624c79;

$fb-blue: #3b5998;
$fb-blue-hover: adjust-lightness($fb-blue, 15% );
$twitter-blue: #1da1f2;
$twitter-blue-hover: adjust-lightness($twitter-blue, 15% );
$linkedin-blue: #0077b5;
$linkedin-blue-hover: adjust-lightness($linkedin-blue, 15% );


$purple-dark-10: adjust-lightness($purple, -10% );
$purple-dark-15: adjust-lightness($purple, -15% );
$purple-dark-20: adjust-lightness($purple, -20% );
$lt-purple-dark-20: adjust-lightness($lt-purple, -20% );
$ca-dark-grey-dark-5: adjust-lightness($ca-dk-grey, -5% );
$orange-dark-15: adjust-lightness($orange, -15% );
$yellow-dark-10: adjust-lightness($yellow, -10% );

$ca-lt-grey-light-5: adjust-lightness($ca-lt-grey, 5% );
$lt-purple-light-45: adjust-lightness($lt-purple, 45% );
$lt-purple-light-50: adjust-lightness($lt-purple, 50% );
$lt-purple-light-40: adjust-lightness($lt-purple, 40% );
$lt-grey-light-5: adjust-lightness($lt-grey, 5% );
$dk-grey-light-30: adjust-lightness($dk-grey, 30% );
$purple-light-20: adjust-lightness($purple, 20% );


/*------------------------------------*\
#Typography Variables
\*------------------------------------*/

$main-font: 'Uniform', Helvetica, Arial, sans-serif !important;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 600;

$text-primary: $dk-grey;

/*------------------------------------*\
#Background Colour Variables
\*------------------------------------*/

$body-bkg: $off-white;

/*------------------------------------*\
#Breakpoint Variables
\*------------------------------------*/
$desktop-nav-desc-breakpoint: 1100px;
$desktop-nav-breakpoint: 1024px;
$mini-nav-breakpoint: 810px;
$wpadmin-desktop-nav-breakpoint: 783px;
$form-breakpoint: 641px;
$team-breakpoint: 1060px;
$cafe-menu-breakpoint: 1600px;
$reach-breakpoint: 1380px;

$screen-xxl: 2000px;
$screen-xl: 1200px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 576px;
$screen-xs: 480px;

/*------------------------------------*\
#Typography Variables
\*------------------------------------*/

$fontawesome: 'Font Awesome 6 Sharp';

/*------------------------------------*\
#Container Width Variables
\*------------------------------------*/

$cont-xs: 460px;
$cont-sm: 556px;
$cont-md: 972px;
$cont-lg: 1200px;
$cont-xl: 1600px;
