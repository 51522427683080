/*------------------------------------*\
#Extend
\*------------------------------------*/

%list-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}

%chevron-bullets {
    content: '\f054';
    font-family: 'Font Awesome 6 Pro';
    padding-right: 10px;
    position: absolute;
    left: 0;
    display: inline-block;
}
