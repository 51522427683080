/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Button
\*------------------------------------*/
button {
    border: none;
    font-size: 1.5rem;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.btn {
    @include btn;

    &.btn-icon {
        padding: 20px;
    }

    &.btn-left-margin {
        margin-left: 10px;
    }

    &.btn-right-margin {
        margin-right: 10px;
    }

    &.btn-top-margin {
        margin-top: 10px;
    }

    &.btn-small,
    .btn-small a {
        margin: 0 0.5em;
    }

    &.btn-bottom-margin {
        margin-bottom: 10px;
    }

    &.btn-full {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.btn-notification-bar {
        padding: 0.5em 1em;
        margin: 1em;
        border: 2px solid $white;
        background-color: rgba($white, 0.1);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include ie {
            min-height: 100%;
        }
        &:hover {
            background-color: rgba($white, 0.25);
            cursor: pointer;
            color: $white;
        }
    }

    &.btn-outline {
        @include btn-outline;

        &.on-dark:hover {
            color: $purple;
        }
    }

    &.btn-outline-light {
        @include btn-outline-light;
    }

    &.btn-submit {
        background-color: $purple;
        border: none;

        &:hover {
            background-color: $purple-dark-10;
            cursor: pointer;
        }
    }

    &.btn-view {
        background-color: $yellow;
        border: none;

        &:hover {
            background-color: $yellow-dark-10;
            cursor: pointer;
        }
    }
    &.btn-disabled {
        background-color: $lt-grey;
        color: $dk-grey;
        border-radius: 0;
        border-color: $dk-grey;
    }
    &.btn-notification-bar {
        max-width: 320px;
    }
    &.btn-220 {
        width: 100%;
        @include md {
            max-width: 220px;
        }
    }
}

.btn-list-light {
    a {
        @include btn;
        @include btn-outline-light;
    }

    li {
        margin: 1em 0;
        display: block;

        @include lg {
            margin: 0 1em;
            display: inline-block;
        }
    }
}

.button {
    margin-top: 2em;
}

.btn-row {
    a {
        margin: 0 1em 1em 0;
        &.btn-center {
            margin: 0 0.5em 1em;
        }
    }
}

.cta-buttons {
    padding: 0;
    &.btn-primary {
        @include btn-purple;
    }
    .btn {
        width: 280px;
        @include md {
            width: 320px;
        }
    }

    &.btn-group {
        a {
            &.btn-primary {
                @include btn-purple;
            }
            &.btn-secondary {
                @include btn-outline;
                margin-top: 1em;
                margin-left: 0;
                @include md {
                    margin-top: 0;
                    margin-left: 1em;
                }
            }
        }
    }

    &.single-btn {
        &.btn-outline {
            .btn-primary {
                @include btn-outline;
            }
        }
    }

    &.post-links {
        a {
            margin: 1em 0;
        }
    }
    &.cta-header__btns,
    &.cta-block {
        .btn {
            &.btn-primary {
                background-color: $orange;
                border-color: $orange;
                color: $white;

                &:hover {
                    background-color: $orange-dark-15;
                    border-color: $orange-dark-15;
                    color: $white;
                }
            }
            &.btn-secondary {
                @include btn-outline-light;
            }
        }
    }

    &.icon-block {
        .btn {
            width: 100%;
        }
    }

    &.pages-content-item {
        .btn {
            min-width: 280px;
            @include md {
                min-width: 320px;
            }
        }
    }
}

.cta-button-single {
    .btn {
        @include sm {
            max-width: 288px;
        }

        @include lg {
            max-width: 320px;
        }
        &.wide-button {
            max-width: 100%;
            @include md {
                max-width: 500px;
            }
        }
    }

    &.bottom {
        padding-top: 15px;
    }
}

.container.p-4-all {
    .cta-buttons .btn {
        max-width: 100%;

        @include md {
            min-width: 320px;
        }
    }
}

a {
    &.download-item-meta {
        display: block;
        padding: 0.2em 0.5em;
        background-color: $purple;
        width: 160px;
        color: $white;
        font-weight: 400;
        &:hover {
            background-color: $purple-dark-10;
            color: $white;
        }

        span {
            font-size: 1.4rem;
        }
    }
}

.payment-method-buttons {
    flex-direction: column;
    a {
        margin-bottom: 0.75em;
    }
    @include md {
        flex-direction: row;
        margin-bottom: 0;
    }
    .btn {
        min-width: 280px;
    }
}

.plastiq-button {
    width: 221px;
    height: 70px;
    display: block;
    background-image: url(../images/plastiq-pay-button.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: 2em;

    span {
        visibility: hidden;
    }

    &:hover {
        background-position: 0 -70px;
    }
}

.section__we-are-ca {
    .btn-primary {
        @include btn-outline;
        margin: 0;
        text-align: center;
    }
    .cta-buttons {
        margin-bottom: 2em;
        @include md {
            text-align: left;
            margin-bottom: 0;
        }
    }
}

span#pq-pay-button-container img {
    width: 260px !important;
}

ul#menu-404-page-buttons {
    li {
        margin: 1em 1em 2em 1em;
    }
}

/*------------------------------------*\
#Content Sections
\*------------------------------------*/

.content-section {
    .cta-buttons {
        padding-top: 0;
    }
}

.section__header-cta {
    margin-top: 1.5em;
}

/*------------------------------------*\
#Pagination
\*------------------------------------*/

ul.page-numbers {
    @extend %list-unstyled;
    li {
        display: inline-block;
        padding-bottom: 1em;
        @include md {
            padding-bottom: 0;
        }
        &:first-child {
            padding: 0;
        }
        a {
            &.page-numbers {
                background-color: transparent;
                color: $purple;
                &:hover {
                    background-color: $purple;
                    color: $white;
                    curssor: pointer;
                }
            }
            &.next,
            &.prev {
                .fa-arrow-circle-left {
                    margin: 0;
                    @include md {
                        margin-right: 0.5em;
                    }
                }
                .fa-arrow-circle-right {
                    margin: 0;
                    @include md {
                        margin-left: 0.5em;
                    }
                }
                span {
                    display: none;
                    @include md {
                        display: inline;
                    }
                }
            }
        }
        a.page-numbers,
        span.page-numbers {
            border-radius: 4px;
            border: 1px solid $purple;
            line-height: 45px;
            height: 37px;
            padding: 0 15px;
            display: block;
            height: 45px;
            display: block;
            min-width: 45px;
            text-align: center;
        }
    }
    span.page-numbers {
        background-color: $purple;
        color: $white;
        &.dots {
            border: none;
            background-color: transparent;
            color: $purple;
            padding: 0;
            min-width: auto;
        }
    }
}

/*------------------------------------*\
#Team Social Links
\*------------------------------------*/
.team-social-links {
    list-style: none;
    padding: 0;
    margin: 10px auto 0; /* Center the entire list */
    display: flex;
    gap: 12px; /* Adjust spacing between icons */
    justify-content: center; /* Center icons horizontally */
    align-items: center; /* Center icons vertically */
}

.team-social-links li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Ensure uniform width */
    height: 40px; /* Ensure uniform height */
    padding: 0;
}

.team-social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.team-social-links svg {
    width: 30px;
    height: 30px;
    fill: $purple; /* Default purple fill */
    transition: fill 0.3s ease-in-out, transform 0.2s ease-in-out;
}

ul.team-social-links {
    margin-top: 1em;
}

/* Hover Effect */
.team-social-links a:hover svg {
    fill: $purple-dark-20; /* Red hover color */
    transform: scale(1.1); /* Slight enlargement on hover */
}

/*------------------------------------*\
#Social Sharing
\*------------------------------------*/

.social-wrapper {
    margin: 30px 0;
    font-size: 0;
}

.social-wrapper span {
    font-weight: bold;
    padding-right: 10px;
    font-size: 16px;
}

.social-sharing {
    font-size: 17px;
    padding-right: 1em;
    &:last-child {
        padding-right: unset;
    }
}

@media only screen and (max-width: 600px) {
    .social-sharing {
        display: inline-block;
    }
}

.social-sharing {
    svg {
        position: relative;
        top: 0.15em;
        display: inline-block;
        height: 2em;
    }
    path {
        fill: white;
    }
}

.social-email circle {
    fill: $purple;
}

.social-email circle:hover {
    fill: $purple-dark-10
}

.social-facebook circle {
    fill: $fb-blue;
}

.social-facebook circle:hover {
    fill: $fb-blue-hover;
}

.social-twitter circle {
    fill: $twitter-blue;
}

.social-twitter circle:hover {
    fill: $twitter-blue-hover;
}

.social-linkedin circle {
    fill: $linkedin-blue;
}

.social-linkedin circle:hover {
    fill: $linkedin-blue-hover;
}
