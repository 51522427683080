/*------------------------------------*\
#Base Styles
\*------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.2em;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Colour Functions
\*------------------------------------*/
/*------------------------------------*\
#Colour Variables
\*------------------------------------*/
/*------------------------------------*\
#Typography Variables
\*------------------------------------*/
/*------------------------------------*\
#Background Colour Variables
\*------------------------------------*/
/*------------------------------------*\
#Breakpoint Variables
\*------------------------------------*/
/*------------------------------------*\
#Typography Variables
\*------------------------------------*/
/*------------------------------------*\
#Container Width Variables
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Extend
\*------------------------------------*/
.mini-nav_contact ul, nav ul, ul.page-numbers {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-section ul li:before,
.mce-content-body ul li:before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  padding-right: 10px;
  position: absolute;
  left: 0;
  display: inline-block;
}

/*------------------------------------*\
#Breakpoints
\*------------------------------------*/
/*------------------------------------*\
#Typography
\*------------------------------------*/
/*------------------------------------*\
#Gradient Mixins
\*------------------------------------*/
/*------------------------------------*\
#Container Mixins
\*------------------------------------*/
/*------------------------------------*\
#Border Mixins
\*------------------------------------*/
/*------------------------------------*\
#Button Mixins
\*------------------------------------*/
/*------------------------------------*\
#Feature Queries
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Header Tags
\*------------------------------------*/
h1 {
  font-size: 3.9rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
  font-weight: 400;
}
h1.small-heading {
  font-size: 2.5rem;
  line-height: 1.2em;
}

h2 {
  font-size: 3.25rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
  font-weight: 500;
}
h2.small-heading {
  font-size: 2rem;
  line-height: 1.2em;
}

h3 {
  font-size: 2.925rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
h3.small-heading {
  font-size: 1.5rem;
  line-height: 1.2em;
}

h4 {
  font-size: 1.95rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
  line-height: 1.2em;
}
h4.small-heading {
  font-size: 1rem;
  line-height: 1.2em;
  line-height: 1.25em;
}

h5 {
  font-size: 1.3rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}

h6 {
  font-size: 1.65rem;
  font-weight: 600;
}

section h1 {
  font-size: 3.9rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
section h2 {
  font-size: 3.25rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
section h3 {
  font-size: 2.6rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
section h4 {
  font-size: 1.95rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
section h5 {
  font-size: 1.3rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
section h6 {
  font-size: 0.65rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}

article h1 {
  font-size: 3.12rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
article h2 {
  font-size: 2.6rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
article h3 {
  font-size: 2.08rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
article h4 {
  font-size: 1.56rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
article h5 {
  font-size: 1.04rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
article h6 {
  font-size: 0.52rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}

footer h1 {
  font-size: 1.0725rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
footer h2 {
  font-size: 0.89375rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
footer h3 {
  font-size: 0.715rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
footer h4 {
  font-size: 0.53625rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
footer h5 {
  font-size: 0.3575rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
footer h6 {
  font-size: 0.17875rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}

aside h1 {
  font-size: 1.95rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
aside h2 {
  font-size: 1.625rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
aside h3 {
  font-size: 1.3rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
aside h4 {
  font-size: 0.975rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
aside h5 {
  font-size: 0.65rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}
aside h6 {
  font-size: 0.325rem;
  margin-bottom: 0.2em;
  line-height: 1.2em;
  font-weight: 500;
}

p ~ h3 {
  padding-top: 20px;
  padding-bottom: 8px;
  margin-bottom: 0;
}
p .header-content {
  line-height: 1.5em;
}
p + h2 {
  margin-top: 2em;
}

h2 + h3 {
  padding-top: 0;
}

a.btn {
  line-height: 1.5em;
}

blockquote {
  display: block;
  padding: 3em;
  margin: 1.5em 0;
  position: relative;
  background-color: rgba(102, 74, 117, 0.25);
}
blockquote:before {
  content: "\f10d";
  font-family: "Font Awesome 6 Sharp";
  font-weight: bold;
  position: absolute;
  font-size: 3em;
  top: 0.75em;
  left: 0.75em;
  width: 3rem;
  height: 2rem;
  color: rgba(94, 33, 84, 0.25);
  text-align: center;
  z-index: -300;
}
blockquote p {
  color: #5e2154;
  font-weight: 500;
  font-style: italic;
}

.form-confirmation p {
  font-size: 2rem;
  text-align: center;
}

i.right-side {
  padding-left: 0.5em;
}
i.left-side {
  padding-right: 0.5em;
}

.icon .icon-inline {
  display: inline-block;
}
.icon .icon-inline.left-side {
  padding-right: 10px;
}
.icon .icon-inline.right-side {
  padding-left: 10px;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line-clamp.one-line {
  -webkit-line-clamp: 1;
}
.line-clamp.two-line {
  -webkit-line-clamp: 2;
}
.line-clamp.three-line {
  -webkit-line-clamp: 3;
}

.pillar_item-text {
  text-align: center;
}
@media (min-width: 768px) {
  .pillar_item-text {
    text-align: left;
  }
}

.pillar_item-text-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.reach-title h2 {
  font-size: 3.2rem;
  margin-bottom: 0.25em;
}

.reach-text {
  text-align: left;
  justify-content: center;
}
@media (min-width: 1380px) {
  .reach-text {
    text-align: center;
    padding-left: 0;
  }
}

.script {
  font-family: "Pacifico", sans-serif;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-allcaps {
  text-transform: uppercase;
}

.text-normal {
  text-transform: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-bold,
strong {
  font-weight: 600;
}

.text-light {
  font-weight: 300;
}

.text-medium {
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}

.text-body-large {
  font-size: 1.5em;
}
.text-italics,
em {
  font-style: italic;
}

.text-nospace {
  letter-spacing: 0;
}

.text-spaced {
  letter-spacing: 2px;
}

.text-spaced-tight {
  letter-spacing: 1px;
}

.text-dk-grey {
  color: #2d2d2d !important;
}

.text-purple {
  color: #664a75;
}

.text-dk-purple {
  color: #5e2154;
}

.text-lt-purple {
  color: #7f6789;
}

.text-orange {
  color: #f36c23;
}

.text-yellow {
  color: #eeb22a;
}

.text-white {
  color: #fff;
}

.text-small {
  font-size: 0.85em;
  line-height: 1.5em;
}

/*------------------------------------*\
# Specific Styles
\*------------------------------------*/
.container__header h1 {
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.35em;
}
@media (min-width: 992px) {
  .container__header h1 {
    font-size: 5rem;
  }
}

.colour-block-content {
  padding: 1em;
  margin-bottom: 1.2em;
}
.colour-block-content.inline-block {
  display: block;
}
@media (min-width: 768px) {
  .colour-block-content.inline-block {
    display: inline-block;
  }
}
.colour-block-content.block {
  display: block;
}
.colour-block-content span {
  display: block;
}
@media (min-width: 768px) {
  .colour-block-content span {
    display: unset;
  }
}

.dont-breakout {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: none;
}

.no-break {
  white-space: nowrap;
}

h1.script {
  font-size: 6rem;
  margin-bottom: 0.5em;
}

h1.large-header {
  font-size: 4rem;
}

.subtitle {
  font-size: 1.8rem;
  color: rgb(121.5, 121.5, 121.5);
  margin-bottom: 1em;
}

.bkg-image-text.grid-outline h4 {
  font-size: 1em;
  font-weight: bold;
}
.bkg-image-text.grid-outline h4 span {
  padding: 0.7em;
  border: 2px solid white;
}

.career-post-approach ul {
  list-style-type: disc;
}

.career-post-content__highlights ul ~ h3 {
  padding-top: 1em;
}

.career-posting-meta p {
  font-size: 1.4rem;
  line-height: 1.5em;
}

.ca-event-item .ca-event-date__date {
  font-size: 7rem;
  font-family: mongoose, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.ca-event-item .ca-event-date__month {
  font-family: mongoose, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 4rem;
}

.container__post p a {
  font-weight: 600;
}
.container__post p a:hover {
  color: #7f6789;
}

.icon-block__title {
  margin-bottom: 0.5em;
}

.menu-price {
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 0.2em;
}

.pages-content__title {
  margin-bottom: 1em;
}

.post-item__meta {
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .post-item__meta {
    font-size: 1.6rem;
  }
}
.post-item__meta span {
  display: inline-block;
}

section .option-title h3 {
  margin-top: 0.5em;
  font-size: 2rem;
}

.section p a {
  font-weight: bold;
}
.section p a:hover {
  color: #7f6789;
}
.section p + ol, .section p + ul {
  margin-top: 0.8em;
  margin-bottom: 1.2em;
}
.section ol,
.section ul {
  list-style-position: outside;
  margin: 2em 2em 0;
}
.section ol > li > ol,
.section ul > li > ol {
  margin-top: 0.5em;
  list-style-type: lower-alpha;
}
.section ol > li > ol > li:last-child,
.section ul > li > ol > li:last-child {
  padding-bottom: 0.25em;
}
.section ol > li > ol > li > ol,
.section ul > li > ol > li > ol {
  list-style-type: disc;
}
.section ol > li > ul,
.section ul > li > ul {
  margin-top: 1em;
  list-style-type: disc;
  padding-bottom: 0;
}
.section ol {
  list-style: decimal;
}
.section ol.lower-alpha {
  list-style: lower-alpha;
}
.section ol.upper-alpha {
  list-style: upper-alpha;
}
.section ol.lower-roman {
  list-style: lower-roman;
}
.section ol.upper-roman {
  list-style: upper-roman;
}
.section strong {
  font-weight: 600;
}
.section em {
  font-style: italic;
}
.section img {
  width: 100%;
  height: auto;
}
.section.career-post-skills p + h3 {
  margin-top: 2em;
}
.section.career-post-skills h3 {
  margin-bottom: 1em;
}
.section.career-post-skills ul {
  margin: 0 3em 2em;
  list-style-type: disc;
}
.section__block-content-receipts h4 {
  font-size: 2.2rem;
  font-weight: 600;
}

span.grade {
  margin-bottom: 0.5em;
}

.reach-description p {
  font-size: 1.65rem;
}

.tuition-fee-year h3 {
  font-size: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .tuition-fee-year h3 {
    text-align: left;
  }
}

.fee-cell {
  text-align: left;
}
@media (min-width: 768px) {
  .fee-cell {
    text-align: center;
  }
}

.fee-item-details {
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .fee-item-details {
    margin: 0;
  }
}
.fee-item-details h4 {
  line-height: 1.2em;
}
.fee-item-details p + p {
  margin-top: 0.5em;
}

.fee-item-fee h4 {
  font-size: 2 rem;
  margin-bottom: 1em;
}
.fee-item-fee h5 {
  display: block;
}
@media (min-width: 768px) {
  .fee-item-fee h5 {
    display: none;
  }
}
.fee-item-fee .fee-amount {
  font-size: 2 rem;
  text-align: right;
}
@media (min-width: 768px) {
  .fee-item-fee .fee-amount {
    text-align: center;
  }
}

.text-note {
  padding: 1em;
  border-radius: 4px;
}
.text-note.important {
  background-color: rgba(173, 3, 29, 0.15);
  color: #ad031d;
}
.text-note.warning {
  background-color: rgba(238, 178, 42, 0.35);
  color: #2d2d2d;
}
.text-note.good {
  background-color: rgba(135, 206, 0, 0.25);
  color: #2d2d2d;
}
.text-note.accent {
  background-color: rgba(102, 74, 117, 0.25);
  color: #664a75;
}

.scoca-post-meta {
  line-height: 1.2em;
}

.video-block-text {
  text-align: center;
}
@media (min-width: 768px) {
  .video-block-text {
    text-align: left;
  }
}

.video-item__title h4 {
  font-size: 2rem;
  letter-spacing: 1px;
}

.video-item__publish-date {
  margin-bottom: 0.5em;
}

.wp-caption-text {
  margin-bottom: 1em;
  font-size: 1.4rem;
}

/*------------------------------------*\
#Blog & Video
\*------------------------------------*/
.category {
  letter-spacing: 1px;
  margin-bottom: 1.5em;
  text-transform: uppercase;
  display: block;
}

.category-label {
  font-size: 1.2rem;
}

.date-time-wrapper {
  margin-bottom: 1em;
  font-size: 1.4rem;
}
.date-time-wrapper span {
  margin-right: 0.5em;
}
.date-time-wrapper span:last-child {
  margin-right: 0;
}

.post-item-card .post-item__category,
.post-item-card .post-item__publish-date {
  font-size: 1.3rem;
}
.post-item-card .post-item__category.label,
.post-item-card .post-item__publish-date.label {
  font-size: 1rem;
}

.post-item__title h2 {
  font-weight: 500;
  font-size: 2.4rem;
  margin-bottom: 0.5em;
}

.post-sidebar .container__categories ul.sidebar li {
  font-size: 1.4rem;
  margin-bottom: 0.5em;
}
.post-sidebar .container__categories ul.sidebar li a {
  color: #7f6789;
}
.post-sidebar .container__categories ul.sidebar li a:hover {
  color: rgb(73.025, 59.225, 78.775);
}
.post-sidebar .container__categories ul.children {
  padding-left: 1em;
}
.post-sidebar .sidebar .post-meta.sidebar {
  font-size: 1.2rem;
  font-weight: 500;
}
.post-sidebar .sidebar .post-meta.sidebar span {
  display: inline;
}

.scoca-post-header {
  background-color: #624c79;
  color: #fff;
  text-align: center;
  padding: 20px;
}

span.category {
  font-weight: bold;
}
span.category a:hover {
  color: rgb(153.8848167539, 122.0732984293, 170.9267015707);
}

/*------------------------------------*\
# Labels
\*------------------------------------*/
.label-wrapper {
  margin-bottom: 1.25em;
}

.label {
  display: inline-block;
  padding: 0.65em 1em 0.5em;
  background-color: #d6d1ca;
}
.label.meeting-agenda {
  background-color: #c6bbd0;
  color: #624c79;
}
.label.meeting-minutes {
  background-color: #624c79;
  color: #fff;
}
.label.post-item__category {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 0.5em 0.5em 0;
  line-height: 1.2em;
  display: inline-block;
}

/*------------------------------------*\
#List Styles
\*------------------------------------*/
.container > ul,
.mce-content-body > ul {
  padding-left: 1.15em;
  list-style-position: outside;
  margin: 1em 0 1em 1em;
}
.container > ul.list-unstyled,
.mce-content-body > ul.list-unstyled {
  padding: 0;
  margin: 0;
}

.container li,
.mce-content-body li {
  padding-bottom: 0.5em;
  padding-left: 0.85em;
  line-height: 1.5em;
}

.container__text ul,
.mce-content-body ul {
  list-style-position: outside;
  margin-top: 20px;
  margin-left: 15px;
  padding-left: 20px;
}

.content-section ul,
.content-section ol,
.mce-content-body ul,
.mce-content-body ol {
  padding: 1em 0 0 1.5em;
}
.content-section ul li,
.content-section ol li,
.mce-content-body ul li,
.mce-content-body ol li {
  padding-left: 1.5em;
  position: relative;
}
.content-section ul ~ :is(h1, h2, h3, h4, h5, h6),
.content-section ol ~ :is(h1, h2, h3, h4, h5, h6),
.mce-content-body ul ~ :is(h1, h2, h3, h4, h5, h6),
.mce-content-body ol ~ :is(h1, h2, h3, h4, h5, h6) {
  padding-top: 1em;
}
.content-section ul > ul,
.content-section ul > ol,
.content-section ol > ul,
.content-section ol > ol,
.mce-content-body ul > ul,
.mce-content-body ul > ol,
.mce-content-body ol > ul,
.mce-content-body ol > ol {
  padding-bottom: 0;
  padding-top: 0;
}
.content-section ul > ul:last-child,
.content-section ul > ol:last-child,
.content-section ol > ul:last-child,
.content-section ol > ol:last-child,
.mce-content-body ul > ul:last-child,
.mce-content-body ul > ol:last-child,
.mce-content-body ol > ul:last-child,
.mce-content-body ol > ol:last-child {
  padding-bottom: 1em;
}
.content-section ul ~ ul,
.content-section ul ~ ol,
.content-section ol ~ ul,
.content-section ol ~ ol,
.mce-content-body ul ~ ul,
.mce-content-body ul ~ ol,
.mce-content-body ol ~ ul,
.mce-content-body ol ~ ol {
  padding-top: 1em;
}
.content-section ul + li,
.content-section ol + li,
.mce-content-body ul + li,
.mce-content-body ol + li {
  padding-top: 1em;
}
.content-section ul.list-unstyled,
.content-section ol.list-unstyled,
.mce-content-body ul.list-unstyled,
.mce-content-body ol.list-unstyled {
  padding: 0;
}
.content-section ul.downloads-list li,
.content-section ol.downloads-list li,
.mce-content-body ul.downloads-list li,
.mce-content-body ol.downloads-list li {
  padding: 1em 0.5em !important;
  border-top: 1px solid #aca198;
}
.content-section ul + p,
.content-section ol + p,
.mce-content-body ul + p,
.mce-content-body ol + p {
  padding-top: 2em;
}
.content-section ul.list-icon li,
.content-section ol.list-icon li,
.mce-content-body ul.list-icon li,
.mce-content-body ol.list-icon li {
  padding: 1em 1em 1em 2.5em;
  display: block;
}
.content-section ul.list-icon li:before,
.content-section ol.list-icon li:before,
.mce-content-body ul.list-icon li:before,
.mce-content-body ol.list-icon li:before {
  margin-left: 1em;
}
.content-section ul.list-pdf li:before,
.content-section ol.list-pdf li:before,
.mce-content-body ul.list-pdf li:before,
.mce-content-body ol.list-pdf li:before {
  content: "\f1c1";
}
.content-section ul.list-striped li:nth-child(odd),
.content-section ol.list-striped li:nth-child(odd),
.mce-content-body ul.list-striped li:nth-child(odd),
.mce-content-body ol.list-striped li:nth-child(odd) {
  background-color: rgb(235.93125, 231.88125, 237.61875);
}
.content-section ul.list-striped li:nth-child(even),
.content-section ol.list-striped li:nth-child(even),
.mce-content-body ul.list-striped li:nth-child(even),
.mce-content-body ol.list-striped li:nth-child(even) {
  background-color: rgb(223.925, 217.325, 226.675);
}
.content-section ol > li,
.mce-content-body ol > li {
  list-style: decimal;
  margin-left: 1.25em;
  padding-left: 0.2em;
}
.content-section p + ul, .content-section p + ol,
.mce-content-body p + ul,
.mce-content-body p + ol {
  padding-top: 0;
}

ul.cafe-hours-list {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}
ul.cafe-hours-list .timespan {
  margin-right: 1em;
  display: inline-block;
  min-width: 110px;
}

/*------------------------------------*\
# Career Posts
\*------------------------------------*/
.position-type {
  margin-top: 0.5em;
}

.position-dept {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.5em;
}

.section__careers-list .grid-card {
  color: #2d2d2d;
}

/*------------------------------------*\
# FAQ Accordion
\*------------------------------------*/
.faq-accordion {
  width: 100%;
  background-color: #f9f9f9;
  margin: 0.5em auto 2em;
}

.faq-item .faq-item-question {
  background-color: #eaeaea;
  color: #2d2d2d;
  padding: 0.75em 1em;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid #fff;
}
.faq-item .faq-item-question:first-child {
  border-bottom: 2px solid #fff;
}
.faq-item .faq-item-question h3 {
  font-size: 2rem;
  margin-bottom: 0;
}
.faq-item .faq-item-question.active + .faq-item-answer {
  height: auto;
  max-height: 100%;
}
.faq-item .faq-item-answer {
  padding: 0;
  width: 100%;
  height: auto;
  max-height: 0px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}
.faq-item .faq-item-answer .faq-text {
  padding: 0.75em 1em;
}

/*------------------------------------*\
#Team
\*------------------------------------*/
.team-post-item__content-text h2 {
  font-size: 2.85rem;
  margin-bottom: 0;
}
.team-post-item__content-text .team-post-item__title {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1em;
  letter-spacing: 1px;
  line-height: 1.2em;
}
.team-post-item__content-text .team-post-item__quote p {
  font-size: 1.6rem;
  line-height: 1.5em;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Reset
\*------------------------------------*/
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*------------------------------------*\
#Global
\*------------------------------------*/
.site.customize-support #main-menu {
  top: 46px;
}
@media (min-width: 768px) {
  .site.customize-support #main-menu {
    top: 0;
  }
}
.site.customize-support .main-nav {
  transition: all 0.2s ease;
}
.site.customize-support .main-nav.scrolled {
  margin-top: 46px;
}
@media (min-width: 783px) {
  .site.customize-support .main-nav.scrolled {
    margin-top: 32px;
  }
}

header {
  position: relative;
  display: block;
}

.container,
.container-fluid,
.container-grid {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container,
  .container-fluid,
  .container-grid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container {
  margin: 0 auto;
  transition: all 0.3s ease;
  max-width: 972px;
}
@media (min-width: 480px) {
  .container {
    max-width: 460px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 556px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 972px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
  .container.container-narrow {
    width: 900px;
  }
}
@media (min-width: 2000px) {
  .container {
    max-width: 1600px;
  }
}
.container.container-cta {
  max-width: 100%;
  width: 100%;
}

.container-fullpage {
  width: 100vw;
  height: 80vh;
}

.container-grid {
  transition: all 0.3s ease;
}
@media (min-width: 768px) {
  .container-grid {
    max-width: 1170px;
  }
}
@media (min-width: 992px) {
  .container-grid {
    max-width: 1600px;
  }
}

.display-block {
  display: block;
}

.fill-height {
  height: 100%;
}

footer .container ul {
  margin: 0;
  padding: 0;
}

.footer__main .container {
  padding: 3em 15px;
}
@media (min-width: 992px) {
  .footer__main .container {
    padding: 2em;
  }
}

.footer__secondary .container {
  padding: 3vw 15px;
}
@media (min-width: 768px) {
  .footer__secondary .container {
    padding: 1vw 15px;
  }
}
@media (min-width: 992px) {
  .footer__secondary .container {
    padding: 1vw 30px;
  }
}

.is-flex {
  display: flex;
}
.is-flex.flex-center-both {
  align-items: center;
  justify-content: center;
}
.is-flex.flex-column {
  flex-direction: column;
}
.is-flex.flex-column.centered {
  justify-content: center;
}
.is-flex.flex-row {
  flex-direction: row;
}
.is-flex.flex-row.centered {
  align-items: center;
}
.is-flex.justify-content-start {
  justify-content: flex-start;
}
.is-flex.justify-content-end {
  justify-content: flex-end;
}
.is-flex.justify-content-center {
  justify-content: center;
}
.is-flex.justify-content-between {
  justify-content: space-between;
}
.is-flex.align-items-start {
  align-items: flex-start;
}
.is-flex.align-items-end {
  align-items: flex-end;
}
.is-flex.align-items-center {
  align-items: center;
}
.is-flex.align-content-start {
  align-content: flex-start;
}
.is-flex.align-content-end {
  align-content: flex-end;
}
.is-flex.align-content-center {
  align-content: center;
}
.is-flex.flex-wrap {
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .overlap-left {
    transform: translateX(-8em);
  }
}

@media (min-width: 992px) {
  .overlap-right {
    transform: translateX(-6em);
  }
}

section {
  padding: 4em 15px;
}
@media (min-width: 768px) {
  section {
    padding-left: 0;
    padding-right: 0;
  }
}
section.full-section {
  padding: 0;
}

.wrapper {
  width: 100%;
  margin: 0 auto 3em;
}

.wrapper-padded {
  padding: 0 15px;
}

/*------------------------------------*\
#Structure
\*------------------------------------*/
a.container__link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.blog-post .container__single-post-image {
  width: 100%;
  position: relative;
  height: 260px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .blog-post .container__single-post-image {
    width: calc(50% - 2em);
    margin: 0 2em 0 0;
    height: 480px;
  }
}
@supports (display: grid) {
  .blog-post .container__single-post-image {
    width: 100%;
  }
}
.blog-post .container__single-post-title-text {
  width: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
  .blog-post .container__single-post-title-text {
    width: 50%;
    padding: 0 10% 2em 0;
    justify-content: flex-end;
    height: 100%;
  }
}
@supports (display: grid) {
  .blog-post .container__single-post-title-text {
    width: 100%;
  }
}
.blog-post .section__post-title-block {
  margin: 0 2em 2em;
  column-gap: 2em;
}
@media (min-width: 768px) {
  .blog-post .section__post-title-block {
    margin: 0 0 2em;
    background-color: rgb(225.1223404255, 221.4787234043, 216.3776595745);
  }
}
@supports (display: grid) {
  .blog-post .section__post-title-block {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) {
    .blog-post .section__post-title-block {
      margin-top: 0;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }
}
.blog-post .single-post {
  width: 100%;
}
@media (min-width: 768px) {
  .blog-post .single-post {
    width: calc(70% - 2em);
    margin-right: 2em;
  }
}
@supports (display: grid) {
  .blog-post .single-post {
    width: 100%;
  }
}
.blog-post .single-post__sidebar {
  width: 100%;
}
@media (min-width: 768px) {
  .blog-post .single-post__sidebar {
    width: 30%;
  }
}
.blog-post .single-post__sidebar a {
  color: rgb(108.1116071429, 97.4776785714, 90.3883928571);
}
.blog-post .single-post__sidebar a:hover {
  color: rgb(108.1116071429, 97.4776785714, 90.3883928571);
}
.blog-post .single-post__sidebar .post-sidebar__item {
  background-color: rgb(225.1223404255, 221.4787234043, 216.3776595745);
  padding: 1em;
}
.blog-post .single-post__sidebar .post-sidebar__item:hover {
  background-color: #aca198;
}
@supports (display: grid) {
  .blog-post .single-post__sidebar {
    width: 100%;
  }
}

.post-item-card {
  background-color: #fff;
  grid-template-rows: min-content 1fr;
  position: relative;
}

.post-item__content {
  width: 100%;
  padding: 2em;
}

.post-item__excerpt {
  margin-bottom: 2em;
}

.post-item__image {
  overflow: hidden;
  height: 260px;
  position: relative;
}
.post-item__image img {
  object-fit: contain;
  top: 50%;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .post-item__image img {
    height: 100%;
    width: unset;
  }
}
.post-item__image.video {
  height: 213px;
}

.ca-event-item {
  background-color: #664a75;
  height: 100%;
}
.ca-event-item .ca-event-date {
  padding: 1.5em;
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  height: 100%;
}
.ca-event-item .ca-event-details {
  padding: 1em;
  width: 100%;
}
.ca-event-item .ca-event-details .event-location {
  padding: 0.5em 1em 0.5em 0;
}
.ca-event-item .event-info i {
  margin-right: 0.2em;
}

.cafe-hours.mobile {
  display: block;
}
@media (min-width: 992px) {
  .cafe-hours.mobile {
    display: none;
  }
}

.career-post-content div {
  margin-bottom: 2em;
}
.career-post-content div:last-child {
  margin-bottom: 0;
}

.career-posting-list__details {
  margin-bottom: 2em;
}

.career-posting-meta {
  margin-bottom: 1em;
  border-bottom: 1px dotted #eaeaea;
  padding-bottom: 1em;
}

.container__header {
  min-height: 20em;
  margin-top: 100px;
}
@supports (display: grid) {
  .container__header {
    margin-top: unset;
  }
}
@media (min-width: 992px) {
  .container__header {
    min-height: 25em;
  }
}

.container__home-page-features {
  max-width: 1600px;
}

.container__post {
  margin-bottom: 2em;
}
.container__post:last-of-type {
  margin-bottom: 0;
}

.container__program-post-item {
  padding: 2em 1em;
}
@media (min-width: 768px) {
  .container__program-post-item {
    padding: 2em;
  }
}
@media (min-width: 992px) {
  .container__program-post-item {
    padding: 3em;
  }
}

.container__sport-single {
  padding: 2em;
  border: 2px solid #fff;
}

.contract-options-item {
  padding: 2em;
  background-color: rgba(102, 74, 117, 0.3);
}

.display_archive {
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .display_archive {
    max-width: 800px;
  }
}
@media (min-width: 992px) {
  .display_archive {
    max-width: 960px;
  }
}

.download-item {
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 768px) {
  .download-item {
    align-items: center;
    flex-direction: row;
  }
}
.download-item .download-item-meta {
  margin-top: 0.5em;
}
@media (min-width: 768px) {
  .download-item .download-item-meta {
    margin-top: unset;
  }
}

.grid-card {
  padding: 2em;
  background-color: #fff;
}
.grid-card.lt-grey {
  background-color: #f9f9f9;
}

.ltad-item-card {
  padding: 1.5em;
}

.main-content {
  padding-top: 100px;
  width: 100vw;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding {
  padding: 0;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.payment-methods {
  padding-top: 2em;
}

.section__block-beliefs {
  padding: 4em 0;
}

.section__block-ca-way {
  padding: 4em 0;
}
.section__block-ca-way .container {
  padding: 3em 15px;
  max-width: 320px;
}
@media (min-width: 768px) {
  .section__block-ca-way .container {
    max-width: 800px;
    padding: 3em;
  }
}

.section__block-cta {
  min-height: 15em;
  padding: 3em 15px;
}
@media (min-width: 768px) {
  .section__block-cta {
    padding: 4em;
    min-height: 20em;
  }
}
@media (min-width: 1200px) {
  .section__block-cta {
    min-height: 25em;
  }
}

.section__block-careers .grid__3col {
  grid-template-columns: 1fr;
  grid-row-gap: 1.2em;
}
@media (min-width: 992px) {
  .section__block-careers .grid__3col {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0;
  }
}

.section__fullpage {
  height: 70vh;
}

.section__home-intro {
  padding: 2em 0;
  margin-top: 100px;
}
@supports (display: grid) {
  .section__home-intro {
    margin-top: unset;
  }
}
@media (min-width: 576px) {
  .section__home-intro {
    padding: 4em;
  }
}
@media (min-width: 768px) {
  .section__home-intro {
    padding: 4em 0 8em;
  }
}
@media (min-width: 992px) {
  .section__home-intro {
    padding: 6em 0 12em;
  }
}

.section__block-contact .section__text + .section__text {
  margin-top: 0;
}
.section__block-contact .section__block-content-fee {
  padding: 3em;
}
@media (min-width: 992px) {
  .section__block-contact .section__block-content-fee {
    padding: 2em 4em;
  }
}

@media (min-width: 1100px) {
  .section__block-home-page-features {
    margin-top: -10em;
  }
}
.section__block-home-page-features .home-page-feature {
  flex-direction: column;
  padding: 2em 3em;
  grid-gap: 1.5em;
  grid-template-rows: auto 3fr;
}
@supports (display: grid) {
  .section__block-home-page-features .home-page-feature {
    flex-direction: unset;
  }
}
.section__block-home-page-features .home-page-feature .container__home-page-feature-content p {
  margin-bottom: 1.2em;
}

.section__no-posts {
  padding: 3em;
  margin-top: 1.5em;
}
.section__no-posts.border-top {
  border-top: 1px solid;
}

.section__text {
  width: 100%;
}
.section__text + .section__no-posts {
  padding: 0;
}

.section__block-splash {
  min-height: auto;
}
.section__block-splash .container-fluid {
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
}
@media (min-width: 768px) {
  .section__block-splash .container-fluid {
    flex-direction: row;
  }
}
.section__block-splash .container-fluid .section__text {
  text-align: center;
}
@media (min-width: 768px) {
  .section__block-splash .container-fluid .section__text {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .section__block-splash {
    min-height: 30em;
  }
}

.single-post__column {
  max-width: 300px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .single-post__column {
    max-width: 365px;
  }
}

.single-post-content {
  max-width: 960px;
  margin: 0 auto;
}

.ss-item-card {
  background-color: #fff;
}

.fee-item-fee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  border-top: 1px dotted #2d2d2d;
}
.fee-item-fee:last-child {
  border-bottom: 1px dotted #2d2d2d;
}
@media (min-width: 768px) {
  .fee-item-fee {
    padding: 0;
    justify-content: center;
    border: none;
  }
  .fee-item-fee:last-child {
    border: none;
  }
}

.fee-item {
  padding: 1.2em 1em 1em;
}
.fee-item:nth-child(odd) {
  background-color: rgba(102, 74, 117, 0.1);
}
.fee-item:nth-child(even) {
  background-color: rgba(102, 74, 117, 0.25);
}

.fee-section {
  border-bottom: 4px solid #2d2d2d;
}
.fee-section:last-child {
  border: none;
}

.fee-table-header {
  padding: 1em;
}

/*------------------------------------*\
#Content Blocks
\*------------------------------------*/
.application-items .icon-block-item {
  flex-direction: column;
  margin-bottom: 1em;
  padding: 2em;
}
.application-items .icon-block-item .icon_block__content {
  text-align: center;
}
@media (min-width: 768px) {
  .application-items .icon-block-item .icon_block__content {
    text-align: left;
  }
}
.application-items .icon-block-item .icon_block__icon {
  height: 60px;
}
@media (min-width: 768px) {
  .application-items .icon-block-item {
    flex-direction: unset;
    padding: 1em 2em;
  }
  .application-items .icon-block-item .icon-block__icon {
    width: 10%;
    margin: 0 0 1em 0;
  }
  .application-items .icon-block-item .icon-block__meta {
    width: 90%;
    align-self: center;
  }
}

.application-item {
  padding: 20px;
  border: 1px solid #d6d1ca;
  border-radius: 10px;
}

.ca-event-block.grid__1col {
  max-width: 576px;
}

.content-section ~ .content-section {
  margin-top: 1em;
}
.content-section .content-section-thumb {
  height: 300px;
  overflow: hidden;
  position: relative;
}
.content-section .content-section-thumb img {
  object-fit: contain;
  top: 50%;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
}
.content-section.paint-splash {
  flex-direction: column;
}
@media (min-width: 768px) {
  .content-section.paint-splash {
    flex-direction: row;
  }
}

.icon-block__meta,
.pages-content__meta {
  height: 100%;
}

.icon-block-item,
.pages-content-item {
  padding: 2em 15px;
}
@media (min-width: 768px) {
  .icon-block-item,
  .pages-content-item {
    padding: 2em 0;
  }
}

@media (min-width: 768px) {
  .icon-block-item {
    padding: 2em;
  }
}

.pages-content-item.image-thumb {
  display: flex;
  align-items: center;
  padding: 0 0 2em;
  flex-direction: column;
}
@media (min-width: 768px) {
  .pages-content-item.image-thumb {
    flex-direction: row;
  }
  .pages-content-item.image-thumb.push-right {
    flex-direction: row-reverse;
  }
}
.pages-content-item.image-thumb.grid {
  flex-direction: column;
  display: grid;
}
.pages-content-item .pages-content__meta {
  padding: 2em;
  width: 100%;
}
.pages-content-item .pages-content__meta.grid {
  padding: 0;
}

.section__content-block > div {
  margin-bottom: 1em;
}

.text-large {
  width: 100%;
}

/*------------------------------------*\
#Grids
\*------------------------------------*/
.grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: start;
  grid-gap: 1em;
}
@supports (display: grid) {
  .grid {
    display: grid;
    display: -ms-grid;
    min-height: 0;
    min-width: 0;
    transition: all 0.2s ease;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .grid {
      grid-row-gap: 2em;
    }
  }
  @media (min-width: 992px) {
    .grid {
      grid-template-columns: auto;
    }
  }
  .grid.grid-full {
    max-width: 100%;
  }
  .grid.grid_no-gap {
    grid-gap: 0;
  }
}
.grid.grid-row {
  grid-auto-rows: 1fr;
}
.grid .grid-item.pages-content-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.grid .grid-item.pages-content-item.default {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.grid .grid-item.pages-content-item.padded-box {
  padding: 1.5em;
}
@media (min-width: 768px) {
  .grid .grid-item.pages-content-item.padded-box {
    padding: 2em;
  }
}
.grid .grid-item.pages-content-item.image-card, .grid .grid-item.pages-content-item.outline {
  padding: 0;
  flex-direction: column;
}
.grid .grid-item.pages-content-item.column-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  grid-row-gap: 1em;
}
.grid .grid-item.pages-content-item.column-item .pages-content__item-card {
  flex-grow: 1;
}
.grid .grid-item.pages-content-item.column-item .pages-content__item-card.img-round {
  flex-grow: unset;
}
.grid .grid-item.pages-content-item.column-item.image-card {
  grid-row-gap: 0;
}
.grid .grid-item.pages-content-item.row-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  text-align: center;
  justify-items: center;
  padding: 0;
}
.grid .grid-item.pages-content-item.row-item.padded-box {
  padding: 2em;
}
@media (min-width: 768px) {
  .grid .grid-item.pages-content-item.row-item {
    grid-template-columns: 1fr 3fr;
    grid-auto-flow: column;
    grid-column-gap: 2em;
    text-align: left;
    justify-items: start;
    align-items: stretch;
  }
}
.grid .grid-item.pages-content-item.row-item > * {
  width: 100%;
  min-height: 100%;
}
.grid .grid-item.pages-content-item.row-item .pages-content__item-card {
  flex-grow: 1;
  height: auto;
  max-height: 180px;
}
.grid .grid-item.pages-content-item.row-item .pages-content__item-card.img-round {
  width: 100%;
  max-width: 180px;
  height: auto;
  aspect-ratio: 1/1;
  flex-shrink: 0;
}
.grid .grid-item.pages-content-item.row-item .pages-content__meta {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.grid .grid.grid-column,
.grid .grid.grid-boxed-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: stretch;
}
@supports (display: grid) {
  .grid.blog-post-content {
    grid-template-areas: "single-post-content" "single-post-sidebar";
    grid-gap: 2em;
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) {
    .grid.blog-post-content {
      grid-template-areas: "single-post-content single-post-sidebar";
      grid-template-columns: minmax(600px, 5fr) minmax(300px, 2fr);
    }
  }
}
.grid.content-text-image {
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .grid.content-text-image {
    flex-direction: row;
  }
}
@supports (display: grid) {
  .grid.content-text-image {
    grid-template-areas: "content-image" "content-text";
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
  }
  .grid.content-text-image.center {
    grid-template-areas: "content-text" "content-image";
  }
}
.grid.content-text-image .content-section-text {
  width: 100%;
}
@media (min-width: 768px) {
  .grid.content-text-image .content-section-text {
    width: 60%;
  }
}
@supports (display: grid) {
  .grid.content-text-image .content-section-text {
    width: auto;
  }
}
.grid.content-text-image .content-section-thumb {
  width: 100%;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.content-text-image .content-section-thumb {
    width: calc(40% - 2em);
    margin-bottom: 0;
  }
}
@supports (display: grid) {
  .grid.content-text-image .content-section-thumb {
    width: auto;
  }
}
@media (min-width: 768px) {
  .grid.content-text-image.right .content-section-thumb {
    margin-left: 2em;
  }
  @supports (display: grid) {
    .grid.content-text-image.right .content-section-thumb {
      margin-left: 0;
    }
  }
}
@supports (display: grid) {
  @media (min-width: 768px) {
    .grid.content-text-image.right {
      grid-template-areas: "content-text content-image";
      grid-template-columns: 3fr 460px;
    }
  }
}
.grid.content-text-image.left {
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .grid.content-text-image.left {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .grid.content-text-image.left .content-section-thumb {
    margin-right: 2em;
  }
  @supports (display: grid) {
    .grid.content-text-image.left .content-section-thumb {
      margin-right: 0;
    }
  }
}
@supports (display: grid) {
  @media (min-width: 768px) {
    .grid.content-text-image.left {
      grid-template-areas: "content-image content-text";
      grid-template-columns: 460px 3fr;
    }
  }
}
.grid.content-text-image.paint-splash img {
  width: 60%;
}
@supports (display: grid) {
  .grid.content-text-image.paint-splash img {
    width: unset;
  }
}
.grid.content-text-image.paint-splash .content-section-text {
  width: 40%;
}
@supports (display: grid) {
  .grid.content-text-image.paint-splash .content-section-text {
    width: unset;
  }
}
.grid.grid__2col .grid-item {
  width: 100%;
  flex: 1 1 372px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.grid__2col .grid-item {
    max-width: calc(50% - 1em);
    width: calc(50% - 1em);
    margin-right: 1em;
  }
  @supports (display: grid) {
    .grid.grid__2col .grid-item {
      max-width: unset;
      width: unset;
      margin-right: unset;
      row-gap: 1em;
    }
  }
  .grid.grid__2col .grid-item:nth-of-type(2n) {
    margin-right: 0;
  }
}
.grid.grid__2col .grid-item.mb-0 {
  margin-bottom: 0;
}
@supports (display: grid) {
  .grid.grid__2col .grid-item {
    margin-bottom: 0;
  }
}
.grid.grid__3col .grid-item {
  width: 100%;
  flex: 1 1 372px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.grid__3col .grid-item {
    max-width: calc(33.33% - 1em);
    width: calc(33.33% - 1em);
    margin-right: 1em;
  }
  @supports (display: grid) {
    .grid.grid__3col .grid-item {
      max-width: unset;
      width: unset;
      margin-right: unset;
      row-gap: 1em;
    }
  }
  .grid.grid__3col .grid-item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.grid.grid__3col .grid-item.mb-0 {
  margin-bottom: 0;
}
@supports (display: grid) {
  .grid.grid__3col .grid-item {
    margin-bottom: 0;
  }
}
.grid.grid__4col .grid-item {
  width: 100%;
  flex: 1 1 372px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.grid__4col .grid-item {
    max-width: calc(25% - 1em);
    width: calc(25% - 1em);
    margin-right: 1em;
  }
  @supports (display: grid) {
    .grid.grid__4col .grid-item {
      max-width: unset;
      width: unset;
      margin-right: unset;
      row-gap: 1em;
    }
  }
  .grid.grid__4col .grid-item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.grid.grid__4col .grid-item.mb-0 {
  margin-bottom: 0;
}
@supports (display: grid) {
  .grid.grid__4col .grid-item {
    margin-bottom: 0;
  }
}
.grid.grid__5col .grid-item {
  width: 100%;
  flex: 1 1 372px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.grid__5col .grid-item {
    max-width: calc(20% - 1em);
    width: calc(20% - 1em);
    margin-right: 1em;
  }
  @supports (display: grid) {
    .grid.grid__5col .grid-item {
      max-width: unset;
      width: unset;
      margin-right: unset;
      row-gap: 1em;
    }
  }
  .grid.grid__5col .grid-item:nth-of-type(5n) {
    margin-right: 0;
  }
}
.grid.grid__5col .grid-item.mb-0 {
  margin-bottom: 0;
}
@supports (display: grid) {
  .grid.grid__5col .grid-item {
    margin-bottom: 0;
  }
}
.grid.grid__6col .grid-item {
  width: 100%;
  flex: 1 1 372px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .grid.grid__6col .grid-item {
    max-width: calc(16.66% - 1em);
    width: calc(16.66% - 1em);
    margin-right: 1em;
  }
  @supports (display: grid) {
    .grid.grid__6col .grid-item {
      max-width: unset;
      width: unset;
      margin-right: unset;
      row-gap: 1em;
    }
  }
  .grid.grid__6col .grid-item:nth-of-type(6n) {
    margin-right: 0;
  }
}
.grid.grid__6col .grid-item.mb-0 {
  margin-bottom: 0;
}
@supports (display: grid) {
  .grid.grid__6col .grid-item {
    margin-bottom: 0;
  }
}
.grid.grid__2col {
  justify-content: space-between;
}
.grid.grid__3col {
  justify-content: space-between;
}
.grid.grid__4col {
  justify-content: space-between;
}
.grid.grid__5col {
  justify-content: space-between;
}
.grid.grid__6col {
  justify-content: space-between;
}
.grid.grid-full {
  width: 100%;
}
.grid.grid-full.grid__2col .grid-item {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .grid.grid-full.grid__2col .grid-item {
    width: 50%;
    margin-right: 0;
  }
  @supports (display: grid) {
    .grid.grid-full.grid__2col .grid-item {
      width: unset;
    }
  }
}
.grid.grid-full.grid__3col .grid-item {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .grid.grid-full.grid__3col .grid-item {
    width: 33.33%;
    margin-right: 0;
  }
  @supports (display: grid) {
    .grid.grid-full.grid__3col .grid-item {
      width: unset;
    }
  }
}
.grid.grid-full.grid__4col .grid-item {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .grid.grid-full.grid__4col .grid-item {
    width: 25%;
    margin-right: 0;
  }
  @supports (display: grid) {
    .grid.grid-full.grid__4col .grid-item {
      width: unset;
    }
  }
}
.grid.grid-full.grid__5col .grid-item {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .grid.grid-full.grid__5col .grid-item {
    width: 20%;
    margin-right: 0;
  }
  @supports (display: grid) {
    .grid.grid-full.grid__5col .grid-item {
      width: unset;
    }
  }
}
.grid.grid-full.grid__6col .grid-item {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .grid.grid-full.grid__6col .grid-item {
    width: 16.66%;
    margin-right: 0;
  }
  @supports (display: grid) {
    .grid.grid-full.grid__6col .grid-item {
      width: unset;
    }
  }
}
.grid.rows {
  flex-direction: column;
}
@supports (display: grid) {
  .grid.rows {
    flex-direction: unset;
  }
}
.grid.grid-icon-text-column {
  grid-gap: 1.5em;
  /* grid-template-rows: minmax(80px, 120px) auto; */
  grid-template-rows: minmax(120px, 220px) auto;
}
.grid.post-item-card {
  flex-direction: column;
}
@supports (display: grid) {
  .grid.post-item-card {
    grid-template-areas: "blog-image" "blog-content";
    grid-template-columns: 1fr;
    margin: 0;
  }
}
.grid.post-item__content {
  grid-template-areas: "blog-category" "blog-title" "blog-date" "blog-excerpt" "read-more";
}
.grid.cafe-hours {
  display: none;
}
@media (min-width: 992px) {
  .grid.cafe-hours {
    display: grid;
    grid-template-columns: 1fr repeat(4, 2fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: " . all-grades high-school junior-high elementary" "mon-thurs mon-thurs-all-grades mon-thurs-high-school mon-thurs-junior-high mon-thurs-elementary" "friday friday-all-grades friday-high-school friday-junior-high friday-elementary";
  }
}
.grid.campaign {
  grid-template-columns: 1fr;
  text-align: center;
  padding: 1em;
  grid-gap: 0.5em;
}
@media (min-width: 768px) {
  .grid.campaign {
    grid-template-columns: 2fr 7fr;
    text-align: left;
    grid-gap: 1em;
  }
}
@media (min-width: 992px) {
  .grid.campaign {
    grid-template-columns: 1fr 7fr;
  }
}
.grid.campaign a {
  font-weight: 600;
}
.grid.campaign a:hover {
  color: #5e2154;
}
.grid.campaign:nth-child(odd) {
  background-color: rgb(247.9375, 246.4375, 248.5625);
}
.grid.campaign:nth-child(even) {
  background-color: rgb(223.925, 217.325, 226.675);
}
.grid.learning-model-item {
  grid-template-columns: 1fr;
  grid-template-areas: "lm_graphic" "lm_text";
  padding: 2em 0;
}
@media (min-width: 768px) {
  .grid.learning-model-item {
    grid-template-columns: 3fr 5fr;
    grid-template-areas: "lm_graphic lm_text";
  }
}
.grid.learning-model-item.pull-right {
  float: none;
}
@media (min-width: 992px) {
  .grid.learning-model-item.pull-right {
    grid-template-columns: 5fr 3fr;
    grid-template-areas: "lm_text lm_graphic";
  }
}
.grid.resource-item {
  grid-template-rows: 100px auto;
}
.grid.tuition-fee-table-header {
  display: none;
}
@media (min-width: 768px) {
  .grid.tuition-fee-table-header {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    grid-template-areas: "fee-year academy-header collegiate-header kindergarten-header";
  }
}
.grid.container__application-steps {
  grid-template-columns: auto;
  text-align: center;
  margin-bottom: 4em;
}
.grid.container__application-steps:last-of-type {
  margin: 0;
}
@media (min-width: 768px) {
  .grid.container__application-steps {
    grid-template-columns: auto 1fr;
    text-align: left;
  }
}
.grid.container__fee-posts {
  grid-gap: 1em;
  grid-template-areas: "application_fee" "initiation_fee" "academy_tuition" "collegiate_tuition" "other_fees" "supplies_fee" "bussing_1" "bussing_2";
  max-width: 1600px;
}
@media (min-width: 576px) {
  .grid.container__fee-posts {
    grid-template-areas: "application_fee application_fee" "initiation_fee initiation_fee" "academy_tuition collegiate_tuition" "other_fees supplies_fee" "bussing_1 bussing_2";
  }
}
@media (min-width: 992px) {
  .grid.container__fee-posts {
    margin: 2em auto;
    grid-template-areas: "application_fee application_fee application_fee initiation_fee initiation_fee initiation_fee" "academy_tuition academy_tuition collegiate_tuition collegiate_tuition other_fees other_fees" "supplies_fee supplies_fee bussing_1 bussing_1 bussing_2 bussing_2";
  }
}
.grid.contract-options {
  grid-template-areas: "option-1" "option-2" "option-3";
}
@media (min-width: 768px) {
  .grid.contract-options {
    grid-template-areas: "option-1 option-2 option-3";
  }
}
.grid.grid__gap-narrow {
  grid-gap: 1.5em;
}
.grid.integrated-studies-post-item {
  grid-template-areas: "is-icon is-title";
  grid-template-columns: minmax(80px, 120px) minmax(100px, 250px);
  grid-gap: 0.5em;
}
@media (min-width: 768px) {
  .grid.integrated-studies-post-item {
    grid-gap: 1em;
  }
}
.grid.pillar_item .pillar_item-icon {
  width: 20%;
  margin-right: 5%;
}
@supports (display: grid) {
  .grid.pillar_item .pillar_item-icon {
    width: 100%;
  }
}
.grid.pillar_item .pillar_item-text {
  width: 75%;
}
@supports (display: grid) {
  .grid.pillar_item .pillar_item-text {
    width: 100%;
  }
}
@supports (display: grid) {
  .grid.pillar_item {
    grid-template-areas: "pillar-icon" "pillar-text";
    grid-template-rows: auto 5fr;
  }
  @media (min-width: 768px) {
    .grid.pillar_item {
      grid-template-areas: "pillar-icon pillar-text";
      grid-template-columns: 2fr 10fr;
      grid-template-rows: 1fr;
    }
  }
}
.grid.reach_item {
  grid-template-areas: "reach-image reach-text";
  grid-template-columns: 2fr 5fr;
  grid-template-rows: auto;
}
@media (min-width: 1380px) {
  .grid.reach_item {
    grid-template-areas: "reach-image" "reach-text";
    grid-template-rows: max-content auto 1fr;
    grid-template-columns: 1fr;
  }
}
.grid.team-members {
  grid-template-columns: 1fr;
  justify-content: center;
}
@media (min-width: 768px) {
  .grid.team-members {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1060px) {
  .grid.team-members {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid.team-members.grid__2col {
    grid-template-columns: repeat(2, 372px);
  }
}
@supports (display: grid) {
  .grid.team-post-item__team-member {
    grid-template-areas: "team-thumb" "team-content";
    grid-template-rows: max-content 2fr;
  }
}
@supports (display: grid) {
  .grid.testimonial-post-quote {
    grid-template-areas: "quote" "testimonial-meta";
  }
}
.grid.testimonial-post-quote .testimonial-post-meta {
  display: flex;
  flex-direction: column;
}
@supports (display: grid) {
  .grid.testimonial-post-quote .testimonial-post-meta {
    display: grid;
    flex-direction: unset;
    grid-template-areas: "author" "author-info";
  }
}
.grid .fee-column {
  width: 100%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .grid .fee-column {
    flex-direction: row;
  }
}
.grid .fee-column .fee-cell {
  flex: 1 1 0px;
}
.grid.fee-item {
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .grid.fee-item {
    grid-template-columns: 4fr 5fr;
    min-height: 6em;
    align-items: center;
  }
}
.grid.fee-table-header {
  display: none;
}
@media (min-width: 768px) {
  .grid.fee-table-header {
    display: grid;
    grid-template-columns: 4fr 5fr;
  }
}
@supports (display: grid) {
  .grid.video-block {
    grid-template-areas: "video" "video-text";
  }
  @media (min-width: 768px) {
    .grid.video-block {
      grid-template-areas: "video video-text";
      grid-template-rows: 1fr;
    }
  }
}
.grid.video-block.align-top {
  align-items: start;
}
.grid.video-block.align-center {
  align-items: center;
}
.grid.video-block.align-bottom {
  align-items: end;
}
.grid.video-block .grid-item {
  min-height: 360px;
}
.grid.video-block .grid-item.video-block-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.grid.video-block .grid-item.video-block-video .mejs-controls {
  display: none !important;
  visibility: hidden !important;
}
.grid.video-block .grid-item.video-block-video .mejs-overlay-play {
  background-color: rgba(0, 0, 0, 0.35);
}
.grid.video-block .grid-item.video-block-video .mejs-overlay-play:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.grid.video-block .grid-item.video-block-text {
  justify-content: center;
  align-items: center;
  padding: 2em;
}
@media (min-width: 768px) {
  .grid.video-block .grid-item.video-block-text {
    align-items: unset;
  }
}
.grid.video-block .grid-item.video-block-text .sub-title,
.grid.video-block .grid-item.video-block-text .video-block-description {
  width: 100%;
}
.grid.video-block .grid-item.video-block-text.text-block {
  padding: 2em;
}
.grid.video-block .grid-item.video-block-text.full-block {
  padding: 1em;
}
@media (min-width: 768px) {
  .grid.video-block .grid-item.video-block-text.full-block {
    padding: 2em;
  }
}

.grid__auto-col {
  grid-template-columns: 1fr;
  grid-gap: 1em;
  grid-auto-rows: 1fr;
}
@media (min-width: 768px) {
  .grid__auto-col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .grid__auto-col {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
.grid__auto-col.container__ca-event-list {
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 320px));
}

.grid__2col,
.grid__3col,
.grid__4col,
.grid__5col,
.grid__6col {
  grid-template-columns: minmax(280px, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 1em;
}
@media (min-width: 768px) {
  .grid__2col,
  .grid__3col,
  .grid__4col,
  .grid__5col,
  .grid__6col {
    grid-gap: 2em;
  }
}

@media (min-width: 768px) {
  .grid__2col {
    grid-template-columns: repeat(2, 1fr);
  }
}
.grid__2col.card-list {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr;
}
@media (min-width: 768px) {
  .grid__2col.card-list {
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
  }
}

.grid__3col.container__posts {
  grid-template-columns: 1fr;
  width: 100%;
}
@media (min-width: 768px) {
  .grid__3col.container__posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .grid__3col.container__posts {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) {
  .grid__3col.container__team-posts {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5em;
    grid-auto-rows: auto;
  }
}
@media (min-width: 768px) {
  .grid__3col {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid__3col.container__testimonial-posts {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 992px) {
  .grid__3col.container__testimonial-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1060px) {
  .grid__3col.container__team-posts {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8em;
  }
}

@media (min-width: 768px) {
  .grid__4col {
    grid-row-gap: 3em;
    grid-template-columns: repeat(4, 1fr);
  }
}
.grid__4col.container__strat-goals {
  grid-row-gap: 8em;
}
@media (min-width: 768px) {
  .grid__4col.container__strat-goals {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .grid__4col.container__strat-goals {
    grid-template-columns: repeat(4, 1fr);
  }
}
.grid__4col.container__menu-a-la-carte {
  grid-row-gap: 4em;
  grid-template-rows: auto;
}
@media (min-width: 768px) {
  .grid__4col.container__menu-a-la-carte {
    grid-column-gap: 4em;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .grid__5col {
    grid-template-columns: repeat(5, 1fr);
  }
}
@supports (display: grid) {
  .grid__5col.container__reach {
    grid-template-areas: "respect" "enthusiasm" "altruism" "commitment" "honesty";
    grid-template-columns: 1fr;
  }
  @media (min-width: 1380px) {
    .grid__5col.container__reach {
      grid-template-areas: "respect enthusiasm altruism commitment honesty";
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.grid__6col {
  grid-template-rows: auto;
}
@media (min-width: 576px) {
  .grid__6col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .grid__6col {
    grid-template-columns: repeat(6, 1fr);
  }
}

.grid__footer {
  grid-template-areas: "info" "footer-submenu";
  grid-template-columns: 1fr;
  grid-row-gap: 3em;
  width: 100%;
}
@media (min-width: 768px) {
  .grid__footer {
    grid-template-areas: "info footer-submenu";
    grid-template-columns: 3fr auto;
    grid-column-gap: 4em;
  }
}
.grid__footer .footer-info {
  width: 100%;
}
@media (min-width: 768px) {
  .grid__footer .footer-info {
    width: 40%;
  }
}
@supports (display: grid) {
  .grid__footer .footer-info {
    width: unset;
  }
}
.grid__footer .grid__footer-subgrid {
  justify-content: flex-start;
}
.grid__footer .grid__footer-subgrid .grid-item {
  width: 100%;
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .grid__footer .grid__footer-subgrid {
    width: 60%;
    justify-content: flex-end;
  }
  .grid__footer .grid__footer-subgrid .grid-item {
    width: auto;
    margin-bottom: unset;
    margin-right: 2em;
  }
}
@supports (display: grid) {
  .grid__footer .grid__footer-subgrid {
    grid-gap: 0;
    grid-auto-flow: row;
    width: unset;
    grid-gap: 2em;
  }
  @media (min-width: 768px) {
    .grid__footer .grid__footer-subgrid {
      grid-template-columns: 2fr 2fr;
    }
  }
  @media (min-width: 992px) {
    .grid__footer .grid__footer-subgrid {
      grid-auto-flow: column;
    }
  }
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "mini-nav" "main-nav" "content" "footer";
  width: 100vw;
  max-width: 100%;
}

.container-pagination {
  width: 300px;
  margin: 0 auto;
  text-align: center;
}
.container-pagination > ul {
  padding-left: 0 !important;
  margin: 2em 1em 0em !important;
}
@media (min-width: 768px) {
  .container-pagination {
    width: 700px;
  }
}
@media (min-width: 992px) {
  .container-pagination {
    width: 900px;
  }
}

/*------------------------------------*\
#Grid Areas
\*------------------------------------*/
.post-item__category {
  grid-area: blog-category;
}

.post-item__content {
  grid-area: blog-content;
}

.post-item__excerpt {
  grid-area: blog-excerpt;
}

.post-item__image {
  grid-area: blog-image;
}

.blog-read-more {
  grid-area: read-more;
}

.post-item__title {
  grid-area: blog-title;
}

.post-item__publish-date {
  grid-area: blog-date;
}

.cafe-days.mon-thurs {
  grid-area: mon-thurs;
  display: block;
}
.cafe-days.friday {
  grid-area: friday;
  display: block;
}

.cafe-grade.all-grades {
  grid-area: all-grades;
}
.cafe-grade.elementary {
  grid-area: elementary;
}
.cafe-grade.junior-high {
  grid-area: junior-high;
}
.cafe-grade.high-school {
  grid-area: high-school;
}

.cafe-grade-mobile {
  grid-area: cafe-grade-mobile;
}

.cafe-times.mon-thurs.all-grades {
  grid-area: mon-thurs-all-grades;
}
.cafe-times.mon-thurs.elementary {
  grid-area: mon-thurs-elementary;
}
.cafe-times.mon-thurs.junior-high {
  grid-area: mon-thurs-junior-high;
}
.cafe-times.mon-thurs.high-school {
  grid-area: mon-thurs-high-school;
}
.cafe-times.friday.all-grades {
  grid-area: friday-all-grades;
}
.cafe-times.friday.elementary {
  grid-area: friday-elementary;
}
.cafe-times.friday.junior-high {
  grid-area: friday-junior-high;
}
.cafe-times.friday.high-school {
  grid-area: friday-high-school;
}

.mon-thurs__wrapper {
  grid-area: mon-thurs-wrapper;
}

.friday__wrapper {
  grid-area: friday-wrapper;
}

.content-section-image {
  grid-area: content-image;
}

.content-section-text {
  grid-area: content-text;
}

.contract-option-1 {
  grid-area: option-1;
}

.contract-option-2 {
  grid-area: option-2;
}

.contract-option-3 {
  grid-area: option-3;
}

#content {
  grid-area: content;
}

footer {
  grid-area: footer;
}

.main-nav {
  grid-area: main-nav;
}

.fee-application-fee {
  grid-area: application_fee;
}

.fee-initiation-fee {
  grid-area: initiation_fee;
}

.fee-academy-tuition {
  grid-area: academy_tuition;
}

.fee-collegiate-tuition {
  grid-area: collegiate_tuition;
}

.fee-other-fees {
  grid-area: other_fees;
}

.fee-grades-1-12-fee {
  grid-area: supplies_fee;
}

.fee-bussing-optional {
  grid-area: bussing_1;
}

.fee-bussing-optional-2 {
  grid-area: bussing_2;
}

.footer-info {
  grid-area: info;
}

.grid__foter-subgrid {
  grid-area: footer-submenu;
}

.integrated-studies-item-icon {
  grid-area: is-icon;
}

.integrated-studies-item-title {
  grid-area: is-title;
}

.mini-nav {
  grid-area: mini-nav;
}

.learning-model-graphic {
  grid-area: lm_graphic;
}

.learning-model-text {
  grid-area: lm_text;
}

.pillar_item-icon {
  grid-area: pillar-icon;
}

.pillar_item-text {
  grid-area: pillar-text;
}

.reach-image {
  grid-area: reach-image;
}

.reach_item.respect {
  grid-area: respect;
}
.reach_item.enthusiasm {
  grid-area: enthusiasm;
}
.reach_item.altruism {
  grid-area: altruism;
}
.reach_item.commitment {
  grid-area: commitment;
}
.reach_item.honesty {
  grid-area: honesty;
}

.reach-text {
  grid-area: reach-text;
}

.reach-title {
  grid-area: reach-title;
}

.reach-description {
  grid-area: reach-description;
}

.single-post__content {
  grid-area: single-post-content;
}

.single-post__sidebar {
  grid-area: single-post-sidebar;
}

.team-content {
  grid-area: team-content;
}

.team-post-item__thumbnail {
  grid-area: team-thumb;
}

.testimonial-post-author {
  grid-area: author;
}

.testimonial-post-author-info {
  grid-area: author-info;
}

.testimonial-post-meta {
  grid-area: testimonial-meta;
}

.testimonial-post-quote__text {
  grid-area: quote;
}

.tuition-fee.academy-header {
  grid-area: academy-header;
}
.tuition-fee.fee-year {
  grid-area: fee-year;
}
.tuition-fee.collegiate-header {
  grid-area: collegiate-header;
}
.tuition-fee .kindergarten-header {
  grid-area: kindergarten-header;
}

.tuition-fee-item-fee.academy {
  grid-area: academy-fee;
}
.tuition-fee-item-fee.collegiate {
  grid-area: collegiate-fee;
}
.tuition-fee-item-fee.kindergarten {
  grid-area: kindergarten-fee;
}

/*------------------------------------*\
#Grid Cell
\*------------------------------------*/
.grid-cell {
  padding: 1em 0.65em;
}

/*------------------------------------*\
#Bus Route Table
\*------------------------------------*/
.bus-route-wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .bus-route-wrapper {
    flex-direction: row;
  }
}
.bus-route-wrapper tr.column-header {
  background-color: #664a75;
  color: white;
}

.bus-route-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
.mb-half {
  margin-bottom: 0.5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .mb-2 {
    margin-bottom: 2em;
  }
}

.mr-1 {
  margin-right: 1em;
}

.mt-0 {
  margin-top: 0em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.p-narrow {
  padding: 1.5em 2em;
}
@media (min-width: 768px) {
  .p-narrow {
    padding: 1em 2em;
  }
}

.p-standard {
  padding: 2em;
}

.prl-4 {
  padding: 0 2em;
}
@media (min-width: 480px) {
  .prl-4 {
    padding: 0 2em;
  }
}
@media (min-width: 576px) {
  .prl-4 {
    padding: 0 4em;
  }
}

.pt-0 {
  padding-top: 0;
}

.ptb-2 {
  padding: 1em 0;
}
@media (min-width: 768px) {
  .ptb-2 {
    padding: 2em 0;
  }
}

.ptb-4 {
  padding: 2em 0;
}
@media (min-width: 576px) {
  .ptb-4 {
    padding: 3em 0;
  }
}
@media (min-width: 992px) {
  .ptb-4 {
    padding: 4em 0;
  }
}

.p-2-all {
  padding: 1em;
}
@media (min-width: 480px) {
  .p-2-all {
    padding: 2em;
  }
}
@media (min-width: 576px) {
  .p-2-all {
    padding: 4em;
  }
}
.p-2-all.calendar-container {
  padding: 2em 0.5em;
}
@media (min-width: 576px) {
  .p-2-all.calendar-container {
    padding: 4em;
  }
}

.p-4-all {
  padding: 1em;
}
@media (min-width: 480px) {
  .p-4-all {
    padding: 2em;
  }
}
@media (min-width: 576px) {
  .p-4-all {
    padding: 4em;
  }
}
.p-4-all.calendar-container {
  padding: 2em 0.5em;
}
@media (min-width: 576px) {
  .p-4-all.calendar-container {
    padding: 4em;
  }
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

/*------------------------------------*\
#Components
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Alerts
\*------------------------------------*/
.alert {
  padding: 1em;
  color: #fff;
}
.alert.alert-reminder {
  background-color: #eeb22a;
  color: #000;
}
.alert.alert-important {
  background-color: #f36c23;
}
.alert.alert-stat {
  background-color: #664a75;
}
.alert.alert-closure {
  background-color: #960018;
  color: #fff !important;
}
.alert a {
  color: #fff;
}
.alert.no-padding .notification-text {
  display: block;
  margin-top: 0;
  padding: 1em 1em 0;
}
@media (min-width: 768px) {
  .alert.no-padding .notification-text {
    padding: 0;
    display: inline;
  }
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Button
\*------------------------------------*/
button {
  border: none;
  font-size: 1.5rem;
  background-color: transparent;
}
button:hover {
  cursor: pointer;
}
button:focus {
  outline: none;
}

.btn {
  display: inline-block;
  background-color: #664a75;
  color: #fff;
  border-radius: 60px;
  border: none;
  min-height: 60px;
  min-width: 240px;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border: 2px solid #664a75;
  text-align: center;
  padding: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.btn:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  color: #fff;
}
.btn.btn-icon {
  padding: 20px;
}
.btn.btn-left-margin {
  margin-left: 10px;
}
.btn.btn-right-margin {
  margin-right: 10px;
}
.btn.btn-top-margin {
  margin-top: 10px;
}
.btn.btn-small,
.btn .btn-small a {
  margin: 0 0.5em;
}
.btn.btn-bottom-margin {
  margin-bottom: 10px;
}
.btn.btn-full {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn-notification-bar {
  padding: 0.5em 1em;
  margin: 1em;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btn.btn-notification-bar {
    min-height: 100%;
  }
}
.btn.btn-notification-bar:hover {
  background-color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  color: #fff;
}
.btn.btn-outline {
  background-color: transparent;
  border: 2px solid #664a75;
  color: #664a75;
}
.btn.btn-outline:hover {
  background-color: #664a75;
  border-color: #664a75;
  color: #fff;
}
.btn.btn-outline.on-dark:hover {
  color: #664a75;
}
.btn.btn-outline-light {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.btn.btn-outline-light:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #fff;
  color: #fff;
}
.btn.btn-submit {
  background-color: #664a75;
  border: none;
}
.btn.btn-submit:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  cursor: pointer;
}
.btn.btn-view {
  background-color: #eeb22a;
  border: none;
}
.btn.btn-view:hover {
  background-color: rgb(212.0739130435, 152.3347826087, 16.9260869565);
  cursor: pointer;
}
.btn.btn-disabled {
  background-color: #eaeaea;
  color: #2d2d2d;
  border-radius: 0;
  border-color: #2d2d2d;
}
.btn.btn-notification-bar {
  max-width: 320px;
}
.btn.btn-220 {
  width: 100%;
}
@media (min-width: 768px) {
  .btn.btn-220 {
    max-width: 220px;
  }
}

.btn-list-light a {
  display: inline-block;
  background-color: #664a75;
  color: #fff;
  border-radius: 60px;
  border: none;
  min-height: 60px;
  min-width: 240px;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border: 2px solid #664a75;
  text-align: center;
  padding: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.btn-list-light a:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  color: #fff;
}
.btn-list-light a:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #fff;
  color: #fff;
}
.btn-list-light li {
  margin: 1em 0;
  display: block;
}
@media (min-width: 992px) {
  .btn-list-light li {
    margin: 0 1em;
    display: inline-block;
  }
}

.button {
  margin-top: 2em;
}

.btn-row a {
  margin: 0 1em 1em 0;
}
.btn-row a.btn-center {
  margin: 0 0.5em 1em;
}

.cta-buttons {
  padding: 0;
}
.cta-buttons.btn-primary {
  background-color: #664a75;
  border: 2px solid #664a75;
  color: #fff;
}
.cta-buttons.btn-primary:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
}
.cta-buttons .btn {
  width: 280px;
}
@media (min-width: 768px) {
  .cta-buttons .btn {
    width: 320px;
  }
}
.cta-buttons.btn-group a.btn-primary {
  background-color: #664a75;
  border: 2px solid #664a75;
  color: #fff;
}
.cta-buttons.btn-group a.btn-primary:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
}
.cta-buttons.btn-group a.btn-secondary {
  background-color: transparent;
  border: 2px solid #664a75;
  color: #664a75;
  margin-top: 1em;
  margin-left: 0;
}
.cta-buttons.btn-group a.btn-secondary:hover {
  background-color: #664a75;
  border-color: #664a75;
  color: #fff;
}
@media (min-width: 768px) {
  .cta-buttons.btn-group a.btn-secondary {
    margin-top: 0;
    margin-left: 1em;
  }
}
.cta-buttons.single-btn.btn-outline .btn-primary {
  background-color: transparent;
  border: 2px solid #664a75;
  color: #664a75;
}
.cta-buttons.single-btn.btn-outline .btn-primary:hover {
  background-color: #664a75;
  border-color: #664a75;
  color: #fff;
}
.cta-buttons.post-links a {
  margin: 1em 0;
}
.cta-buttons.cta-header__btns .btn.btn-primary, .cta-buttons.cta-block .btn.btn-primary {
  background-color: #f36c23;
  border-color: #f36c23;
  color: #fff;
}
.cta-buttons.cta-header__btns .btn.btn-primary:hover, .cta-buttons.cta-block .btn.btn-primary:hover {
  background-color: rgb(191.0775862069, 73.8254310345, 10.4224137931);
  border-color: rgb(191.0775862069, 73.8254310345, 10.4224137931);
  color: #fff;
}
.cta-buttons.cta-header__btns .btn.btn-secondary, .cta-buttons.cta-block .btn.btn-secondary {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.cta-buttons.cta-header__btns .btn.btn-secondary:hover, .cta-buttons.cta-block .btn.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #fff;
  color: #fff;
}
.cta-buttons.icon-block .btn {
  width: 100%;
}
.cta-buttons.pages-content-item .btn {
  min-width: 280px;
}
@media (min-width: 768px) {
  .cta-buttons.pages-content-item .btn {
    min-width: 320px;
  }
}

@media (min-width: 576px) {
  .cta-button-single .btn {
    max-width: 288px;
  }
}
@media (min-width: 992px) {
  .cta-button-single .btn {
    max-width: 320px;
  }
}
.cta-button-single .btn.wide-button {
  max-width: 100%;
}
@media (min-width: 768px) {
  .cta-button-single .btn.wide-button {
    max-width: 500px;
  }
}
.cta-button-single.bottom {
  padding-top: 15px;
}

.container.p-4-all .cta-buttons .btn {
  max-width: 100%;
}
@media (min-width: 768px) {
  .container.p-4-all .cta-buttons .btn {
    min-width: 320px;
  }
}

a.download-item-meta {
  display: block;
  padding: 0.2em 0.5em;
  background-color: #664a75;
  width: 160px;
  color: #fff;
  font-weight: 400;
}
a.download-item-meta:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  color: #fff;
}
a.download-item-meta span {
  font-size: 1.4rem;
}

.payment-method-buttons {
  flex-direction: column;
}
.payment-method-buttons a {
  margin-bottom: 0.75em;
}
@media (min-width: 768px) {
  .payment-method-buttons {
    flex-direction: row;
    margin-bottom: 0;
  }
}
.payment-method-buttons .btn {
  min-width: 280px;
}

.plastiq-button {
  width: 221px;
  height: 70px;
  display: block;
  background-image: url(../images/plastiq-pay-button.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  margin-top: 2em;
}
.plastiq-button span {
  visibility: hidden;
}
.plastiq-button:hover {
  background-position: 0 -70px;
}

.section__we-are-ca .btn-primary {
  background-color: transparent;
  border: 2px solid #664a75;
  color: #664a75;
  margin: 0;
  text-align: center;
}
.section__we-are-ca .btn-primary:hover {
  background-color: #664a75;
  border-color: #664a75;
  color: #fff;
}
.section__we-are-ca .cta-buttons {
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .section__we-are-ca .cta-buttons {
    text-align: left;
    margin-bottom: 0;
  }
}

span#pq-pay-button-container img {
  width: 260px !important;
}

ul#menu-404-page-buttons li {
  margin: 1em 1em 2em 1em;
}

/*------------------------------------*\
#Content Sections
\*------------------------------------*/
.content-section .cta-buttons {
  padding-top: 0;
}

.section__header-cta {
  margin-top: 1.5em;
}

/*------------------------------------*\
#Pagination
\*------------------------------------*/
ul.page-numbers li {
  display: inline-block;
  padding-bottom: 1em;
}
@media (min-width: 768px) {
  ul.page-numbers li {
    padding-bottom: 0;
  }
}
ul.page-numbers li:first-child {
  padding: 0;
}
ul.page-numbers li a.page-numbers {
  background-color: transparent;
  color: #664a75;
}
ul.page-numbers li a.page-numbers:hover {
  background-color: #664a75;
  color: #fff;
  curssor: pointer;
}
ul.page-numbers li a.next .fa-arrow-circle-left, ul.page-numbers li a.prev .fa-arrow-circle-left {
  margin: 0;
}
@media (min-width: 768px) {
  ul.page-numbers li a.next .fa-arrow-circle-left, ul.page-numbers li a.prev .fa-arrow-circle-left {
    margin-right: 0.5em;
  }
}
ul.page-numbers li a.next .fa-arrow-circle-right, ul.page-numbers li a.prev .fa-arrow-circle-right {
  margin: 0;
}
@media (min-width: 768px) {
  ul.page-numbers li a.next .fa-arrow-circle-right, ul.page-numbers li a.prev .fa-arrow-circle-right {
    margin-left: 0.5em;
  }
}
ul.page-numbers li a.next span, ul.page-numbers li a.prev span {
  display: none;
}
@media (min-width: 768px) {
  ul.page-numbers li a.next span, ul.page-numbers li a.prev span {
    display: inline;
  }
}
ul.page-numbers li a.page-numbers,
ul.page-numbers li span.page-numbers {
  border-radius: 4px;
  border: 1px solid #664a75;
  line-height: 45px;
  height: 37px;
  padding: 0 15px;
  display: block;
  height: 45px;
  display: block;
  min-width: 45px;
  text-align: center;
}
ul.page-numbers span.page-numbers {
  background-color: #664a75;
  color: #fff;
}
ul.page-numbers span.page-numbers.dots {
  border: none;
  background-color: transparent;
  color: #664a75;
  padding: 0;
  min-width: auto;
}

/*------------------------------------*\
#Team Social Links
\*------------------------------------*/
.team-social-links {
  list-style: none;
  padding: 0;
  margin: 10px auto 0; /* Center the entire list */
  display: flex;
  gap: 12px; /* Adjust spacing between icons */
  justify-content: center; /* Center icons horizontally */
  align-items: center; /* Center icons vertically */
}

.team-social-links li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Ensure uniform width */
  height: 40px; /* Ensure uniform height */
  padding: 0;
}

.team-social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.team-social-links svg {
  width: 30px;
  height: 30px;
  fill: #664a75; /* Default purple fill */
  transition: fill 0.3s ease-in-out, transform 0.2s ease-in-out;
}

ul.team-social-links {
  margin-top: 1em;
}

/* Hover Effect */
.team-social-links a:hover svg {
  fill: rgb(47.5287958115, 34.4816753927, 54.5183246073); /* Red hover color */
  transform: scale(1.1); /* Slight enlargement on hover */
}

/*------------------------------------*\
#Social Sharing
\*------------------------------------*/
.social-wrapper {
  margin: 30px 0;
  font-size: 0;
}

.social-wrapper span {
  font-weight: bold;
  padding-right: 10px;
  font-size: 16px;
}

.social-sharing {
  font-size: 17px;
  padding-right: 1em;
}
.social-sharing:last-child {
  padding-right: unset;
}

@media only screen and (max-width: 600px) {
  .social-sharing {
    display: inline-block;
  }
}
.social-sharing svg {
  position: relative;
  top: 0.15em;
  display: inline-block;
  height: 2em;
}
.social-sharing path {
  fill: white;
}

.social-email circle {
  fill: #664a75;
}

.social-email circle:hover {
  fill: rgb(74.7643979058, 54.2408376963, 85.7591623037);
}

.social-facebook circle {
  fill: #3b5998;
}

.social-facebook circle:hover {
  fill: rgb(94.7156398104, 126.3507109005, 192.7843601896);
}

.social-twitter circle {
  fill: #1da1f2;
}

.social-twitter circle:hover {
  fill: rgb(101.3389121339, 191.0878661088, 246.1610878661);
}

.social-linkedin circle {
  fill: #0077b5;
}

.social-linkedin circle:hover {
  fill: rgb(2.5, 168.5082872928, 255);
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Calendar Styles
\*------------------------------------*/
ul.stec-top-menu-filter-dropdown,
ul.stec-top-dropmenu-layouts {
  margin: 0;
}

.stec-layout-agenda-events-all-control,
p.stec-layout-day-noevents {
  margin-bottom: 3em !important;
}

.stec-top-menu-filter-by-calendar:hover,
.stec-top-menu-filter-by-category:hover,
.stec-top-menu-filter-by-organizer:hover,
.stec-top-menu-filter-by-location:hover,
.stec-top-menu-filter-by-featured:hover {
  background-color: #7a6e66;
}

body .stec-layout-month-daylabel p {
  color: #fff !important;
}

.stec {
  font-family: "Uniform", Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}
.stec ul li:before {
  content: "" !important;
}

.stec-layout-month-daylabel p {
  font-family: "Uniform", Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}

.stec-layout-month-daycell .stec-layout-month-daycell-wrap .stec-layout-month-daycell-num {
  font-family: "Uniform", Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}

.responsive-calendar {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
}

.responsive-calendar iframe {
  border: 0;
  width: 100%;
  min-height: 50vh; /* Adjust the value based on your needs */
}
@media (min-width: 768px) {
  .responsive-calendar iframe {
    min-height: 80vh; /* Adjust the value based on your needs */
  }
}

@media screen and (max-width: 768px) {
  .responsive-calendar iframe {
    width: 150%; /* Adjust as necessary for your content */
  }
}
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Forms
\*------------------------------------*/
.category-filter {
  height: 44px;
  display: flex;
  float: right;
}
.category-filter .dropdown-filter {
  width: auto;
}
.category-filter .form-group {
  float: none;
  align-items: center;
}
@media (min-width: 576px) {
  .category-filter .form-group {
    float: right;
  }
}

.dropdown-filter {
  font-size: 1.5rem;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.dropdown-filter .select {
  width: 100%;
  background: url(arrow.png) no-repeat;
  background-position: 80% center;
}
.dropdown-filter .select select {
  background: transparent;
  line-height: 1;
  border: 0;
  padding: 0;
  border-radius: 0;
  width: 100%;
  position: relative;
  z-index: 10;
  font-size: 1em;
  font-family: "Uniform", Helvetica, Arial, sans-serif !important;
}

.form-label.label-left {
  padding-right: 10px;
}

/*------------------------------------*\
#Gravity Forms Styles
\*------------------------------------*/
.gform_wrapper .hidden_label .gfield_label,
.gform_wrapper label.hidden_sub_label,
.gform_wrapper label.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.gform_wrapper li.hidden_label input {
  margin-top: 12px;
}
.gform_wrapper .field_description_above label {
  margin-bottom: 0.25em;
}
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
  margin-bottom: 12px;
}
.gform_wrapper .gfield_description p {
  color: #2d2d2d !important;
}
.gform_wrapper .gfield_description p + p {
  margin-top: 0.5em;
}
.gform_wrapper .left_label li.hidden_label input,
.gform_wrapper .right_label li.hidden_label input {
  margin-left: 3.7%;
}
.gform_wrapper .gform_body .name_last {
  padding-left: 0 !important;
}
.gform_wrapper .gform_body .name_first {
  padding-right: 0 !important;
}
@media (min-width: 768px) {
  .gform_wrapper .gform_body .name_first {
    padding-right: 16px !important;
  }
}
.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
  display: inline-block;
  background-color: #664a75;
  color: #fff;
  border-radius: 60px;
  border: none;
  min-height: 60px;
  min-width: 240px;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border: 2px solid #664a75;
  text-align: center;
  padding: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 1em !important;
}
.gform_wrapper .gform_footer input.button:hover, .gform_wrapper .gform_footer input[type=submit]:hover {
  background-color: rgb(74.7643979058, 54.2408376963, 85.7591623037);
  color: #fff;
}
.gform_wrapper .gform_footer input.button:hover, .gform_wrapper .gform_footer input[type=submit]:hover {
  cursor: pointer;
}
.gform_wrapper .hidden_label input.large,
.gform_wrapper .hidden_label select.large,
.gform_wrapper .top_label input.large,
.gform_wrapper .top_label select.large {
  width: 100%;
}
.gform_wrapper hr {
  margin: 2em 0;
}

.gform_confirmation_wrapper {
  padding: 1em;
  background-color: rgb(153.8848167539, 122.0732984293, 170.9267015707);
  border: 3px solid #664a75;
  border-radius: 10px;
}
.gform_confirmation_wrapper p {
  color: #664a75;
}

.ginput_container_fileupload input {
  padding: 12px;
}

.gform_fields li {
  padding: 0 !important;
}
.gform_fields li label .gfield_label {
  line-height: 1.2em !important;
  margin-bottom: 0.25em !important;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Graphics
\*------------------------------------*/
.brand-logo {
  width: 240px;
  display: block;
}

.blog-post-logo {
  width: 220px;
}

img.screenshot, img.card-icons {
  width: 100%;
}
img.screenshot.card-icons, img.card-icons.card-icons {
  width: auto;
}
@media (min-width: 576px) {
  img.screenshot, img.card-icons {
    width: auto;
  }
}
img.screenshot {
  border: 1px solid #8e8e8e;
}

.section img {
  width: 100%;
  height: auto;
}
.section .section__block-content-credit-card img {
  width: auto;
}

.paint-splash img {
  flex-shrink: 0;
}
@media (min-width: 576px) {
  .paint-splash img {
    min-width: 540px;
  }
}
@media (min-width: 992px) {
  .paint-splash img {
    min-width: 800px;
  }
}
@media (min-width: 1200px) {
  .paint-splash img {
    min-width: 960px;
  }
}

.pages-content__item-card {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.pages-content__item-card.img-default {
  width: 100%;
  aspect-ratio: 4/3;
  max-height: 360px;
  min-height: 200px;
  display: block;
}
.pages-content__item-card.img-default.grid.column-item {
  max-height: 220px;
}
@media (min-width: 768px) {
  .pages-content__item-card.img-default {
    max-height: clamp(220px, 30vw, 360px);
  }
}
.pages-content__item-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .pages-content__item-card {
    margin-right: 1em;
    width: 460px;
    height: auto;
  }
  .pages-content__item-card.grid {
    width: 100%;
    margin: 0;
  }
}
.pages-content__item-card.image-card, .pages-content__item-card.outline {
  position: relative;
  width: 100%;
  height: 100%;
}
.pages-content__item-card.timeline {
  border: none;
  width: 100%;
  height: 200px;
  margin-bottom: 1.5em !important;
  border-radius: 0;
}
@media (min-width: 768px) {
  .pages-content__item-card.timeline {
    width: 320px;
    margin: 0 1.5em 0 0 !important;
    overflow: hidden;
    position: relative;
    flex: 0 0 300px;
    align-items: center;
  }
}
.pages-content__item-card.img-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: clamp(100px, 80%, 200px);
  height: auto;
  padding: 0;
}
.pages-content__item-card.img-round img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.pages-content__item-card.img-round.column-item {
  align-self: center;
  justify-self: center;
  text-align: center;
}
.pages-content__item-card.img-round.column-item img {
  width: 80%;
  height: auto;
  max-width: 100%;
  aspect-ratio: 1/1;
}
.pages-content__item-card.img-round.row-item {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: auto;
}
.pages-content__item-card.img-round.row-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.pages-content__item-card.row-item {
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .pages-content__item-card.row-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 2em;
  }
}
.pages-content__item-card.column-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/*------------------------------------*\
#Images
\*------------------------------------*/
.bkg-contain {
  background-size: contain;
}

.bkg-cover {
  background-size: cover;
}

/*------------------------------------*\
#Icons
\*------------------------------------*/
.app-item-icon {
  width: 3em;
  height: 3em;
  border-radius: 5px;
  background-color: #664a75;
  padding: 1em;
}
.app-item-icon i {
  color: #fff;
  font-size: 1.75em;
}

.appliction-steps-number {
  font-size: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  background-color: #7f6789;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border: 6px solid #664a75;
  margin: 0 auto;
}

.appliction-steps-number span {
  padding-top: 0.12em;
}

.container__logo.logo-h img {
  width: 100%;
}
@media (min-width: 576px) {
  .container__logo.logo-h img {
    height: 180px;
    width: auto;
  }
}
.container__logo.logo-v img {
  width: 320px;
}
.container__logo.logo-bottom {
  margin-top: 2em;
}
.container__logo.logo-top {
  margin-bottom: 2em;
}

.cta-button-icon {
  padding-right: 0.5em;
}

.feature-item__icon {
  width: 80px;
  margin: 0 auto;
}

.icon.icon-100 img {
  width: 100px;
}
.icon.icon-160 img {
  width: 160px;
}
.icon.icon-220 img {
  width: 220px;
}
.icon.icon-text-row img {
  padding-right: 1em;
}
.icon.goal_item-icon {
  top: -80px;
}

.icon-block-item .icon-block__icon {
  width: 180px;
  height: 180px;
  margin: 0 auto 1.5em;
}
.icon-block-item .icon-block__icon i {
  font-size: 4em;
}
.icon-block-item img {
  width: 100%;
}

.icon-left {
  margin-right: 15px;
}

.icon-right {
  margin-left: 15px;
}

.item__icon {
  max-width: 200px;
  margin: 0 auto;
}

.pillar-icon img,
.reach-image img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.reach-image img {
  height: 180px;
}

.pillar_item-icon {
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .pillar_item-icon {
    margin-bottom: 0;
  }
}
.pillar_item-icon img {
  height: 180px;
}
@media (min-width: 768px) {
  .pillar_item-icon img {
    height: auto;
  }
}

.thumbnail-round {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1em;
}
.thumbnail-round.team-post-item__thumbnail {
  width: 279px;
  height: 279px;
}

.thumbnail-round img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.video-item__thumbnail {
  margin-bottom: 1em;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-item__thumbnail iframe,
.video-item__thumbnail object,
.video-item__thumbnail embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*------------------------------------*\
#Images
\*------------------------------------*/
.bkg-image-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.bkg-image-outer.ct-item-card {
  height: 170px;
}
.bkg-image-outer .bkg-image-text {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bkg-image-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container__single-post-image {
  margin: 0 auto 1em;
}
@media (min-width: 768px) {
  .container__single-post-image {
    margin-bottom: 2em;
  }
}
.container__single-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container__single-post-image img.single-post-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

figure.wp-caption {
  width: 100% !important;
}

.gallery .gallery-item {
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

img,
.wp-caption img {
  max-width: 100%;
  height: auto;
}
img .alignright,
.wp-caption img .alignright {
  float: right;
}
img .alignleft,
.wp-caption img .alignleft {
  float: left;
}
img .aligncenter,
.wp-caption img .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img .alignnone, img.alignnone,
.wp-caption img .alignnone,
.wp-caption img.alignnone {
  display: block;
  margin-bottom: 1em;
}
img .alignnone.fullwidth-image, img.alignnone.fullwidth-image,
.wp-caption img .alignnone.fullwidth-image,
.wp-caption img.alignnone.fullwidth-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
img .alignnone.banner, img.alignnone.banner,
.wp-caption img .alignnone.banner,
.wp-caption img.alignnone.banner {
  object-fit: cover;
  height: 320px;
  width: 100%;
}
img .aligncenter, img.aligncenter,
.wp-caption img .aligncenter,
.wp-caption img.aligncenter {
  display: block;
  margin: 1em auto;
}
img .alignleft, img.alignleft,
.wp-caption img .alignleft,
.wp-caption img.alignleft {
  display: inline-flex;
  float: left;
  padding: 1em 1em 1em 0;
}
img .alignright, img.alignright,
.wp-caption img .alignright,
.wp-caption img.alignright {
  display: inline-flex;
  float: right;
  padding: 1em 0 1em 1em;
}
img .full-width, img.full-width,
.wp-caption img .full-width,
.wp-caption img.full-width {
  width: 100%;
}
img .half-width, img.half-width,
.wp-caption img .half-width,
.wp-caption img.half-width {
  width: 50%;
}
img .third-width, img.third-width,
.wp-caption img .third-width,
.wp-caption img.third-width {
  width: 33%;
}
img .quarter-width, img.quarter-width,
.wp-caption img .quarter-width,
.wp-caption img.quarter-width {
  width: 25%;
}

.learning-model-element-image {
  overflow: hidden;
  object-fit: cover;
  position: relative;
  height: 350px;
}
.learning-model-element-image img {
  height: 100%;
  width: auto;
}

.paint-splash img {
  height: auto;
}

.resource-item img {
  max-height: 100px;
}

/*------------------------------------*\
#Logos
\*------------------------------------*/
.scoca-header-logo img {
  height: 100px;
}

/*------------------------------------*\
#Video Wrapper
\*------------------------------------*/
.videowrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: auto;
  width: 100vw;
}
.videowrapper.full-width {
  height: 0;
  overflow: hidden;
  width: unset;
}
.videowrapper.single-post {
  width: unset;
  margin: 1em auto;
}
@media (min-width: 768px) {
  .videowrapper.single-post {
    width: 100%;
    margin: 1.5em auto;
  }
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.recruitment.recruitment_styles .careercenter-container .careercenter-carousel-image {
  height: auto !important;
}

/*------------------------------------*\
#Image Overrides
\*------------------------------------*/
.pages-content__item-card.img-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: clamp(100px, 80%, 300px);
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
}
.pages-content__item-card.img-round img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.pages-content__item-card.img-round.column-item {
  align-self: center;
  justify-self: center;
}
.pages-content__item-card.img-round.row-item {
  align-self: center;
  justify-self: center;
}
.pages-content__item-card.img-round.column-item {
  text-align: center;
}
.pages-content__item-card.img-round.row-item {
  max-width: 200px;
  height: auto;
  flex-shrink: 0;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#Modals
\*------------------------------------*/
.modal-box {
  display: none;
  z-index: 1000;
  background: white;
  border-bottom: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  min-height: 70vh;
}
@media (min-width: 768px) {
  .modal-box {
    margin-top: 0em;
    height: auto;
    width: 600px;
    height: auto;
    min-height: 600px;
  }
}
@media (min-width: 992px) {
  .modal-box {
    width: 1200px;
  }
}

.modal-body {
  height: 100%;
}
.modal-body p .btn {
  line-height: 1em;
}
.modal-body ol {
  margin: 0;
}
.modal-body .screenshot {
  width: 100%;
}
@media (min-width: 768px) {
  .modal-body .screenshot {
    width: 60%;
  }
}
.modal-body iframe {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.modal-body.grid {
  grid-template-areas: "modal-thumb" "modal-text";
  grid-template-columns: 1fr;
  grid-template-rows: auto 3fr;
}
@media (min-width: 768px) {
  .modal-body.grid {
    grid-template-areas: "modal-thumb modal-text";
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
  }
}
.modal-body .content-section {
  height: 100%;
}

.modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-thumbnail {
  grid-area: modal-thumb;
  margin-bottom: 1em;
}
.modal-thumbnail img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .modal-thumbnail {
    margin-bottom: unset;
  }
}

.modal-text {
  grid-area: modal-text;
}

.modal-box header h3,
.modal-box header h4,
.modal-box .modal-header h3,
.modal-box .modal-header h4 {
  margin: 0;
}

.modal-box .modal-body {
  padding: 2em;
}

.modal-box footer,
.modal-box .modal-footer {
  padding: 1em;
  border-top: 1px solid #ddd;
  background: rgba(0, 0, 0, 0.02);
  text-align: right;
}

.modal-overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
}

a.close {
  line-height: 1;
  font-size: 1.5em;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  text-decoration: none;
  color: #bbb;
  background-color: #eaeaea;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
}
@media (min-width: 768px) {
  a.close {
    top: 1em;
    right: 1em;
  }
}

a.close:hover {
  color: #222;
  -webkit-transition: color 1s ease;
  -moz-transition: color 1s ease;
  transition: color 1s ease;
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
# Main Navigation
\*------------------------------------*/
.header-menu li {
  padding-left: 0;
}
@media (min-width: 992px) {
  .header-menu li {
    padding-left: 0.85em;
  }
}

.container__secondary-nav {
  display: none;
}
@media (min-width: 768px) {
  .container__secondary-nav {
    display: block;
  }
}

.main-nav {
  border-bottom: 1px solid #d6d1ca;
}

.mini-nav {
  background-color: #aca198;
}

.menu-toggle {
  display: inline-block;
  line-height: 1em;
  font-size: 1.5em;
  color: #664a75;
  position: absolute;
  right: 0;
  height: 44px;
  padding: 10px 15px;
  text-align: center;
}
@media (min-width: 1100px) {
  .menu-toggle {
    display: none;
  }
}

.menu-toggle:hover,
.menu-toggle:focus {
  color: #5e2154;
}

.main-menu a {
  display: block;
  padding: 0.75em 0.5em;
  line-height: 1em;
  font-size: 1.5em;
  color: #2d2d2d;
  text-decoration: none;
  text-align: left;
}
.main-menu a:hover {
  color: #7a6e66;
}
@media (min-width: 1100px) {
  .main-menu a {
    font-size: 1.7em;
    padding: 0.75em 1em;
  }
}
@media (min-width: 1100px) {
  .main-menu a {
    color: #664a75;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.main-menu li:last-child a {
  padding-right: 0;
}

.main-menu .menu-close {
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 1100px) {
  .main-menu .menu-close {
    display: none;
  }
}

.main-menu:target,
.main-menu[aria-expanded=true] {
  right: 0;
  outline: none;
  -moz-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded=true] .menu-close {
  z-index: 1001;
}

.main-menu:target ul,
.main-menu[aria-expanded=true] ul {
  position: relative;
  z-index: 1000;
}

.main-menu:target + .backdrop,
.main-menu[aria-expanded=true] + .backdrop {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #000;
  background: rgba(0, 0, 0, 0.85);
  cursor: default;
}

@supports (position: fixed) {
  .main-menu,
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded=true] + .backdrop {
    position: fixed;
  }
}
.main-menu {
  position: absolute;
  right: -280px;
  top: 0;
  height: 100%;
  transition: right 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;
}
@media (min-width: 1100px) {
  .main-menu {
    position: relative !important;
    right: auto;
    top: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

/*------------------------------------*\
#New Nav Styles
\*------------------------------------*/
.container__main-nav {
  height: auto;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 30;
}
.container__main-nav.shrink {
  height: auto;
  min-height: 70px;
  -webkit-transition: height 0.5s, ease-in-out;
  /* Safari */
  transition: height 0.5s, ease-in-out;
}

.lock-scroll .container__main-nav.shrink {
  height: auto;
}

.main-nav {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 45px;
  z-index: 5;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
}
.main-nav.scrolled {
  top: 0;
  -webkit-box-shadow: 0 6px 12px rgba(45, 45, 45, 0.175);
  box-shadow: 0 6px 12px rgba(45, 45, 45, 0.175);
  border-bottom: 1px #7a6e66 solid;
  background-color: #f9f9f9;
  margin-top: 0;
  position: fixed;
}
.main-nav.scrolled .sub-menu {
  top: 70px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-nav {
    margin-top: 0;
  }
}

.main-menu-item {
  text-align: center;
}

.mini-nav_contact a {
  font-weight: 400;
}

.header-menu,
.menu-main-menu-container {
  height: 100%;
  align-items: center;
  display: flex;
}

.header-menu {
  flex-direction: column;
  width: 100%;
}
@media (min-width: 1100px) {
  .header-menu {
    flex-direction: row;
  }
  .header-menu li {
    padding: 0 !important;
  }
}

.navbar-collapse {
  display: none;
  align-items: center;
  flex-direction: column;
  width: 100%;
  transition: all 0.2s ease;
}
@media (min-width: 1200px) {
  .navbar-collapse {
    font-size: 1.6rem;
  }
}
@media (min-width: 1100px) {
  .navbar-collapse {
    display: flex;
    align-items: flex-end;
    width: auto;
    font-size: 1.45rem;
  }
}
@media (min-width: 768px) {
  .navbar-collapse {
    font-size: 1.4rem;
  }
}
.navbar-collapse.show {
  display: flex;
  background-color: #f9f9f9;
}
.navbar-collapse.show ul.header-menu {
  height: 85vh;
  overflow-y: auto;
}
.navbar-collapse.show ul.header-menu > .menu-item > a {
  height: auto;
}
.navbar-collapse.show .menu-main-menu-container {
  height: 100vh;
}
.navbar-collapse .menu-item {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1100px) {
  .navbar-collapse .menu-item {
    height: 100%;
    width: auto;
  }
}
.navbar-collapse .header-menu > .menu-item > a:active {
  background-color: rgba(102, 74, 117, 0.5);
}

.menu-main-menu-container {
  flex-direction: column;
  width: 100%;
}
@media (min-width: 1100px) {
  .menu-main-menu-container {
    flex-direction: row-reverse;
    width: auto;
    height: 100px;
  }
  .menu-main-menu-container.scrolled {
    height: 70px;
  }
}

.header-menu > .menu-item > a {
  padding: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease;
  font-weight: 400;
}
.header-menu > .menu-item > a:hover {
  background-color: rgba(102, 74, 117, 0.2);
  transition: all 0.2s ease;
  color: #664a75;
}
.header-menu > .menu-item > a:active {
  background-color: rgba(102, 74, 117, 0.35);
}
@media (min-width: 1100px) {
  .header-menu > .menu-item > a {
    padding: 0 1em;
  }
}

.nav-brand-toggle {
  display: flex;
  align-items: center;
  height: 100px;
  transition: all 0.2s ease;
  padding: 0 15px;
}

.shrink .nav-brand-toggle {
  height: 70px;
}

.nav-toggle {
  display: block;
  background: none;
  transition: none;
  min-width: 40px;
}
@media (min-width: 1100px) {
  .nav-toggle {
    display: none;
  }
}

.navbar-header {
  width: 100%;
  height: 100%;
}
@media (min-width: 1100px) {
  .navbar-header {
    width: auto;
  }
}

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

span.menu-item-description {
  display: block;
  font-size: 0.65em;
  color: #2d2d2d;
  pointer-events: none;
  line-height: 1.5em;
}

.has-dropdown {
  position: relative;
}

.sub-menu {
  left: 0;
  background-color: rgba(102, 74, 117, 0.2);
  border-top: none;
  text-align: left;
  position: static;
  z-index: 99;
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}
.sub-menu.hidden {
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1100px) {
  .sub-menu {
    background-color: #f9f9f9;
    position: absolute;
    min-width: 195px;
    min-height: auto;
    top: 100px;
    -webkit-box-shadow: 0 6px 12px rgba(45, 45, 45, 0.175);
    box-shadow: 0 6px 12px rgba(45, 45, 45, 0.175);
  }
}
.sub-menu .menu-item {
  position: relative;
  display: block;
  clear: both;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}
@media (min-width: 1100px) {
  .sub-menu .menu-item {
    text-align: left;
  }
}
.sub-menu .menu-item:hover {
  background-color: rgba(102, 74, 117, 0.2);
}
.sub-menu .menu-item a {
  display: block;
  padding: 1em;
  font-weight: 400;
}

/*------------------------------------*\
#Mini Nav
\*------------------------------------*/
.container__mini-nav {
  height: 45px;
  min-height: 45px;
  width: 100vw;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
  display: flex;
  z-index: 20;
}
.container__mini-nav.display-hide {
  transition: all 0.5s ease;
  opacity: 0;
  display: none;
}
@media (min-width: 810px) {
  .container__mini-nav {
    justify-content: space-between;
  }
}

.mini-nav_contact ul {
  display: none;
}
.mini-nav_contact ul li {
  color: #fff;
  font-size: 1.3rem;
  display: inline-block;
  margin-right: 20px;
}
.mini-nav_contact ul li i {
  margin-right: 5px;
}
@media (min-width: 810px) {
  .mini-nav_contact ul {
    display: block;
  }
}

.mini-nav-menu {
  width: 100%;
}
.mini-nav-menu.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media (min-width: 810px) {
  .mini-nav-menu {
    width: auto;
  }
}
.mini-nav-menu ul.mini-nav-menu-items {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 810px) {
  .mini-nav-menu ul.mini-nav-menu-items {
    width: auto;
    justify-content: flex-end;
  }
}
.mini-nav-menu ul li {
  margin-left: 20px;
  display: inline-block;
}
.mini-nav-menu ul li:first-child {
  margin-left: 0;
}
.mini-nav-menu ul li a {
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2rem;
}
.mini-nav-menu ul li a:hover {
  color: #7a6e66;
  cursor: pointer;
}

nav ul li {
  display: block;
}
@media (min-width: 1100px) {
  nav ul li {
    display: inline-block;
    padding: 10px 30px;
  }
  nav ul li:last-child {
    padding-right: 0;
  }
}

/*------------------------------------*\
# CASSC Navigation
\*------------------------------------*/
.cassc-nav {
  background-color: rgba(0, 0, 0, 0.5);
}
.cassc-nav .mini-nav-menu {
  width: 100%;
}
.cassc-nav .mini-nav-menu.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.cassc-nav .mini-nav-menu ul.mini-nav-menu-items {
  justify-content: space-between;
}
.cassc-nav .mini-nav-menu ul li {
  padding: 1.5em !important;
}
.cassc-nav .mini-nav-menu ul li:hover {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
.cassc-nav .mini-nav-menu ul li.current-menu-item a {
  border-bottom: 1px solid #fff;
  padding-bottom: 0.5em;
}
.cassc-nav .mini-nav-menu ul li.current-menu-item a:hover {
  cursor: default;
  color: #fff;
}
.cassc-nav .mini-nav-menu ul a {
  color: #fff;
  font-size: 1.6rem;
  padding: 0;
}
.cassc-nav .mini-nav-menu ul a:hover {
  color: #fff;
}

/*------------------------------------*\
#Footer
\*------------------------------------*/
footer {
  text-align: center;
  flex: 1 0 auto;
}
@supports (display: grid) {
  footer {
    flex: unset;
  }
}
footer h1 {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-size: 1.4rem;
  line-height: 1.2em;
}
@media (min-width: 768px) {
  footer {
    text-align: left;
  }
}
footer .ca-contact,
footer .ca-social {
  padding: 10px 0px;
}
footer .ca-contact p,
footer .ca-social p {
  font-size: 1.4rem;
}
footer .ca-contact-numbers {
  margin-bottom: 1.25em;
}
footer .ca-contact-numbers p {
  display: inline-block;
  margin-right: 20px;
  margin-top: 0.8em;
}
footer .ca-contact-numbers p:last-child {
  margin-right: 0;
}
footer .ca-contact-numbers h2 {
  margin-bottom: 0.5em;
}
footer .ca-social li {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}
footer .ca-social li.facebook {
  background-image: url("../images/social-facebook.svg");
}
footer .ca-social li.instagram {
  background-image: url("../images/social-instagram.svg");
}
footer .ca-social li.twitter {
  background-image: url("../images/social-twitter.svg");
}
footer .ca-social li.linkedin {
  background-image: url("../images/social-linkedin.svg");
}
footer .ca-social li.youtube {
  background-image: url("../images/social-youtube.svg");
}
footer .ca-social span {
  display: none;
}
footer .ca-social a {
  display: block;
  height: 100%;
}
footer a {
  color: #fff;
}
footer a:hover {
  color: rgba(255, 255, 255, 0.5);
}
footer a .footer__secondary a:hover {
  color: #664a75;
}
footer li.shaw-birdies {
  width: 150px;
  height: 150px;
  background-image: url(../images/shaw-birdies-logo.png);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: contain; /* or 'contain' depending on how you want it displayed */
  background-position: center; /* centers the background image */
}
footer li.shaw-birdies a {
  display: block;
  height: 100%;
}
footer li.shaw-birdies span {
  display: none;
}

.footer__main {
  background-color: #664a75;
  font-size: 1.4rem;
}
.footer__main li.menu-item {
  padding: 0.75em 0 !important;
}
.footer__main a {
  font-weight: normal;
}

.logo-ca {
  height: 56px;
}
.logo-ca img {
  width: auto;
  height: 56px;
  flex-shrink: 0;
}

.footer__secondary {
  background-color: #5e2154;
  text-align: center;
  font-size: 1.4rem;
}
.footer__secondary span {
  display: inline-block;
}
.footer__secondary ul {
  margin-bottom: 20px;
  float: none;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .footer__secondary ul {
    float: right;
    display: inline-block;
    margin-bottom: none;
  }
}
.footer__secondary ul li {
  padding-bottom: 12px;
  padding-left: 0;
}
@media (min-width: 576px) {
  .footer__secondary ul li {
    float: right;
    padding-bottom: 0;
    padding-left: 30px;
  }
}
@media (min-width: 576px) {
  .footer__secondary {
    text-align: left;
  }
}

/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@media print {
  /*------------------------------------*\
  #Global
  \*------------------------------------*/
  body {
    background: #fff;
    color: #000;
    margin: 0;
    font-size: 14pt;
    font-family: "Uniform", Helvetica, Arial, sans-serif !important;
    font-weight: 400;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
  /*------------------------------------*\
  #Navigation
  \*------------------------------------*/
  .mini-nav,
  header,
  footer {
    display: none !important;
  }
  /*------------------------------------*\
  #Content
  \*------------------------------------*/
  #content {
    width: 100%;
    margin: 0;
    float: none;
    padding: 0;
  }
  /*------------------------------------*\
  #Page Layout
  \*------------------------------------*/
  .container {
    width: 100% !important;
  }
  .container.container__header {
    padding: 0 !important;
    min-height: 150px !important;
    border-bottom: 2px solid #000;
    margin-bottom: 25px;
  }
  .container.container__header.p-4-all {
    padding: 0 !important;
  }
  .p-4-all {
    padding: 0 !important;
  }
  .mb-2 {
    margin: 0 !important;
  }
  .section {
    padding-bottom: 40px;
  }
  .section:last-child {
    padding-bottom: 0;
  }
  /*------------------------------------*\
  #Typography
  \*------------------------------------*/
  p {
    line-height: 1.5em;
  }
  .text-white {
    color: #000;
  }
  .container__header h1 {
    font-size: 3rem;
  }
  /*------------------------------------*\
  #Header Tags
  \*------------------------------------*/
  h1 {
    font-size: 2.1rem;
    margin-bottom: 0.2em;
    line-height: 1.5em;
    font-weight: 400;
  }
  h1.small-heading {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 1.75rem;
    margin-bottom: 0.2em;
    line-height: 1.25em;
    font-weight: 500;
  }
  h2.small-heading {
    font-size: 2.4rem;
  }
  h3 {
    font-size: 1.4rem;
    margin-bottom: 0.2em;
    line-height: 1em;
  }
  h3.small-heading {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 1.05rem;
    margin-bottom: 0.2em;
    line-height: 0.75em;
    line-height: 1.2em;
  }
  h4.small-heading {
    font-size: 1.5rem;
    line-height: 1.25em;
  }
  h5 {
    font-size: 0.7rem;
    margin-bottom: 0.2em;
    line-height: 0.5em;
  }
  h6 {
    font-size: 0.35rem;
    margin-bottom: 0.2em;
    line-height: 0.25em;
  }
  section h1 {
    font-size: 2.1rem;
    margin-bottom: 0.2em;
    line-height: 1.5em;
  }
  section h2 {
    font-size: 1.75rem;
    margin-bottom: 0.2em;
    line-height: 1.25em;
  }
  section h3 {
    font-size: 1.4rem;
    margin-bottom: 0.2em;
    line-height: 1em;
  }
  section h4 {
    font-size: 1.05rem;
    margin-bottom: 0.2em;
    line-height: 0.75em;
  }
  section h5 {
    font-size: 0.7rem;
    margin-bottom: 0.2em;
    line-height: 0.5em;
  }
  section h6 {
    font-size: 0.35rem;
    margin-bottom: 0.2em;
    line-height: 0.25em;
  }
  article h1 {
    font-size: 1.68rem;
    margin-bottom: 0.2em;
    line-height: 1.2em;
  }
  article h2 {
    font-size: 1.4rem;
    margin-bottom: 0.2em;
    line-height: 1em;
  }
  article h3 {
    font-size: 1.12rem;
    margin-bottom: 0.2em;
    line-height: 0.8em;
  }
  article h4 {
    font-size: 0.84rem;
    margin-bottom: 0.2em;
    line-height: 0.6em;
  }
  article h5 {
    font-size: 0.56rem;
    margin-bottom: 0.2em;
    line-height: 0.4em;
  }
  article h6 {
    font-size: 0.28rem;
    margin-bottom: 0.2em;
    line-height: 0.2em;
  }
  footer h1 {
    font-size: 0.5775rem;
    margin-bottom: 0.2em;
    line-height: 0.4125em;
  }
  footer h2 {
    font-size: 0.48125rem;
    margin-bottom: 0.2em;
    line-height: 0.34375em;
  }
  footer h3 {
    font-size: 0.385rem;
    margin-bottom: 0.2em;
    line-height: 0.275em;
  }
  footer h4 {
    font-size: 0.28875rem;
    margin-bottom: 0.2em;
    line-height: 0.20625em;
  }
  footer h5 {
    font-size: 0.1925rem;
    margin-bottom: 0.2em;
    line-height: 0.1375em;
  }
  footer h6 {
    font-size: 0.09625rem;
    margin-bottom: 0.2em;
    line-height: 0.06875em;
  }
  aside h1 {
    font-size: 0.693rem;
    margin-bottom: 0.2em;
    line-height: 0.495em;
  }
  aside h2 {
    font-size: 0.5775rem;
    margin-bottom: 0.2em;
    line-height: 0.4125em;
  }
  aside h3 {
    font-size: 0.462rem;
    margin-bottom: 0.2em;
    line-height: 0.33em;
  }
  aside h4 {
    font-size: 0.3465rem;
    margin-bottom: 0.2em;
    line-height: 0.2475em;
  }
  aside h5 {
    font-size: 0.231rem;
    margin-bottom: 0.2em;
    line-height: 0.165em;
  }
  aside h6 {
    font-size: 0.1155rem;
    margin-bottom: 0.2em;
    line-height: 0.0825em;
  }
  /*------------------------------------*\
  #Career and Fees
  \*------------------------------------*/
  .container-application-item {
    text-align: left;
  }
  .application-items-list {
    margin: 1em 0 2em;
  }
  .application-items-list .app-item {
    margin-top: 1em;
    height: 100%;
  }
}