/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Breakpoints
\*------------------------------------*/

@mixin desktop-nav {
    @media (min-width: #{$desktop-nav-desc-breakpoint} ) {
        @content;
    }

    // Desktop Nav Breakpoint
}

@mixin form-break {
    @media (min-width: #{$form-breakpoint}) {
        @content;
    }

    // Form Breakpoint
}

@mixin nav-break {
    @media (min-width: #{$desktop-nav-desc-breakpoint}) {
        @content;
    }

    // Nav Breakpoint
}

@mixin team-break {
    @media (min-width: #{$team-breakpoint}) {
        @content;
    }
}

@mixin reach-break {
    @media (min-width: #{$reach-breakpoint}) {
        @content;
    }
}

@mixin menu-break {
    @media (min-width: #{$menu-breakpoint}) {
        @content;
    }

    // Desktop Nav Breakpoint
}

@mixin logged-in-desktop {
    @media (min-width: #{$wpadmin-desktop-nav-breakpoint}) {
        @content;
    }

    // Desktop Nav Breakpoint
}

@mixin mini-nav {
    @media (min-width: #{$mini-nav-breakpoint}) {
        @content;
    }

    // Desktop Nav Breakpoint
}

@mixin xxl {
    @media (min-width: #{$screen-xxl}) {
        @content;
    }

    // Extra Large Devices
}

@mixin xl {
    @media (min-width: #{$screen-xl}) {
        @content;
    }

    // Extra Large Devices
}

@mixin lg {
    @media (min-width: #{$screen-lg}) {
        @content;
    }

    // Large Devices
}

@mixin md {
    @media (min-width: #{$screen-md}) {
        @content;
    }

    // Medium Devices
}

@mixin sm {
    @media (min-width: #{$screen-sm}) {
        @content;
    }

    // Small Devices
}

@mixin xs {
    @media (min-width: #{$screen-xs}) {
        @content;
    }

    // Extra Small Devices
}

@mixin cafe-menu-break {
    @media (min-width: #{$cafe-menu-breakpoint}) {
        @content;
    }

    // Extra Small Devices
}

@mixin ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

/*------------------------------------*\
#Typography
\*------------------------------------*/

@mixin fontawesome-light {
    font-family: $fontawesome;
    font-weight: $light;
}

@mixin fontawesome-solid {
    font-family: $fontawesome;
    font-weight: $bold;
}

@mixin mongoose-light {
    font-family: mongoose, sans-serif;
    font-weight: 300;
    font-style: normal;
}

@mixin mongoose-regular {
    font-family: mongoose, sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin mongoose-medium {
    font-family: mongoose, sans-serif;
    font-weight: 500;
    font-style: normal;
}

/*------------------------------------*\
#Gradient Mixins
\*------------------------------------*/

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation== 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation== 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else if $orientation== 'radial' {
        background: -webkit-radial-gradient(
            center,
            ellipse cover,
            $start-color,
            $end-color
        );
        background: radial-gradient(
            ellipse at center,
            $start-color,
            $end-color
        );
    } @else if $orientation== 'angle' {
        background: -webkit-linear-gradient(160deg, $start-color, $end-color);
        background: linear-gradient(160deg, $start-color, $end-color);
    }
}

@mixin gradientmix($direction, $mix) {
    background: -webkit-linear-gradient($direction, $mix);
    background: -moz-linear-gradient($direction, $mix);
    background: -o-linear-gradient($direction, $mix);
    background: linear-gradient($direction, $mix);
}

$mix1: $lt-purple, $purple, $dk-purple;

$mix2: $purple, $dk-purple, $orange;

$mix3: $orange, $yellow;

/*------------------------------------*\
#Container Mixins
\*------------------------------------*/

$narrow-cont: 0.75;

@mixin container-narrow($width) {
    width: $width * $narrow-cont;
}

/*------------------------------------*\
#Border Mixins
\*------------------------------------*/

@mixin cafe-border {
    border: 1px solid $purple-dark-20;
}

/*------------------------------------*\
#Button Mixins
\*------------------------------------*/

@mixin btn {
    display: inline-block;
    background-color: $purple;
    color: #fff;
    border-radius: 60px;
    border: none;
    min-height: 60px;
    min-width: 240px;
    font-size: 1.5rem;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    text-transform: uppercase;
    border: 2px solid $purple;
    text-align: center;
    padding: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    &:hover {
        background-color: $purple-dark-10;
        color: $white;
    }
}

@mixin btn-purple {
    background-color: $purple;
    border: 2px solid $purple;
    color: $white;

    &:hover {
        background-color: $purple-dark-10;
    }
}

@mixin btn-outline-light {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;

    &:hover {
        background-color: rgba($white, 0.5);
        border-color: $white;
        color: $white;
    }
}

@mixin btn-outline {
    background-color: transparent;
    border: 2px solid $purple;
    color: $purple;
    &:hover {
        background-color: $purple;
        border-color: $purple;
        color: $white;
    }
}

/*------------------------------------*\
#Feature Queries
\*------------------------------------*/

@mixin grid {
    @supports (display: grid) {
        @content;
    }
    // Supports Grid
}
