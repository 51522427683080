/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Forms
\*------------------------------------*/
.category-filter {
    height: 44px;
    display: flex;
    float: right;
    .dropdown-filter {
        width: auto;
    }

    .form-group {
        float: none;
        align-items: center;
        @include sm {
            float: right;
        }
    }
}

.dropdown-filter {
    font-size: 1.5rem;
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .select {
        width: 100%;
        background: url(arrow.png) no-repeat;
        background-position: 80% center;

        & select {
            background: transparent;
            line-height: 1;
            border: 0;
            padding: 0;
            border-radius: 0;
            width: 100%;
            position: relative;
            z-index: 10;
            font-size: 1em;
            font-family: $main-font;
        }
    }
}

.form-label {
    &.label-left {
        padding-right: 10px;
    }
}

/*------------------------------------*\
#Gravity Forms Styles
\*------------------------------------*/

.gform_wrapper {
    .hidden_label .gfield_label,
    label.hidden_sub_label,
    label.screen-reader-text {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }

    li.hidden_label input {
        margin-top: 12px;
    }

    .field_description_above label {
        margin-bottom: 0.25em;
    }

    .field_sublabel_hidden_label
        .ginput_complex.ginput_container
        input[type='text'],
    .field_sublabel_hidden_label .ginput_complex.ginput_container select {
        margin-bottom: 12px;
    }

    .gfield_description {
        p {
            color: $dk-grey !important;
        }

        p + p {
            margin-top: 0.5em;
        }
    }

    .left_label li.hidden_label input,
    .right_label li.hidden_label input {
        margin-left: 3.7%;
    }

    .gform_body {
        .name_last {
            padding-left: 0 !important;
        }
        .name_first {
            padding-right: 0 !important;
            @include md {
                padding-right: 16px !important;
            }
        }
    }

    .gform_footer {
        input {
            &.button,
            &[type='submit'] {
                @include btn;
                line-height: 1em !important;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .hidden_label input.large,
    .hidden_label select.large,
    .top_label input.large,
    .top_label select.large {
        width: 100%;
    }

    hr {
        margin: 2em 0;
    }
}

.gform_confirmation_wrapper {
    padding: 1em;
    background-color: $purple-light-20;
    border: 3px solid $purple;
    border-radius: 10px;
    p {
        color: $purple;
    }
}

.ginput_container_fileupload input {
    padding: 12px;
}

.gform_fields {
    li {
        padding: 0 !important;
        label {
            .gfield_label {
                line-height: 1.2em !important;
                margin-bottom: 0.25em !important;
            }
        }
    }
}
