/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Graphics
\*------------------------------------*/

.brand-logo {
    width: 240px;
    display: block;
}

.blog-post-logo {
    width: 220px;
}

img {
    &.screenshot,
    &.card-icons {
        width: 100%;

        &.card-icons {
            width: auto;
        }

        @include sm {
            width: auto;
        }
    }
    &.screenshot {
        border: 1px solid $md-grey;
    }
}

.section {
    img {
        width: 100%;
        height: auto;
    }

    .section__block-content-credit-card img {
        width: auto;
    }
}

.paint-splash {
    img {
        flex-shrink: 0;
        @include sm {
            min-width: 540px;
        }

        @include lg {
            min-width: 800px;
        }

        @include xl {
            min-width: 960px;
        }
    }
}

.pages-content__item-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.img-default {
        width: 100%;
        aspect-ratio: 4 / 3; // Maintains 4:3 aspect ratio
        max-height: 360px;
        min-height: 200px;
        display: block;

        &.grid.column-item {
            max-height: 220px;
        }
    }

    @include md {
        &.img-default {
            max-height: clamp(220px, 30vw, 360px);
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include md {
        margin-right: 1em;
        width: 460px;
        height: auto;
        
        &.grid {
            width: 100%;
            margin: 0;
        }
    }

    &.image-card,
    &.outline {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &.timeline {
        border: none;
        width: 100%;
        height: 200px;
        margin-bottom: 1.5em !important;
        border-radius: 0;

        @include md {
            width: 320px;
            margin: 0 1.5em 0 0 !important;
            overflow: hidden;
        position: relative;
        flex: 0 0 300px;
        align-items: center;
        }
    }

    // Optimized img-round for circular images
    &.img-round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: clamp(100px, 80%, 200px); // Ensures images are proportional
        height: auto;
        padding: 0;

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 50%;
            aspect-ratio: 1 / 1; // Ensures it remains a circle
        }

        // Column Layout Optimization
        &.column-item {
            align-self: center;
            justify-self: center;
            text-align: center;

            img {
                width: 80%;
                height: auto;
                max-width: 100%;
                aspect-ratio: 1 / 1;
            }
        }

        // Row Layout Optimization
        &.row-item {
            align-self: center;
            justify-self: center;
            width: 100%;
            height: auto;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                aspect-ratio: 1 / 1;
            }
        }
    }

    &.row-item {
        align-items: center;
        justify-content: flex-start;

        @include md {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-gap: 2em;
        }
    }

    &.column-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

/*------------------------------------*\
#Images
\*------------------------------------*/
.bkg-contain {
    background-size: contain;
}

.bkg-cover {
    background-size: cover;
}

/*------------------------------------*\
#Icons
\*------------------------------------*/

.app-item-icon {
    width: 3em;
    height: 3em;
    border-radius: 5px;
    background-color: $purple;
    padding: 1em;
    i {
        color: $white;
        font-size: 1.75em;
    }
}

.appliction-steps-number {
    font-size: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    background-color: #7f6789;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    border: 6px solid #664a75;
    margin: 0 auto;
}

.appliction-steps-number span {
    padding-top: 0.12em;
}

.container__logo {
    &.logo-h {
        img {
            width: 100%;

            @include sm {
                height: 180px;
                width: auto;
            }
        }
    }

    &.logo-v {
        img {
            width: 320px;
        }
    }

    &.logo-bottom {
        margin-top: 2em;
    }

    &.logo-top {
        margin-bottom: 2em;
    }
}

.cta-button-icon {
    padding-right: 0.5em;
}

.feature-item__icon {
    width: 80px;
    margin: 0 auto;
}

.icon {
    &.icon-100 img {
        width: 100px;
    }

    &.icon-160 img {
        width: 160px;
    }
    &.icon-220 img {
        width: 220px;
    }
    &.icon-text-row {
        img {
            padding-right: 1em;
        }
    }

    &.goal_item-icon {
        top: -80px;
    }
}

.icon-block-item {
    .icon-block__icon {
        width: 180px;
        height: 180px;
        margin: 0 auto 1.5em;
        i {
            font-size: 4em;
        }
    }

    img {
        width: 100%;
    }
}

.icon-left {
    margin-right: 15px;
}

.icon-right {
    margin-left: 15px;
}

.item__icon {
    max-width: 200px;
    margin: 0 auto;
}

.pillar-icon img,
.reach-image img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
}

.reach-image {
    img {
        height: 180px;
    }
}

.pillar_item-icon {
    margin-bottom: 2em;
    @include md {
        margin-bottom: 0;
    }
    img {
        height: 180px;
        @include md {
            height: auto;
        }
    }
}

.thumbnail-round {
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1em;
    &.team-post-item__thumbnail {
        width: 279px;
        height: 279px;
    }
}

.thumbnail-round {
    img {
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.video-item__thumbnail {
    margin-bottom: 1em;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/*------------------------------------*\
#Images
\*------------------------------------*/

.bkg-image-outer {
    position: relative;
    overflow: hidden;
    width: 100%;

    &.ct-item-card {
        height: 170px;
    }

    .bkg-image-text {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.bkg-image-inner {
    position: absolute;
    width: 100%;
    height: 100%;
}

.container__single-post-image {
    margin: 0 auto 1em;
    @include md {
        margin-bottom: 2em;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; // Ensures it fully covers the container
        &.single-post-image {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center; // Ensures the image aligns properly
            justify-content: center; // Centers the image
            overflow: hidden; // Prevents overflow issues
        
        }
    }
}

figure.wp-caption {
    width: 100% !important;
}

.gallery {
    .gallery-item {
        flex-direction: column;
        align-items: center;
        padding: 1em;
    }
}

img,
.wp-caption img {
    max-width: 100%;
    height: auto;

    .alignright {
        float: right;
    }
    .alignleft {
        float: left;
    }
    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .alignnone,
    &.alignnone {
        display: block;
        margin-bottom: 1em;
        &.fullwidth-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &.banner {
            object-fit: cover;
            height: 320px;
            width: 100%;
        }
    }
    .aligncenter,
    &.aligncenter {
        display: block;
        margin: 1em auto;
    }
    .alignleft,
    &.alignleft {
        display: inline-flex;
        float: left;
        padding: 1em 1em 1em 0;
    }
    .alignright,
    &.alignright {
        display: inline-flex;
        float: right;
        padding: 1em 0 1em 1em;
    }
    .full-width,
    &.full-width {
        width: 100%;
    }
    .half-width,
    &.half-width {
        width: 50%;
    }
    .third-width,
    &.third-width {
        width: 33%;
    }
    .quarter-width,
    &.quarter-width {
        width: 25%;
    }
}

.learning-model-element-image {
    overflow: hidden;
    object-fit: cover;
    position: relative;
    height: 350px;
    img {
        height: 100%;
        width: auto;
    }
}

.paint-splash {
    img {
        height: auto;
    }
}

.resource-item {
    img {
        max-height: 100px;
    }
}

/*------------------------------------*\
#Logos
\*------------------------------------*/

.scoca-header-logo {
    img {
        height: 100px;
    }
}

/*------------------------------------*\
#Video Wrapper
\*------------------------------------*/

.videowrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: auto;
    width: 100vw;
    &.full-width {
        height: 0;
        overflow: hidden;
        width: unset;
    }
    &.single-post {
        width: unset;
        margin: 1em auto;
        @include md {
            width: 100%;
            margin: 1.5em auto;
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

//ADP Carousel Banner
.recruitment.recruitment_styles
    .careercenter-container
    .careercenter-carousel-image {
    height: auto !important;
}

/*------------------------------------*\
#Image Overrides
\*------------------------------------*/

.pages-content__item-card {
    &.img-round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: clamp(100px, 80%, 300px); // Prevents oversized images
        aspect-ratio: 1 / 1; // Ensures it remains a perfect circle
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            aspect-ratio: 1 / 1;
        }

        // Apply styles for different layouts
        @each $layout in column-item, row-item {
            &.#{$layout} {
                align-self: center;
                justify-self: center;
            }
        }

        &.column-item {
            text-align: center;
        }

        &.row-item {
            max-width: 200px; // Prevents stretching in row layout
            height: auto;
            flex-shrink: 0;
        }
    }
}