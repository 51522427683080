/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;


.mb-half {
    margin-bottom: 0.5em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 1em;
    @include md {
        margin-bottom: 2em;
    }
}

.mr-1 {
    margin-right: 1em;
}

.mt-0 {
    margin-top: 0em;
}

.mt-1 {
    margin-top: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.p-narrow {
    padding: 1.5em 2em;

    @include md {
        padding: 1em 2em;
    }
}

.p-standard {
    padding: 2em;
}

.prl-4 {
    padding: 0 2em;
    @include xs {
        padding: 0 2em;
    }
    @include sm {
        padding: 0 4em;
    }
}

.pt-0 {
    padding-top: 0;
}

.ptb-2 {
    padding: 1em 0;

    @include md {
        padding: 2em 0;
    }
}

.ptb-4 {
    padding: 2em 0;

    @include sm {
        padding: 3em 0;
    }

    @include lg {
        padding: 4em 0;
    }
}

.p-2-all {
    padding: 1em;

    @include xs {
        padding: 2em;
    }

    @include sm {
        padding: 4em;
    }

    &.calendar-container {
        padding: 2em 0.5em;

        @include sm {
            padding: 4em;
        }
    }
}

.p-4-all {
    padding: 1em;

    @include xs {
        padding: 2em;
    }

    @include sm {
        padding: 4em;
    }

    &.calendar-container {
        padding: 2em 0.5em;

        @include sm {
            padding: 4em;
        }
    }
}

.no-top-padding {
    padding-top: 0 !important;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

.no-top-margin {
    margin-top: 0 !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}
