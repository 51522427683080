/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
# Main Navigation
\*------------------------------------*/

.header-menu {
    li {
        padding-left: 0;

        @include lg {
            padding-left: 0.85em;
        }
    }
}

.container__secondary-nav {
    display: none;

    @include md {
        display: block;
    }
}

.main-nav {
    border-bottom: 1px solid $ca-lt-grey;
}

.mini-nav {
    background-color: $ca-md-grey;
}

.menu-toggle {
    display: inline-block;
    line-height: 1em;
    font-size: 1.5em;
    color: $purple;
    position: absolute;
    right: 0;
    height: 44px;
    padding: 10px 15px;
    text-align: center;

    @include desktop-nav {
        display: none;
    }
}

.menu-toggle:hover,
.menu-toggle:focus {
    color: $dk-purple;
}

.main-menu a {
    display: block;
    padding: 0.75em 0.5em;
    line-height: 1em;
    font-size: 1.5em;
    color: $dk-grey;
    text-decoration: none;
    text-align: left;

    &:hover {
        color: $ca-dk-grey;
    }

    @include nav-break {
        font-size: 1.7em;
        padding: 0.75em 1em;
    }

    @include desktop-nav {
        color: $purple;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

.main-menu li:last-child a {
    padding-right: 0;
}

.main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;

    @include desktop-nav {
        display: none;
    }
}

.main-menu:target,
.main-menu[aria-expanded='true'] {
    right: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded='true'] .menu-close {
    z-index: 1001;
}

.main-menu:target ul,
.main-menu[aria-expanded='true'] ul {
    position: relative;
    z-index: 1000;
}

.main-menu:target + .backdrop,
.main-menu[aria-expanded='true'] + .backdrop {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0, 0, 0, 0.85);
    cursor: default;
}

@supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded='true'] + .backdrop {
        position: fixed;
    }
}

.main-menu {
    position: absolute;
    right: -280px;
    top: 0;
    height: 100%;
    transition: right 0.3s ease, box-shadow 0.3s ease;
    z-index: 999;

    @include desktop-nav {
        position: relative !important;
        right: auto;
        top: auto;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }
}

/*------------------------------------*\
#New Nav Styles
\*------------------------------------*/

.container__main-nav {
    height: auto;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 30;

    &.shrink {
        height: auto;
        min-height: 70px;
        -webkit-transition: height 0.5s, ease-in-out;
        /* Safari */
        transition: height 0.5s, ease-in-out;
    }
}

.lock-scroll .container__main-nav.shrink {
    height: auto;
}

.main-nav {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 45px;
    z-index: 5;
    transition: all 0.2s ease;
    background-color: $off-white;

    &.scrolled {
        top: 0;
        -webkit-box-shadow: 0 6px 12px rgba($dk-grey, 0.175);
        box-shadow: 0 6px 12px rgba($dk-grey, 0.175);
        border-bottom: 1px $ca-dk-grey solid;
        background-color: $off-white;
        margin-top: 0;
        position: fixed;

        .sub-menu {
            top: 70px;
        }
    }
    @include ie {
        margin-top: 0;
    }
}

.main-menu-item {
    text-align: center;
}

.mini-nav_contact {
    a {
        font-weight: 400;
    }
}

.header-menu,
.menu-main-menu-container {
    height: 100%;
    align-items: center;
    display: flex;
}

.header-menu {
    flex-direction: column;
    width: 100%;

    @include desktop-nav {
        flex-direction: row;
        li {
            padding: 0 !important
        }
    }
    }



.navbar-collapse {
    display: none;
    align-items: center;
    flex-direction: column;
    width: 100%;
    transition: all 0.2s ease;

    @include xl {
        font-size: 1.6rem;
    }

    @include desktop-nav {
        display: flex;
        align-items: flex-end;
        width: auto;
        font-size: 1.45rem;
    }

    @include md {
        font-size: 1.4rem;
    }

    &.show {
        display: flex;
        background-color: $off-white;
        ul.header-menu {
            height: 85vh;
            overflow-y: auto;
            & > .menu-item {
                & > a {
                    height: auto;
                }
            }
        }
        .menu-main-menu-container {
            height: 100vh;
        }
    }

    .menu-item {
        width: 100%;
        height: auto;
        position: relative;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        @include desktop-nav {
            height: 100%;
            width: auto;
        }
    }
    .header-menu {
        & > .menu-item {
            & > a {
               

                &:active {
                    background-color: rgba($purple,0.50);
                }
    
            
            }
        }
    }
    

}

.menu-main-menu-container {
    flex-direction: column;
    width: 100%;

    @include desktop-nav {
        flex-direction: row-reverse;
        width: auto;
        height: 100px;
        &.scrolled {
            height: 70px;
        }
    }
}

.header-menu {
    & > .menu-item {
        & > a {
            padding: 1em;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: all 0.2s ease;
            font-weight: 400;

            &:hover {
                background-color: rgba($purple, 0.2);
                transition: all 0.2s ease;
                color: $purple;
            }

            &:active {
                background-color: rgba($purple, 0.35);
            }

            @include desktop-nav {
                padding: 0 1em;
            }
        }
    }
}

.nav-brand-toggle {
    display: flex;
    align-items: center;
    height: 100px;
    transition: all 0.2s ease;
    padding: 0 15px;
}

.shrink .nav-brand-toggle {
    height: 70px;
}

.nav-toggle {
    display: block;
    background: none;
    transition: none;
    min-width: 40px;

    @include desktop-nav {
        display: none;
    }
}

.navbar-header {
    width: 100%;
    height: 100%;

    @include desktop-nav {
        width: auto;
    }
}

.navbar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

span.menu-item-description {
    display: block;
    font-size: 0.65em;
    color: $dk-grey;
    pointer-events: none;
    line-height: 1.5em;
}

.has-dropdown {
    position: relative;
}

.sub-menu {
    left: 0;
    background-color: rgba($purple, 0.2);
    border-top: none;
    text-align: left;
    position: static;
    z-index: 99;
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }

    @include desktop-nav {
        background-color: #f9f9f9;
        position: absolute;
        min-width: 195px;
        min-height: auto;
        top: 100px;
        -webkit-box-shadow: 0 6px 12px rgba($dk-grey, 0.175);
        box-shadow: 0 6px 12px rgba($dk-grey, 0.175);
    }

    .menu-item {
        position: relative;
        display: block;
        clear: both;
        width: 100%;
        text-align: center;
        text-transform: capitalize;

        @include desktop-nav {
            text-align: left;
        }

        &:hover {
            background-color: rgba($purple, 0.2);
        }

        & a {
            display: block;
            padding: 1em;
            font-weight: 400;
        }
    }
}

/*------------------------------------*\
#Mini Nav
\*------------------------------------*/
.container__mini-nav {
    height: 45px;
    min-height: 45px;
    width: 100vw;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 15px;
    display: flex;
    z-index: 20;

    &.display-hide {
        transition: all 0.5s ease;
        opacity: 0;
        display: none;
    }

    @include mini-nav {
        justify-content: space-between;
    }
}

.mini-nav_contact ul {
    display: none;
    @extend %list-unstyled;

    li {
        color: $white;
        font-size: 1.3rem;
        display: inline-block;
        margin-right: 20px;

        i {
            margin-right: 5px;
        }
    }

    @include mini-nav {
        display: block;
    }
}

.mini-nav-menu {
    width: 100%;
    &.scroll {
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    @include mini-nav {
        width: auto;
    }

    ul {
        &.mini-nav-menu-items {
            display: flex;
            justify-content: space-between;

            @include mini-nav {
                width: auto;
                justify-content: flex-end;
            }
        }

        li {
            margin-left: 20px;
            display: inline-block;

            &:first-child {
                margin-left: 0;
            }

            a {
                color: $white;
                font-weight: bold;
                letter-spacing: 1px;
                font-size: 1.2rem;

                &:hover {
                    color: $ca-dk-grey;
                    cursor: pointer;
                }
            }
        }
    }
}

nav ul {
    @extend %list-unstyled;
}

nav ul li {
    display: block;

    @include desktop-nav {
        display: inline-block;
        padding: 10px 30px;

        &:last-child {
            padding-right: 0;
        }
    }
}

/*------------------------------------*\
# CASSC Navigation
\*------------------------------------*/
.cassc-nav {
    background-color: rgba($black, 0.5);

    .menu-cassc-menu-container {
    }

    .mini-nav-menu {
        width: 100%;

        &.scroll {
            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        ul {
            &.mini-nav-menu-items {
                justify-content: space-between;
            }

            li {
                padding: 1.5em !important;

                &:hover {
                    background-color: rgba($white, 0.4);
                    cursor: pointer;
                }

                &.current-menu-item {
                    a {
                        border-bottom: 1px solid $white;
                        padding-bottom: 0.5em;

                        &:hover {
                            cursor: default;
                            color: $white;
                        }
                    }
                }
            }

            a {
                color: $white;
                font-size: 1.6rem;
                padding: 0;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

/*------------------------------------*\
#Footer
\*------------------------------------*/

footer {
    text-align: center;
    flex: 1 0 auto;
    @include grid {
        flex: unset;
    }

    h1 {
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0.5em;
        font-size: 1.4rem;
        line-height: 1.2em;
    }

    @include md {
        text-align: left;
    }

    .ca-contact,
    .ca-social {
        padding: 10px 0px;

        p {
            font-size: 1.4rem;
        }
    }

    .ca-contact-numbers {
        margin-bottom: 1.25em;

        p {
            display: inline-block;
            margin-right: 20px;
            margin-top: 0.8em;
            &:last-child {
                margin-right: 0;
            }
        }

        h2 {
            margin-bottom: 0.5em;
        }
    }

    .ca-social {
        li {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            margin-right: 0.5em;

            &.facebook {
                background-image: url('../images/social-facebook.svg');
            }

            &.instagram {
                background-image: url('../images/social-instagram.svg');
            }

            &.twitter {
                background-image: url('../images/social-twitter.svg');
            }

            &.linkedin {
                background-image: url('../images/social-linkedin.svg');
            }

            &.youtube {
                background-image: url('../images/social-youtube.svg');
            }
        }

        span {
            display: none;
        }

        a {
            display: block;
            height: 100%;
        }
    }

    a {
        color: $white;

        &:hover {
            color: rgba($white, 0.5);
        }

        .footer__secondary a:hover {
            color: $purple;
        }
    }

    li {
        &.shaw-birdies {
            width: 150px;
            height: 150px;
            background-image: url(../images/shaw-birdies-logo.png);
            background-repeat: no-repeat;
            background-repeat: no-repeat;
            background-size: contain; /* or 'contain' depending on how you want it displayed */
            background-position: center; /* centers the background image */
            a {
                display: block;
                height: 100%;
            }
            span {
                display: none;
            }
        }
    }
}

.footer__main {
    background-color: $purple;
    font-size: 1.4rem;

    li.menu-item {
        padding: 0.75em 0 !important;
    }

    a {
        font-weight: normal;
    }
}

.logo-ca {
    height: 56px;
    img {
        width: auto;
        height: 56px;
        flex-shrink: 0;
    }
}

.footer__secondary {
    background-color: $dk-purple;
    text-align: center;
    font-size: 1.4rem;

    span {
        display: inline-block;
    }

    ul {
        margin-bottom: 20px;
        float: none;
        display: block;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include sm {
            float: right;
            display: inline-block;
            margin-bottom: none;
        }

        li {
            padding-bottom: 12px;
            padding-left: 0;

            @include sm {
                float: right;
                padding-bottom: 0;
                padding-left: 30px;
            }
        }
    }

    @include sm {
        text-align: left;
    }
}
