/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Alerts
\*------------------------------------*/
.alert {
  padding: 1em;
  color: $white;

  &.alert-reminder {
    background-color: $yellow;
    color: $black;
  }

  &.alert-important {
    background-color: $orange;
  }

  &.alert-stat {
    background-color: $purple;
  }

  &.alert-closure {
    background-color: #960018;
    color: #fff !important;
  }

  a {
    color: $white;
  }

  &.no-padding {
    .notification-text {
      display: block;
      margin-top: 0;
      padding: 1em 1em 0;
      @include md {
        padding: 0;
        display: inline;
      }
    }
  }
}
