/*------------------------------------*\
#SaSS Partials
\*------------------------------------*/
@use 'partials/variables' as *;
@use 'partials/mixins' as *;
@use 'partials/extend' as *;

/*------------------------------------*\
#Header Tags
\*------------------------------------*/

$step-size-heading: 0.65;

$step-down: 0.5;

$step-up: 0.95;

// Mixins - Header
@mixin heading-size($size) {
    font-size: $size * $step-size-heading + rem;
    margin-bottom: 0.2em;
    line-height: 1.2em;
    font-weight: 500;
}

@mixin heading-step-down($size) {
    font-size: $size * $step-down + rem;
    line-height: 1.2em;
}

$context-font: (
    section: 1,
    /* 100% */ article: 0.8,
    /* 80%  */ footer: 0.275,
    /* 67%  */ aside: 0.5 /* 50% */,
);

h1 {
    @include heading-size(6);
    font-weight: 400;

    &.small-heading {
        @include heading-step-down(5);
    }
}

h2 {
    @include heading-size(5);
    font-weight: 500;

    &.small-heading {
        @include heading-step-down(4);
    }
}

h3 {
    @include heading-size(4.5);

    &.small-heading {
        @include heading-step-down(3);
    }
}

h4 {
    @include heading-size(3);
    line-height: 1.2em;

    &.small-heading {
        @include heading-step-down(2);
        line-height: 1.25em;
    }
}

h5 {
    @include heading-size(2);
}

h6 {
    font-size: 1.65rem;
    font-weight: 600;
}

@each $element, $value in $context-font {
    #{$element} {
        h1 {
            @include heading-size(6 * $value);
        }

        h2 {
            @include heading-size(5 * $value);
        }

        h3 {
            @include heading-size(4 * $value);
        }

        h4 {
            @include heading-size(3 * $value);
        }

        h5 {
            @include heading-size(2 * $value);
        }

        h6 {
            @include heading-size(1 * $value);
        }
    }
}

p {
    ~ h3 {
        padding-top: 20px;
        padding-bottom: 8px;
        margin-bottom: 0;
    }

    & .header-content {
        line-height: 1.5em;
    }

    + h2 {
        margin-top: 2em;
    }
}

h2 + h3 {
    padding-top: 0;
}

a {
    &.btn {
        line-height: 1.5em;
    }
}

blockquote {
    display: block;
    padding: 3em;
    margin: 1.5em 0;
    position: relative;
    background-color: rgba($color: $purple, $alpha: 0.25);
    &:before {
        content: '\f10d';
        font-family: $fontawesome;
        font-weight: bold;
        position: absolute;
        font-size: 3em;
        top: 0.75em;
        left: 0.75em;
        width: 3rem;
        height: 2rem;
        color: rgba($color: $dk-purple, $alpha: 0.25);
        text-align: center;
        z-index: -300;
    }

    p {
        color: $dk-purple;
        font-weight: 500;
        font-style: italic;
    }
}

.form-confirmation {
    p {
        font-size: 2rem;
        text-align: center;
    }
}

i {
    &.right-side {
        padding-left: 0.5em;
    }

    &.left-side {
        padding-right: 0.5em;
    }
}

.icon {
    .icon-inline {
        display: inline-block;

        &.left-side {
            padding-right: 10px;
        }

        &.right-side {
            padding-left: 10px;
        }
    }
}

.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &.one-line {
        -webkit-line-clamp: 1;
    }
    &.two-line {
        -webkit-line-clamp: 2;
    }
    &.three-line {
        -webkit-line-clamp: 3;
    }
}

.pillar_item-text {
    text-align: center;
    @include md {
        text-align: left;
    }
}

.pillar_item-text-title {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 500;
}

.reach-title h2 {
    font-size: 3.2rem;
    margin-bottom: 0.25em;
}

.reach-text {
    text-align: left;
    justify-content: center;

    @include reach-break {
        text-align: center;
        padding-left: 0;
    }
}

.script {
    font-family: 'Pacifico', sans-serif;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-allcaps {
    text-transform: uppercase;
}

.text-normal {
    text-transform: none;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-bold,
strong {
    font-weight: 600;
}

.text-light {
    font-weight: 300;
}

.text-medium {
    font-weight: 500;
}

.text-regular {
    font-weight: 400;
}

.text-body-large {
    font-size: 1.5em;
    @include sm {
    }

    
}

.text-italics,
em {
    font-style: italic;
}

.text-nospace {
    letter-spacing: 0;
}

.text-spaced {
    letter-spacing: 2px;
}

.text-spaced-tight {
    letter-spacing: 1px;
}

.text-dk-grey {
    color: $dk-grey !important;
}

.text-purple {
    color: $purple;
}

.text-dk-purple {
    color: $dk-purple;
}

.text-lt-purple {
    color: $lt-purple;
}

.text-orange {
    color: $orange;
}

.text-yellow {
    color: $yellow;
}

.text-white {
    color: $white;
}

.text-small {
    font-size: 0.85em;
    line-height: 1.5em;
}

/*------------------------------------*\
# Specific Styles
\*------------------------------------*/

.container__header {
    h1 {
        font-size: 4.5rem;
        font-weight: 400;
        line-height: 1.35em;

        @include lg {
            font-size: 5rem;
        }
    }
}

.colour-block-content {
    padding: 1em;
    margin-bottom: 1.2em;

    &.inline-block {
        display: block;
        @include md {
            display: inline-block;
        }
    }
    &.block {
        display: block;
    }

    span {
        display: block;
        @include md {
            display: unset;
        }
    }
}

.dont-breakout {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: none;
}

.no-break {
    white-space: nowrap;
}

h1.script {
    font-size: 6rem;
    margin-bottom: 0.5em;
}

h1.large-header {
    font-size: 4rem;
}

.subtitle {
    font-size: 1.8rem;
    color: $dk-grey-light-30;
    margin-bottom: 1em;
}

.bkg-image-text {
    &.grid-outline h4 {
        font-size: 1em;
        font-weight: bold;

        span {
            padding: 0.7em;
            border: 2px solid white;
        }
    }
}

.career-post-approach {
    ul {
        list-style-type: disc;
    }
}

.career-post-content__highlights {
    ul {
        ~ h3 {
            padding-top: 1em;
        }
    }
}

.career-posting-meta {
    p {
        font-size: 1.4rem;
        line-height: 1.5em;
    }
}

.ca-event-item {
    .ca-event-date__date {
        font-size: 7rem;
        @include mongoose-light;
    }

    .ca-event-date__month {
        @include mongoose-medium;
        font-size: 4rem;
    }
}

.container__post {
    p a {
        font-weight: 600;

        &:hover {
            color: $lt-purple;
        }
    }
}

.icon-block__title {
    margin-bottom: 0.5em;
}

.menu-price {
    font-size: 2.4rem;
    font-weight: bold;
    margin-top: 0.2em;
}

.pages-content__title {
    margin-bottom: 1em;
}

.post-item__meta {
    font-size: 1.2rem;
    @include md {
        font-size: 1.6rem;
    }

    span {
        display: inline-block;
    }
}

section {
    .option-title {
        h3 {
            margin-top: 0.5em;
            font-size: 2rem;
        }
    }
}

.section {
    p {
        a {
            font-weight: bold;

            &:hover {
                color: $lt-purple;
            }
        }

        & + ol,
        & + ul {
            margin-top: 0.8em;
            margin-bottom: 1.2em;
        }
    }

    ol,
    ul {
        list-style-position: outside;
        margin: 2em 2em 0;
        & > li {
            & > ol {
                margin-top: 0.5em;
                list-style-type: lower-alpha;
                & > li {
                    &:last-child {
                        padding-bottom: 0.25em;
                    }
                    & > ol {
                        list-style-type: disc;
                    }
                }
            }
            & > ul {
                margin-top: 1em;
                list-style-type: disc;
                padding-bottom: 0;
            }
        }
    }

    ol {
        list-style: decimal;
        &.lower-alpha {
            list-style: lower-alpha;
        }
        &.upper-alpha {
            list-style: upper-alpha;
        }
        &.lower-roman {
            list-style: lower-roman;
        }
        &.upper-roman {
            list-style: upper-roman;
        }
    }

    strong {
        font-weight: 600;
    }

    em {
        font-style: italic;
    }

    img {
        width: 100%;
        height: auto;
    }

    &.career-post-skills {
        p + h3 {
            margin-top: 2em;
        }

        h3 {
            margin-bottom: 1em;
        }

        ul {
            margin: 0 3em 2em;
            list-style-type: disc;
        }

        li {
        }
    }
}

.section__block-content-receipts {
    h4 {
        font-size: 2.2rem;
        font-weight: 600;
    }
}

span {
    &.grade {
        margin-bottom: 0.5em;
    }
}

.reach-description {
    p {
        font-size: 1.65rem;
    }
}

.tuition-fee-year {
    h3 {
        font-size: 2rem;
        text-align: center;
        @include md {
            text-align: left;
        }
    }
}
.fee-cell {
    text-align: left;
    @include md {
        text-align: center;
    }
}

.fee-item-details {
    margin-bottom: 1em;
    @include md {
        margin: 0;
    }
    h4 {
        line-height: 1.2em;
    }
    p {
        & + p {
            margin-top: 0.5em;
        }
    }
}
.fee-item-fee {
    h4 {
        font-size: 2 rem;
        margin-bottom: 1em;
    }
    h5 {
        display: block;
        @include md {
            display: none;
        }
    }
    .fee-amount {
        font-size: 2 rem;
        text-align: right;
        @include md {
            text-align: center;
        }
    }
}

.text-note {
    padding: 1em;
    border-radius: 4px;
    &.important {
        background-color: rgba($color: $red, $alpha: 0.15);
        color: $red;
    }
    &.warning {
        background-color: rgba($color: $yellow, $alpha: 0.35);
        color: $dk-grey;
    }
    &.good {
        background-color: rgba($color: $green, $alpha: 0.25);
        color: $dk-grey;
    }
    &.accent {
        background-color: rgba($color: $purple, $alpha: 0.25);
        color: $purple;
    }
}

.scoca-post-meta {
    line-height: 1.2em;
}

.video-block-text {
    text-align: center;
    @include md {
        text-align: left;
    }
}

.video-item__title {
    h4 {
        font-size: 2rem;
        letter-spacing: 1px;
    }
}

.video-item__publish-date {
    margin-bottom: 0.5em;
}

.wp-caption-text {
    margin-bottom: 1em;
    font-size: 1.4rem;
}

/*------------------------------------*\
#Blog & Video
\*------------------------------------*/

.category {
    letter-spacing: 1px;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    display: block;
}

.category-label{
    font-size: 1.2rem;
}

.date-time-wrapper {
    margin-bottom: 1em;
    font-size: 1.4rem;
    span {
        margin-right: 0.5em;
        &:last-child {
            margin-right: 0;
        }
    }
}

.post-item-card {
    .post-item__category,
    .post-item__publish-date {
        font-size: 1.3rem;
        &.label {
            font-size: 1rem;
        }
    }
}

.post-item__title {
    h2 {
        font-weight: 500;
        font-size: 2.4rem;
        margin-bottom: 0.5em;
    }
}

.post-sidebar {
    .container__categories {
        ul {
            &.sidebar {
                li {
                    font-size: 1.4rem;
                    margin-bottom: 0.5em;
                    a {
                        color: $lt-purple;
                        &:hover {
                            color: $lt-purple-dark-20;
                        }
                    }
                }
            }
            &.children {
                padding-left: 1em;
            }
        }
    }
    .sidebar {
        .post-meta.sidebar {
            font-size: 1.2rem;
            font-weight: 500;
            span {
                display: inline;
            }
        }
    }
}

.scoca-post-header {
    background-color: $scoca-dk-purple;
    color: $white;
    text-align: center;
    padding: 20px;
}

span.category {
    font-weight: bold;

    a:hover {
        color: $purple-light-20;
    }
}

/*------------------------------------*\
# Labels
\*------------------------------------*/
.label-wrapper {
    margin-bottom: 1.25em;
}

.label {
    display: inline-block;
    padding: 0.65em 1em 0.5em;
    background-color: $ca-lt-grey;

    &.meeting-agenda {
        background-color: $scoca-lt-purple;
        color: $scoca-dk-purple;
    }
    &.meeting-minutes {
        background-color: $scoca-dk-purple;
        color: $white;
    }

    &.post-item__category {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0 0.5em 0.5em 0;
        line-height: 1.2em;
        display: inline-block;
    }
}

/*------------------------------------*\
#List Styles
\*------------------------------------*/

.container,
.mce-content-body {
    & > ul {
        padding-left: 1.15em;
        list-style-position: outside;
        margin: 1em 0 1em 1em;

        &.list-unstyled {
            padding: 0;
            margin: 0;
        }
    }
}

.container,
.mce-content-body {
    li {
        padding-bottom: 0.5em;
        padding-left: 0.85em;
        line-height: 1.5em;
    }
}

.container__text,
.mce-content-body {
    ul {
        list-style-position: outside;
        margin-top: 20px;
        margin-left: 15px;
        padding-left: 20px;
    }
}

.content-section,
.mce-content-body {
    ul,
    ol {
        padding: 1em 0 0 1.5em;
        li {
            padding-left: 1.5em;
            position: relative;
        }
        & ~ :is(h1, h2, h3, h4, h5, h6) {
            padding-top: 1em;
        }
        > ul,
        > ol {
            padding-bottom: 0;
            padding-top: 0;
            &:last-child {
                padding-bottom: 1em;
            }
        }
        ~ ul,
        ~ ol {
            padding-top: 1em;
        }
        + li {
            padding-top: 1em;
        }
        &.list-unstyled {
            padding: 0;
        }
        &.downloads-list {
            li {
                padding: 1em 0.5em !important;
                border-top: 1px solid $ca-md-grey;
            }
        }

        & + p {
            padding-top: 2em;
        }

        &.list-icon {

            li {
                padding: 1em 1em 1em 2.5em;
                display: block;
                &:before {
                    margin-left: 1em;
                }
           
            
            }
        
        }
        
      &.list-pdf li:before {
            content: "\f1c1";
        }
        
        &.list-striped {
            li {
            
                &:nth-child(odd) {
                    background-color:$lt-purple-light-45;
                }

                &:nth-child(even) {
                    background-color:$lt-purple-light-40;
                }
            }
        }
    }

   
      
    

    ul li {
        &:before {
            @extend %chevron-bullets;
        }
    }

    ol {
        & > li {
            list-style: decimal;
            margin-left: 1.25em;
            padding-left: 0.2em;
        }
    }
    p {
        & + ul,
        & + ol {
            padding-top: 0;
        }
    }
}

ul {
    &.cafe-hours-list {
        list-style: none;
        margin: 0 !important;
        padding: 0 !important;

        .timespan {
            margin-right: 1em;
            display: inline-block;
            min-width: 110px;
        }
    }

    
}


/*------------------------------------*\
# Career Posts
\*------------------------------------*/
.position-type {
    margin-top: 0.5em;
}

.position-dept {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 1.5em;
}

.section__careers-list {
    .grid-card {
        color: $dk-grey;
    }
}

/*------------------------------------*\
# FAQ Accordion
\*------------------------------------*/

.faq-accordion {
    width: 100%;
    background-color: $off-white;
    margin: 0.5em auto 2em;
}

.faq-item {
    .faq-item-question {
        background-color: $lt-grey;
        color: $dk-grey;
        padding: 0.75em 1em;
        position: relative;
        cursor: pointer;
        border-bottom: 2px solid $white;

        &:first-child {
            border-bottom: 2px solid $white;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 0;
        }
        span {
            .fal {
            }
        }
        &.active {
            & + .faq-item-answer {
                height: auto;
                max-height: 100%;
            }
        }
    }

    .faq-item-answer {
        padding: 0;
        width: 100%;
        height: auto;
        max-height: 0px;
        transition: 0.5s ease-in-out;
        overflow: hidden;

        .faq-text {
            padding: 0.75em 1em;
        }
    }
}
/*------------------------------------*\
#Team
\*------------------------------------*/

.team-post-item__content-text {
h2{
    font-size: 2.85rem;
    margin-bottom: 0;
}

.team-post-item__title {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1em;
    letter-spacing: 1px;
    line-height: 1.2em;
}

.team-post-item__quote { 
    p {
    font-size: 1.6rem;
    line-height: 1.5em;
}
}


}
